import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Modal,
	TextInput,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

const FUNNEL_STAGES = {
	AWARENESS: "Awareness",
	CONSIDERATION: "Consideration",
	CONVERSION: "Conversion",
	LOYALTY: "Loyalty",
};

const PERFORMANCE_METRICS = {
	REACH: "Reach",
	ENGAGEMENT: "Engagement",
	CLICKS: "Clicks",
	SALES: "Sales",
	ROI: "ROI",
};

const UNIT_METRICS = {
	TOTAL_UNITS: 10000, // Total units available
	UNITS_SOLD: 6500, // Current units sold
	AVERAGE_PRICE: 299.99, // Average unit price
	TOTAL_SPEND: 150000, // Total marketing spend
	TARGET_CPU: 20, // Target Cost Per Unit
	TARGET_ROAS: 3.5, // Target Return on Ad Spend
};

const INDUSTRY_BENCHMARKS = {
	SELL_THROUGH: {
		target: 80, // 80% sell-through
		timeframe: 8, // 8 weeks
		current: 65, // Current performance (example)
		weeksElapsed: 6, // Weeks elapsed (example)
	},
	UNIT_ECONOMICS: {
		target_cpu: 20, // Target Cost Per Unit
		target_roas: 3.5, // Target Return on Ad Spend
		target_conversion: 4, // Target Conversion Rate %
	},
};

export default class ChannelPerformance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			channels: [
				{
					id: 1,
					name: "Bash Instagram",
					type: "Social Media",
					primaryFocus: FUNNEL_STAGES.AWARENESS,
					secondaryFocus: FUNNEL_STAGES.CONSIDERATION,
					spendAllocation: 25, // percentage
					performance: {
						[PERFORMANCE_METRICS.REACH]: 85,
						[PERFORMANCE_METRICS.ENGAGEMENT]: 72,
						[PERFORMANCE_METRICS.CLICKS]: 45,
						[PERFORMANCE_METRICS.SALES]: 30,
						[PERFORMANCE_METRICS.ROI]: 2.5,
					},
					funnelContribution: {
						[FUNNEL_STAGES.AWARENESS]: 80,
						[FUNNEL_STAGES.CONSIDERATION]: 40,
						[FUNNEL_STAGES.CONVERSION]: 20,
						[FUNNEL_STAGES.LOYALTY]: 30,
					},
				},
				{
					id: 2,
					name: "Takealot Banner",
					type: "Retail Media",
					primaryFocus: FUNNEL_STAGES.CONSIDERATION,
					secondaryFocus: FUNNEL_STAGES.CONVERSION,
					spendAllocation: 35,
					performance: {
						[PERFORMANCE_METRICS.REACH]: 60,
						[PERFORMANCE_METRICS.ENGAGEMENT]: 65,
						[PERFORMANCE_METRICS.CLICKS]: 75,
						[PERFORMANCE_METRICS.SALES]: 80,
						[PERFORMANCE_METRICS.ROI]: 4.2,
					},
					funnelContribution: {
						[FUNNEL_STAGES.AWARENESS]: 40,
						[FUNNEL_STAGES.CONSIDERATION]: 75,
						[FUNNEL_STAGES.CONVERSION]: 70,
						[FUNNEL_STAGES.LOYALTY]: 45,
					},
				},
			],
			showAddModal: false,
			selectedChannel: null,
			activeFilter: null,
			sortMetric: null,
			totalBudget: "",
			selectedGoal: null,
			showBudgetModal: false,
		};
	}

	getPerformanceColor = (score) => {
		if (score >= 75) return APP_COLOURS.GREEN;
		if (score >= 50) return APP_COLOURS.YELLOW;
		return APP_COLOURS.RED;
	};

	calculateSpendSuggestion = (channel) => {
		const performanceAvg =
			Object.values(channel.performance).reduce((a, b) => a + b, 0) / 5;
		const funnelScore = channel.funnelContribution[channel.primaryFocus];

		// Complex algorithm to suggest spend adjustments
		const currentEfficiency = (performanceAvg * funnelScore) / 100;
		const currentSpend = channel.spendAllocation;

		if (currentEfficiency > 70 && currentSpend < 40) {
			return {
				suggestion: "INCREASE",
				amount: "+5-10%",
				reason: "High performance in primary focus area with room for growth",
			};
		} else if (currentEfficiency < 40 && currentSpend > 20) {
			return {
				suggestion: "DECREASE",
				amount: "-5-10%",
				reason: "Underperforming in key metrics",
			};
		}
		return {
			suggestion: "MAINTAIN",
			amount: "0%",
			reason: "Current spend aligned with performance",
		};
	};

	getSortedChannels = () => {
		const { channels, activeFilter, sortMetric } = this.state;

		if (!activeFilter) return channels;

		return [...channels].sort((a, b) => {
			if (sortMetric === "funnel") {
				return (
					b.funnelContribution[activeFilter] -
					a.funnelContribution[activeFilter]
				);
			}
			return b.performance[activeFilter] - a.performance[activeFilter];
		});
	};

	calculateBudgetAllocation = () => {
		const { channels, totalBudget, selectedGoal } = this.state;
		if (!totalBudget || !selectedGoal) return null;

		const budget = parseFloat(totalBudget);
		if (isNaN(budget)) return null;

		// Calculate weighted scores based on selected goal
		const channelsWithScores = channels.map((channel) => {
			let score;
			if (Object.values(FUNNEL_STAGES).includes(selectedGoal)) {
				// For funnel stages, combine funnel contribution with ROI
				score =
					channel.funnelContribution[selectedGoal] * 0.7 +
					channel.performance[PERFORMANCE_METRICS.ROI] * 0.3;
			} else {
				// For performance metrics, use the metric directly
				score = channel.performance[selectedGoal];
			}
			return { ...channel, score };
		});

		// Calculate total score
		const totalScore = channelsWithScores.reduce(
			(sum, channel) => sum + channel.score,
			0,
		);

		// Calculate budget allocation
		return channelsWithScores.map((channel) => ({
			...channel,
			recommendedBudget: (channel.score / totalScore) * budget,
		}));
	};

	calculateSellThroughProgress = () => {
		const { target, current, timeframe, weeksElapsed } =
			INDUSTRY_BENCHMARKS.SELL_THROUGH;
		const progressPercentage = (current / target) * 100;
		const timeProgressPercentage = (weeksElapsed / timeframe) * 100;
		const isOnTrack = progressPercentage >= timeProgressPercentage;

		return {
			progressPercentage,
			timeProgressPercentage,
			isOnTrack,
			remainingWeeks: timeframe - weeksElapsed,
			projectedFinal: (current / weeksElapsed) * timeframe,
		};
	};

	calculateUnitMetrics = () => {
		const {
			TOTAL_UNITS,
			UNITS_SOLD,
			AVERAGE_PRICE,
			TOTAL_SPEND,
			TARGET_CPU,
			TARGET_ROAS,
		} = UNIT_METRICS;

		const currentCPU = TOTAL_SPEND / UNITS_SOLD;
		const currentROAS = (UNITS_SOLD * AVERAGE_PRICE) / TOTAL_SPEND;
		const projectedUnits = TOTAL_SPEND / TARGET_CPU;
		const unitGap = projectedUnits - UNITS_SOLD;
		const conversionRate = (UNITS_SOLD / this.getTotalReach()) * 100;

		return {
			currentCPU,
			currentROAS,
			projectedUnits,
			unitGap,
			conversionRate,
			unitsRemaining: TOTAL_UNITS - UNITS_SOLD,
			revenue: UNITS_SOLD * AVERAGE_PRICE,
			isEfficient: currentCPU <= TARGET_CPU,
			isProfitable: currentROAS >= TARGET_ROAS,
		};
	};

	getTotalReach = () => {
		return this.state.channels.reduce((total, channel) => {
			return (
				total + channel.performance[PERFORMANCE_METRICS.REACH] * 1000
			); // Assuming reach is in thousands
		}, 0);
	};

	render() {
		const sortedChannels = this.getSortedChannels();
		const budgetAllocation = this.calculateBudgetAllocation();
		const filterTabs = [
			{ id: null, label: "All" },
			{ id: FUNNEL_STAGES.AWARENESS, label: "Awareness" },
			{ id: FUNNEL_STAGES.CONSIDERATION, label: "Consideration" },
			{ id: FUNNEL_STAGES.CONVERSION, label: "Conversion" },
			{ id: FUNNEL_STAGES.LOYALTY, label: "Loyalty" },
			{ id: PERFORMANCE_METRICS.ROI, label: "ROI" },
			{ id: PERFORMANCE_METRICS.REACH, label: "Reach" },
		];
		const sellThroughMetrics = this.calculateSellThroughProgress();
		const unitMetrics = this.calculateUnitMetrics();

		return (
			<View style={styles.container}>
				<View style={styles.benchmarkContainer}>
					<View style={styles.benchmarkHeader}>
						<Text style={styles.benchmarkTitle}>
							Industry Benchmark
						</Text>
						<Text style={styles.benchmarkSubtitle}>
							{INDUSTRY_BENCHMARKS.SELL_THROUGH.target}%
							Sell-Through in{" "}
							{INDUSTRY_BENCHMARKS.SELL_THROUGH.timeframe} Weeks
						</Text>
					</View>

					<View style={styles.benchmarkMetrics}>
						<View style={styles.benchmarkMetric}>
							<Text style={styles.metricLabel}>
								Current Sell-Through
							</Text>
							<Text
								style={[
									styles.metricValue,
									{
										color: sellThroughMetrics.isOnTrack
											? APP_COLOURS.GREEN
											: APP_COLOURS.RED,
									},
								]}>
								{INDUSTRY_BENCHMARKS.SELL_THROUGH.current}%
							</Text>
						</View>

						<View style={styles.benchmarkMetric}>
							<Text style={styles.metricLabel}>
								Weeks Remaining
							</Text>
							<Text style={styles.metricValue}>
								{sellThroughMetrics.remainingWeeks}
							</Text>
						</View>

						<View style={styles.benchmarkMetric}>
							<Text style={styles.metricLabel}>
								Projected Final
							</Text>
							<Text
								style={[
									styles.metricValue,
									{
										color:
											sellThroughMetrics.projectedFinal >=
											INDUSTRY_BENCHMARKS.SELL_THROUGH
												.target
												? APP_COLOURS.GREEN
												: APP_COLOURS.RED,
									},
								]}>
								{sellThroughMetrics.projectedFinal.toFixed(1)}%
							</Text>
						</View>
					</View>

					<View style={styles.progressContainer}>
						<View style={styles.progressTrack}>
							<View
								style={[
									styles.progressFill,
									{
										width: `${INDUSTRY_BENCHMARKS.SELL_THROUGH.current}%`,
									},
								]}
							/>
							<View
								style={[
									styles.timelineMarker,
									{
										left: `${sellThroughMetrics.timeProgressPercentage}%`,
									},
								]}
							/>
						</View>
						<View style={styles.progressLabels}>
							<Text style={styles.progressLabel}>0%</Text>
							<Text style={styles.progressLabel}>
								Target:{" "}
								{INDUSTRY_BENCHMARKS.SELL_THROUGH.target}%
							</Text>
							<Text style={styles.progressLabel}>100%</Text>
						</View>
					</View>

					<Text style={styles.benchmarkInsight}>
						{sellThroughMetrics.isOnTrack
							? "On track to meet industry benchmark"
							: `Need to increase sell-through by ${(
									INDUSTRY_BENCHMARKS.SELL_THROUGH.target -
									sellThroughMetrics.projectedFinal
							  ).toFixed(1)}% to meet benchmark`}
					</Text>
				</View>

				<View style={styles.unitMetricsContainer}>
					<View style={styles.unitMetricsHeader}>
						<Text style={styles.unitMetricsTitle}>
							Unit Economics
						</Text>
						<Text style={styles.unitMetricsSubtitle}>
							Target: {UNIT_METRICS.TARGET_CPU} CPU |{" "}
							{UNIT_METRICS.TARGET_ROAS}x ROAS
						</Text>
					</View>

					<View style={styles.metricsGrid}>
						<View style={styles.metricCard}>
							<Text style={styles.metricLabel}>Units Sold</Text>
							<Text style={styles.metricValue}>
								{UNIT_METRICS.UNITS_SOLD.toLocaleString()}
								<Text style={styles.metricSubtext}>
									/{UNIT_METRICS.TOTAL_UNITS.toLocaleString()}
								</Text>
							</Text>
							<Text
								style={[
									styles.metricTrend,
									{
										color:
											unitMetrics.unitGap > 0
												? APP_COLOURS.RED
												: APP_COLOURS.GREEN,
									},
								]}>
								{unitMetrics.unitGap > 0
									? `${unitMetrics.unitGap.toFixed(
											0,
									  )} units below target`
									: "On Target"}
							</Text>
						</View>

						<View style={styles.metricCard}>
							<Text style={styles.metricLabel}>
								Cost Per Unit
							</Text>
							<Text
								style={[
									styles.metricValue,
									{
										color: unitMetrics.isEfficient
											? APP_COLOURS.GREEN
											: APP_COLOURS.RED,
									},
								]}>
								R{unitMetrics.currentCPU.toFixed(2)}
							</Text>
							<Text style={styles.metricTrend}>
								Target: R{UNIT_METRICS.TARGET_CPU}
							</Text>
						</View>

						<View style={styles.metricCard}>
							<Text style={styles.metricLabel}>ROAS</Text>
							<Text
								style={[
									styles.metricValue,
									{
										color: unitMetrics.isProfitable
											? APP_COLOURS.GREEN
											: APP_COLOURS.RED,
									},
								]}>
								{unitMetrics.currentROAS.toFixed(1)}x
							</Text>
							<Text style={styles.metricTrend}>
								Target: {UNIT_METRICS.TARGET_ROAS}x
							</Text>
						</View>

						<View style={styles.metricCard}>
							<Text style={styles.metricLabel}>
								Conversion Rate
							</Text>
							<Text
								style={[
									styles.metricValue,
									{
										color:
											unitMetrics.conversionRate >=
											INDUSTRY_BENCHMARKS.UNIT_ECONOMICS
												.target_conversion
												? APP_COLOURS.GREEN
												: APP_COLOURS.RED,
									},
								]}>
								{unitMetrics.conversionRate.toFixed(2)}%
							</Text>
							<Text style={styles.metricTrend}>
								Target:{" "}
								{
									INDUSTRY_BENCHMARKS.UNIT_ECONOMICS
										.target_conversion
								}
								%
							</Text>
						</View>
					</View>

					<View style={styles.revenueSection}>
						<View style={styles.revenueSummary}>
							<Text style={styles.revenueLabel}>
								Total Revenue
							</Text>
							<Text style={styles.revenueValue}>
								R{unitMetrics.revenue.toLocaleString()}
							</Text>
						</View>
						<View style={styles.spendSummary}>
							<Text style={styles.spendLabel}>Total Spend</Text>
							<Text style={styles.spendValue}>
								R{UNIT_METRICS.TOTAL_SPEND.toLocaleString()}
							</Text>
						</View>
					</View>

					<View style={styles.recommendationBox}>
						<Text style={styles.recommendationTitle}>
							{unitMetrics.isEfficient && unitMetrics.isProfitable
								? "Performance On Track"
								: "Optimization Required"}
						</Text>
						<Text style={styles.recommendationText}>
							{unitMetrics.isEfficient && unitMetrics.isProfitable
								? "Current performance meets or exceeds targets for both CPU and ROAS."
								: `Need to ${
										unitMetrics.currentCPU >
										UNIT_METRICS.TARGET_CPU
											? "reduce cost per unit"
											: "increase sales volume"
								  } to meet targets.`}
						</Text>
					</View>
				</View>

				<View style={styles.header}>
					<Text style={styles.title}>
						Channel Performance & Focus
					</Text>
					<View style={styles.headerButtons}>
						<TouchableOpacity
							style={styles.budgetButton}
							onPress={() =>
								this.setState({ showBudgetModal: true })
							}>
							<Text style={styles.budgetButtonText}>
								Allocate Budget
							</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={styles.addButton}
							onPress={() =>
								this.setState({ showAddModal: true })
							}>
							<Text style={styles.addButtonText}>
								+ Add Channel
							</Text>
						</TouchableOpacity>
					</View>
				</View>

				{/* Budget Allocation Modal */}
				<Modal
					visible={this.state.showBudgetModal}
					transparent={true}
					animationType='slide'>
					<View style={styles.modalOverlay}>
						<View style={styles.budgetModalContent}>
							<Text style={styles.modalTitle}>
								Budget Allocation
							</Text>

							<View style={styles.budgetInputContainer}>
								<Text style={styles.label}>
									Total Budget (R)
								</Text>
								<TextInput
									style={styles.budgetInput}
									value={this.state.totalBudget}
									onChangeText={(value) =>
										this.setState({ totalBudget: value })
									}
									keyboardType='numeric'
									placeholder='Enter total budget'
								/>
							</View>

							<Text style={styles.label}>Optimization Goal</Text>
							<ScrollView
								horizontal
								showsHorizontalScrollIndicator={false}
								style={styles.goalScroll}>
								{[
									...Object.values(FUNNEL_STAGES),
									...Object.values(PERFORMANCE_METRICS),
								].map((goal) => (
									<TouchableOpacity
										key={goal}
										style={[
											styles.goalChip,
											this.state.selectedGoal === goal &&
												styles.goalChipActive,
										]}
										onPress={() =>
											this.setState({
												selectedGoal: goal,
											})
										}>
										<Text
											style={[
												styles.goalChipText,
												this.state.selectedGoal ===
													goal &&
													styles.goalChipTextActive,
											]}>
											{goal}
										</Text>
									</TouchableOpacity>
								))}
							</ScrollView>

							{budgetAllocation && (
								<View style={styles.allocationResults}>
									<Text style={styles.allocationTitle}>
										Recommended Allocation
									</Text>
									{budgetAllocation.map((channel) => (
										<View
											key={channel.id}
											style={styles.allocationItem}>
											<Text
												style={
													styles.allocationChannel
												}>
												{channel.name}
											</Text>
											<Text
												style={styles.allocationAmount}>
												R{" "}
												{channel.recommendedBudget.toFixed(
													2,
												)}
												<Text
													style={
														styles.allocationPercentage
													}>
													{" "}
													(
													{(
														(channel.recommendedBudget /
															parseFloat(
																this.state
																	.totalBudget,
															)) *
														100
													).toFixed(1)}
													%)
												</Text>
											</Text>
										</View>
									))}
								</View>
							)}

							<TouchableOpacity
								style={styles.closeButton}
								onPress={() =>
									this.setState({ showBudgetModal: false })
								}>
								<Text style={styles.closeButtonText}>
									Close
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</Modal>

				<View style={styles.filterContainer}>
					<ScrollView
						horizontal
						showsHorizontalScrollIndicator={false}
						style={styles.filterScroll}>
						{filterTabs.map((tab) => (
							<TouchableOpacity
								key={tab.id || "all"}
								style={[
									styles.filterTab,
									this.state.activeFilter === tab.id &&
										styles.filterTabActive,
								]}
								onPress={() => {
									this.setState({
										activeFilter: tab.id,
										sortMetric: Object.values(
											FUNNEL_STAGES,
										).includes(tab.id)
											? "funnel"
											: "performance",
									});
								}}>
								<Text
									style={[
										styles.filterTabText,
										this.state.activeFilter === tab.id &&
											styles.filterTabTextActive,
									]}>
									{tab.label}
								</Text>
							</TouchableOpacity>
						))}
					</ScrollView>
				</View>

				<ScrollView>
					<View style={styles.channelsGrid}>
						{sortedChannels.map((channel) => (
							<View key={channel.id} style={styles.channelCard}>
								<View style={styles.channelHeader}>
									<Text style={styles.channelName}>
										{channel.name}
									</Text>
									<Text style={styles.channelType}>
										{channel.type}
									</Text>
								</View>

								<View style={styles.focusSection}>
									<View style={styles.focusItem}>
										<Text style={styles.focusLabel}>
											Primary Focus:
										</Text>
										<Text style={styles.focusValue}>
											{channel.primaryFocus}
										</Text>
									</View>
									<View style={styles.focusItem}>
										<Text style={styles.focusLabel}>
											Secondary Focus:
										</Text>
										<Text style={styles.focusValue}>
											{channel.secondaryFocus}
										</Text>
									</View>
								</View>

								<Text style={styles.sectionTitle}>
									Performance Metrics
								</Text>
								<View style={styles.metricsGrid}>
									{Object.entries(channel.performance).map(
										([metric, score]) => (
											<View
												key={metric}
												style={styles.metricItem}>
												<Text
													style={styles.metricLabel}>
													{metric}
												</Text>
												<Text
													style={[
														styles.metricScore,
														{
															color: this.getPerformanceColor(
																score,
															),
														},
													]}>
													{score}%
												</Text>
											</View>
										),
									)}
								</View>

								<Text style={styles.sectionTitle}>
									Funnel Contribution
								</Text>
								<View style={styles.funnelContainer}>
									{Object.entries(
										channel.funnelContribution,
									).map(([stage, contribution]) => (
										<View
											key={stage}
											style={styles.funnelStage}>
											<Text style={styles.stageLabel}>
												{stage}
											</Text>
											<View
												style={styles.contributionBar}>
												<View
													style={[
														styles.contributionFill,
														{
															width: `${contribution}%`,
														},
													]}
												/>
											</View>
											<Text
												style={
													styles.contributionValue
												}>
												{contribution}%
											</Text>
										</View>
									))}
								</View>

								<View style={styles.spendSection}>
									<Text style={styles.sectionTitle}>
										Spend Analysis
									</Text>
									<Text style={styles.currentSpend}>
										Current Allocation:{" "}
										{channel.spendAllocation}%
									</Text>
									{(() => {
										const suggestion =
											this.calculateSpendSuggestion(
												channel,
											);
										return (
											<View
												style={[
													styles.suggestionBox,
													{
														backgroundColor:
															suggestion.suggestion ===
															"INCREASE"
																? "#e3f4e1"
																: suggestion.suggestion ===
																  "DECREASE"
																? "#fee2e2"
																: "#f3f4f6",
													},
												]}>
												<Text
													style={
														styles.suggestionTitle
													}>
													{suggestion.suggestion}{" "}
													{suggestion.amount}
												</Text>
												<Text
													style={
														styles.suggestionReason
													}>
													{suggestion.reason}
												</Text>
											</View>
										);
									})()}
								</View>
							</View>
						))}
					</View>
				</ScrollView>
			</View>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		padding: 20,
		backgroundColor: "#f8fafc",
	},
	title: {
		fontSize: 24,
		fontWeight: "bold",
		color: APP_COLOURS.BG2,
		marginBottom: 20,
	},
	channelCard: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		marginBottom: 20,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 3,
		maxWidth: 500,
	},
	channelHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 15,
	},
	channelName: {
		fontSize: 18,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	channelType: {
		fontSize: 14,
		color: APP_COLOURS.PURPLE,
		backgroundColor: "#f3f4f6",
		padding: 5,
		borderRadius: 5,
	},
	focusSection: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 20,
	},
	focusItem: {
		flex: 1,
	},
	focusLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	focusValue: {
		fontSize: 14,
		fontWeight: "500",
		color: APP_COLOURS.BG2,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 10,
		marginTop: 15,
	},
	metricsGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 15,
		marginBottom: 20,
	},
	metricItem: {
		width: "calc(20% - 12px)",
		alignItems: "center",
	},
	metricLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	metricScore: {
		fontSize: 16,
		fontWeight: "600",
	},
	funnelContainer: {
		marginBottom: 20,
	},
	funnelStage: {
		marginBottom: 10,
	},
	stageLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	contributionBar: {
		height: 8,
		backgroundColor: "#f3f4f6",
		borderRadius: 4,
		overflow: "hidden",
	},
	contributionFill: {
		height: "100%",
		backgroundColor: APP_COLOURS.PURPLE,
	},
	contributionValue: {
		fontSize: 12,
		color: APP_COLOURS.BG2,
		marginTop: 2,
	},
	spendSection: {
		borderTopWidth: 1,
		borderTopColor: "#f3f4f6",
		paddingTop: 15,
	},
	currentSpend: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 10,
	},
	suggestionBox: {
		padding: 15,
		borderRadius: 8,
		marginTop: 10,
	},
	suggestionTitle: {
		fontSize: 14,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 5,
	},
	suggestionReason: {
		fontSize: 12,
		color: "#666",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	addButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
		alignItems: "center",
	},
	addButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
	filterContainer: {
		marginBottom: 20,
		borderBottomWidth: 1,
		borderBottomColor: "#e5e7eb",
	},
	filterScroll: {
		flexGrow: 0,
		paddingBottom: 10,
	},
	filterTab: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		marginRight: 8,
		borderRadius: 20,
		backgroundColor: "#f3f4f6",
	},
	filterTabActive: {
		backgroundColor: APP_COLOURS.PURPLE,
	},
	filterTabText: {
		fontSize: 14,
		color: "#666",
		fontWeight: "500",
	},
	filterTabTextActive: {
		color: "#fff",
	},
	channelsGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 20,
		paddingBottom: 20,
	},
	headerButtons: {
		flexDirection: "row",
		gap: 10,
	},
	budgetButton: {
		backgroundColor: APP_COLOURS.GREEN,
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
		alignItems: "center",
	},
	budgetButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	budgetModalContent: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		width: "90%",
		maxWidth: 600,
		maxHeight: "90%",
	},
	budgetInputContainer: {
		marginBottom: 20,
	},
	budgetInput: {
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 5,
		padding: 12,
		fontSize: 16,
		marginTop: 5,
	},
	goalScroll: {
		flexGrow: 0,
		marginBottom: 20,
	},
	goalChip: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		marginRight: 8,
		borderRadius: 20,
		backgroundColor: "#f3f4f6",
	},
	goalChipActive: {
		backgroundColor: APP_COLOURS.PURPLE,
	},
	goalChipText: {
		fontSize: 14,
		color: "#666",
	},
	goalChipTextActive: {
		color: "white",
	},
	allocationResults: {
		backgroundColor: "#f8fafc",
		padding: 15,
		borderRadius: 8,
		marginTop: 20,
	},
	allocationTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 15,
	},
	allocationItem: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingVertical: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#eee",
	},
	allocationChannel: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		flex: 1,
	},
	allocationAmount: {
		fontSize: 14,
		fontWeight: "500",
		color: APP_COLOURS.PURPLE,
	},
	allocationPercentage: {
		fontSize: 12,
		color: "#666",
	},
	closeButton: {
		marginTop: 20,
		padding: 15,
		backgroundColor: APP_COLOURS.RED,
		borderRadius: 5,
		alignItems: "center",
	},
	closeButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
	benchmarkContainer: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		marginBottom: 20,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 3,
	},
	benchmarkHeader: {
		marginBottom: 20,
	},
	benchmarkTitle: {
		fontSize: 20,
		fontWeight: "bold",
		color: APP_COLOURS.BG2,
		marginBottom: 5,
	},
	benchmarkSubtitle: {
		fontSize: 14,
		color: "#666",
	},
	benchmarkMetrics: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 20,
	},
	benchmarkMetric: {
		flex: 1,
		alignItems: "center",
	},
	metricLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	metricValue: {
		fontSize: 24,
		fontWeight: "bold",
		color: APP_COLOURS.BG2,
	},
	progressContainer: {
		marginBottom: 15,
	},
	progressTrack: {
		height: 8,
		backgroundColor: "#f3f4f6",
		borderRadius: 4,
		overflow: "hidden",
		marginBottom: 10,
		position: "relative",
	},
	progressFill: {
		height: "100%",
		backgroundColor: APP_COLOURS.PURPLE,
		borderRadius: 4,
	},
	timelineMarker: {
		position: "absolute",
		top: -5,
		width: 2,
		height: 18,
		backgroundColor: APP_COLOURS.RED,
		transform: [{ translateX: -1 }],
	},
	progressLabels: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	progressLabel: {
		fontSize: 12,
		color: "#666",
	},
	benchmarkInsight: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		textAlign: "center",
		marginTop: 10,
		fontWeight: "500",
	},
	unitMetricsContainer: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		marginBottom: 20,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 3,
	},
	unitMetricsHeader: {
		marginBottom: 20,
	},
	unitMetricsTitle: {
		fontSize: 20,
		fontWeight: "bold",
		color: APP_COLOURS.BG2,
		marginBottom: 5,
	},
	unitMetricsSubtitle: {
		fontSize: 14,
		color: "#666",
	},
	metricsGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 20,
		marginBottom: 20,
	},
	metricCard: {
		flex: 1,
		minWidth: 200,
		backgroundColor: "#f8fafc",
		padding: 15,
		borderRadius: 8,
	},
	metricLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	metricValue: {
		fontSize: 24,
		fontWeight: "bold",
		color: APP_COLOURS.BG2,
		marginBottom: 5,
	},
	metricSubtext: {
		fontSize: 14,
		color: "#666",
		marginLeft: 5,
	},
	metricTrend: {
		fontSize: 12,
		color: "#666",
	},
	revenueSection: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 20,
		padding: 15,
		backgroundColor: "#f8fafc",
		borderRadius: 8,
	},
	revenueSummary: {
		flex: 1,
	},
	revenueLabel: {
		fontSize: 14,
		color: "#666",
		marginBottom: 5,
	},
	revenueValue: {
		fontSize: 20,
		fontWeight: "bold",
		color: APP_COLOURS.GREEN,
	},
	spendSummary: {
		flex: 1,
		alignItems: "flex-end",
	},
	spendLabel: {
		fontSize: 14,
		color: "#666",
		marginBottom: 5,
	},
	spendValue: {
		fontSize: 20,
		fontWeight: "bold",
		color: APP_COLOURS.RED,
	},
	recommendationBox: {
		backgroundColor: "#f8fafc",
		padding: 15,
		borderRadius: 8,
	},
	recommendationTitle: {
		fontSize: 16,
		fontWeight: "bold",
		color: APP_COLOURS.BG2,
		marginBottom: 5,
	},
	recommendationText: {
		fontSize: 14,
		color: "#666",
		lineHeight: 20,
	},
};
