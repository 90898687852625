import React, { Component } from "react";
import { View, Text, TouchableOpacity, Modal, TextInput } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class EditBudgetEntry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			entry: { ...props.entry },
		};
	}

	handleSpendChange = (month, value) => {
		const numValue = parseFloat(value) || 0;
		this.setState((prevState) => ({
			entry: {
				...prevState.entry,
				spend: {
					...prevState.entry.spend,
					[month]: numValue,
				},
			},
		}));
	};

	handleSubmit = () => {
		this.props.onSubmit(this.state.entry, this.props.index);
	};

	getBrandDisplay = (brand) => {
		if (typeof brand === "string") {
			return brand;
		}
		if (typeof brand === "object" && brand !== null) {
			return brand.title || brand.name || "Unknown Brand";
		}
		return "Unknown Brand";
	};

	render() {
		const months = [
			"jan",
			"feb",
			"mar",
			"apr",
			"may",
			"jun",
			"jul",
			"aug",
			"sep",
			"oct",
			"nov",
			"dec",
		];
		const { entry } = this.state;

		return (
			<Modal visible={true} transparent={true} animationType='slide'>
				<View style={styles.modalOverlay}>
					<View style={styles.modalContent}>
						<Text style={styles.modalTitle}>Edit Budget Entry</Text>

						<View style={styles.formGroup}>
							<Text style={styles.label}>Brand</Text>
							<Text style={styles.value}>
								{this.getBrandDisplay(entry.brand)}
							</Text>
						</View>

						<View style={styles.formGroup}>
							<Text style={styles.label}>Franchise</Text>
							<TextInput
								style={[styles.input, { marginTop: 5 }]}
								value={entry.franchise || ""}
								onChangeText={(value) =>
									this.setState((prevState) => ({
										entry: {
											...prevState.entry,
											franchise: value,
										},
									}))
								}
								placeholder='Enter franchise name'
							/>
						</View>

						<View style={styles.formGroup}>
							<Text style={styles.label}>Allocation</Text>
							<Text style={styles.value}>{entry.allocation}</Text>
						</View>

						<View style={styles.formGroup}>
							<Text style={styles.label}>P&L Allocation</Text>
							<Text style={styles.value}>
								{entry.pl_allocation}
							</Text>
						</View>

						<Text style={styles.sectionTitle}>Monthly Spend</Text>
						<View style={styles.monthsGrid}>
							{months.map((month) => (
								<View key={month} style={styles.monthInput}>
									<Text style={styles.monthLabel}>
										{month.toUpperCase()}
									</Text>
									<TextInput
										style={styles.input}
										value={
											entry.spend[month]?.toString() ||
											"0"
										}
										onChangeText={(value) =>
											this.handleSpendChange(month, value)
										}
										keyboardType='numeric'
										placeholder='0.00'
									/>
								</View>
							))}
						</View>

						<View style={styles.buttonContainer}>
							<TouchableOpacity
								style={styles.saveButton}
								onPress={this.handleSubmit}>
								<Text style={styles.saveButtonText}>
									Save Changes
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={styles.cancelButton}
								onPress={this.props.close}>
								<Text style={styles.cancelButtonText}>
									Cancel
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}

const styles = {
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		width: "90%",
		maxWidth: 800,
		maxHeight: "90%",
		overflow: "auto",
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: "bold",
		marginBottom: 20,
		color: APP_COLOURS.BG2,
		textAlign: "center",
	},
	formGroup: {
		marginBottom: 15,
	},
	label: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 5,
		fontWeight: "600",
	},
	value: {
		fontSize: 14,
		color: "#666",
		padding: 10,
		backgroundColor: "#f5f5f5",
		borderRadius: 5,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginTop: 20,
		marginBottom: 15,
	},
	monthsGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-between",
		gap: 10,
	},
	monthInput: {
		width: "calc(25% - 10px)",
		marginBottom: 15,
	},
	monthLabel: {
		fontSize: 12,
		color: APP_COLOURS.BG2,
		marginBottom: 5,
		fontWeight: "500",
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 5,
		padding: 8,
		fontSize: 14,
		color: APP_COLOURS.BG2,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "center",
		gap: 15,
		marginTop: 20,
	},
	saveButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 15,
		borderRadius: 5,
		alignItems: "center",
		flex: 1,
	},
	saveButtonText: {
		color: "white",
		fontSize: 16,
		fontWeight: "600",
	},
	cancelButton: {
		padding: 15,
		borderRadius: 5,
		alignItems: "center",
		borderWidth: 1,
		borderColor: APP_COLOURS.RED,
		flex: 1,
	},
	cancelButtonText: {
		color: APP_COLOURS.RED,
		fontSize: 16,
		fontWeight: "600",
	},
};
