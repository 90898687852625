import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	ActivityIndicator,
	TouchableOpacity,
	Image,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import AutoHeightImage from "../AutoHeightImageComp";
import KanbanBoard from "./KanbanBoard";
import RequestForm from "./RequestForm";
import {
	crudCreate,
	crudDelete,
	crudReadUnass,
	crudUpdate,
	sendDiscordMessage,
} from "../../CRUDHELPER";
import BrandTabs from "../BrandTabs";
import moment from "moment";

export default class CreativeRequests extends Component {
	constructor(props) {
		super(props);
		this.state = {
			requests: [],
			loading: false,
			showNewRequestForm: false,
			editingRequest: null,
			searchQuery: "",
			user_data: "",
			selectedBrand: {
				id: "",
				title: "",
			},
		};
	}

	componentDidMount() {
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await localStorage.getItem("user_data");
			if (value !== null) {
				const d = JSON.parse(value);
				await this.setState({
					user_data: d,
					accessToken: d.accessToken,
				});
				this.fetchRequests();
			}
		} catch (error) {
			console.error("Error getting device data:", error);
		}
	};

	fetchRequests = () => {
		this.setState({ loading: true });

		crudReadUnass("creative_requests", "", (status, data) => {
			if (status === 200) {
				const filteredRequests = data.filter(
					(request) =>
						!this.state.selectedBrand.id ||
						request.brand?.id === this.state.selectedBrand.id,
				);

				this.setState(
					{
						requests: filteredRequests,
						loading: false,
					},
					() => {
						// Force a re-render of the KanbanBoard by updating a timestamp
						this.setState({ lastUpdate: new Date().getTime() });
					},
				);
			} else {
				console.error("Failed to fetch requests");
				this.setState({
					loading: false,
					requests: [], // Clear requests on error
				});
			}
		});
	};

	handleNewRequest = async (request) => {
		this.setState({ loading: true });

		const newRequest = {
			...request,
			brand: this.state.selectedBrand,
			status: "Pending",
			created_by: this.state.user_data.id,
			created_at: new Date().toISOString(),
			history: [
				{
					action: "Created",
					timestamp: new Date().toISOString(),
					details: "Request created",
					user: {
						id: this.state.user_data.id,
						name: `${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
					},
				},
			],
			// Initialize each asset with its own history
			assets: request.assets.map((asset) => ({
				...asset,
				status: "Pending",
				created_at: new Date().toISOString(),
				history: [
					{
						action: "Created",
						timestamp: new Date().toISOString(),
						details: "Asset added to request",
						user: {
							id: this.state.user_data.id,
							name: `${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
						},
					},
				],
			})),
		};

		try {
			await crudCreate(
				"creative_requests",
				newRequest,
				async (status) => {
					if (status === 200) {
						// Send notification
						await sendDiscordMessage(
							`${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
							`[New Creative Request]
- Title: ${newRequest.title}
- Brand: ${newRequest.brand.title}
- Due: ${moment(newRequest.dueDate).format("DD MMM YYYY")}
- Assets: ${newRequest.assets.length} items`,
							"",
							this.state.user_data.id,
						);

						this.setState(
							{
								showNewRequestForm: false,
								loading: true,
							},
							() => {
								this.fetchRequests();
							},
						);
					} else {
						console.error("Failed to create request");
						this.setState({ loading: false });
					}
				},
			);
		} catch (error) {
			console.error("Error creating request:", error);
			this.setState({ loading: false });
		}
	};

	handleUpdateRequest = async (request) => {
		try {
			const currentRequest = this.state.editingRequest;
			if (!currentRequest) return;

			await crudUpdate("creative_requests", request, () => {
				// Send notification about status change if it changed
				if (request.status !== currentRequest.status) {
					sendDiscordMessage(
						`${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
						`[Creative Request Update]
- Title: ${request.title}
- Status: ${request.status}
- Brand: ${request.brand.title}`,
						"",
						this.state.user_data.id,
					);
				}

				this.setState({ editingRequest: null }, () => {
					this.fetchRequests();
				});
			});
		} catch (error) {
			console.error("Error updating request:", error);
		}
	};

	handleStatusChange = async (requestId, updatedRequest) => {
		try {
			const currentRequest = this.state.requests.find(
				(r) => r._id === requestId,
			);
			if (!currentRequest) return;

			const updateData = {
				...updatedRequest,
				updated_at: new Date().toISOString(),
				history: [
					...(currentRequest.history || []),
					{
						action: "Status Changed",
						timestamp: new Date().toISOString(),
						details: `Status changed from ${currentRequest.status} to ${updatedRequest.status}`,
						oldStatus: currentRequest.status,
						newStatus: updatedRequest.status,
						user: {
							id: this.state.user_data.id,
							name: `${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
						},
					},
				],
			};

			await crudUpdate("creative_requests", updateData, () => {
				sendDiscordMessage(
					`${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
					`[Creative Request Status Update]
- Title: ${updateData.title}
- New Status: ${updateData.status}
${updateData.history[updateData.history.length - 1].details}`,
					"",
					this.state.user_data.id,
				);

				this.fetchRequests();
			});
		} catch (error) {
			console.error("Error updating request status:", error);
		}
	};

	handleAssetUpdate = async (requestId, updatedAsset) => {
		const request = this.state.requests.find((r) => r._id === requestId);
		if (!request) return;

		const updatedAssets = request.assets.map((asset) =>
			asset._id === updatedAsset._id ? updatedAsset : asset,
		);

		const updatedRequest = {
			...request,
			assets: updatedAssets,
			lastUpdated: new Date().toISOString(),
		};

		try {
			await crudUpdate("creative_requests", updatedRequest, () => {
				this.fetchRequests();
			});
		} catch (error) {
			console.error("Error updating asset:", error);
		}
	};

	handleRequestDeleted = (requestId) => {
		this.setState((prevState) => ({
			requests: prevState.requests.filter(
				(request) => request._id !== requestId,
			),
		}));
	};

	render() {
		const filteredRequests = this.state.requests.filter(
			(request) =>
				request.title
					.toLowerCase()
					.includes(this.state.searchQuery.toLowerCase()) ||
				request.brief
					?.toLowerCase()
					.includes(this.state.searchQuery.toLowerCase()),
		);

		return (
			<View style={styles.container}>
				<View style={styles.header}>
					<View style={styles.brandSelector}>
						<BrandTabs
							selectedSubPage={(brand) => {
								this.setState(
									{ selectedBrand: brand },
									this.fetchRequests,
								);
							}}
						/>
					</View>

					<View style={styles.searchContainer}>
						<TextInput
							style={styles.searchInput}
							placeholder='Search requests...'
							value={this.state.searchQuery}
							onChangeText={(text) =>
								this.setState({ searchQuery: text })
							}
						/>
						{this.state.loading && (
							<ActivityIndicator
								color={APP_COLOURS.PRIMARY}
								style={styles.loader}
							/>
						)}
					</View>

					{this.state.selectedBrand.id && (
						<TouchableOpacity
							style={styles.newButton}
							onPress={() =>
								this.setState({ showNewRequestForm: true })
							}>
							<Text style={styles.newButtonText}>
								+ New Request
							</Text>
						</TouchableOpacity>
					)}
				</View>

				{this.state.selectedBrand.id ? (
					<KanbanBoard
						key={this.state.lastUpdate}
						requests={filteredRequests}
						onStatusChange={this.handleStatusChange}
						onEditRequest={(request) =>
							this.setState({ editingRequest: request })
						}
						onAssetUpdate={this.handleAssetUpdate}
						onRequestDeleted={this.handleRequestDeleted}
						user_data={this.state.user_data}
					/>
				) : (
					<View style={styles.selectBrandMessage}>
						<Text style={styles.selectBrandText}>
							Please select a brand to view creative requests
						</Text>
					</View>
				)}

				{this.state.showNewRequestForm && (
					<RequestForm
						onSubmit={this.handleNewRequest}
						onClose={() =>
							this.setState({ showNewRequestForm: false })
						}
					/>
				)}

				{this.state.editingRequest && (
					<RequestForm
						request={this.state.editingRequest}
						onSubmit={this.handleUpdateRequest}
						onClose={() => this.setState({ editingRequest: null })}
					/>
				)}
			</View>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		backgroundColor: "#FFF",
	},
	header: {
		padding: 15,
		backgroundColor: "#FFF",
		borderBottomWidth: 1,
		borderBottomColor: "#E5E5E5",
	},
	brandSelector: {
		marginBottom: 15,
	},
	searchContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 15,
	},
	searchInput: {
		flex: 1,
		height: 40,
		backgroundColor: "#F8F9FA",
		borderRadius: 20,
		paddingHorizontal: 15,
		marginRight: 10,
	},
	loader: {
		marginLeft: 10,
	},
	newButton: {
		backgroundColor: APP_COLOURS.GREEN,
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 20,
		alignSelf: "flex-start",
	},
	newButtonText: {
		color: "#FFF",
		fontWeight: "600",
		fontSize: 14,
	},
	selectBrandMessage: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	selectBrandText: {
		fontSize: 16,
		color: APP_COLOURS.BG3,
		fontWeight: "500",
	},
};
