import React, { Component } from "react";
import {
	View,
	Image,
	StyleSheet,
	ScrollView,
	Dimensions,
	TouchableOpacity,
} from "react-native";
import AutoHeightImage from "../AutoHeightImageComp";
import { APP_COLOURS } from "../../APP_VARS";

class Carousel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: 0,
		};
	}

	change = ({ nativeEvent }) => {
		const slide = Math.ceil(
			nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width,
		);
		if (slide !== this.state.active) {
			this.setState({ active: slide });
		}
	};

	renderVideo(item, screenWidth) {
		return (
			<video
				key={item.uri}
				style={{
					width: screenWidth,
					height: screenWidth * 1.6,
					backgroundColor: APP_COLOURS.BG3,
				}}
				src={item.uri}
				// playsInline
				// autoPlay
				// loop
				// muted // Muted attribute is necessary for autoplay in most browsers

				playsInline
				controls // Provide controls to the user
			/>
		);
	}

	render() {
		let { postImageUri, screenWidth } = this.props;
		let { active } = this.state;

		let width = screenWidth;
		return (
			<View
				style={{
					width,
					// height: width + 20,
					height: "auto",
					paddingBottom: 20,
				}}>
				<ScrollView
					pagingEnabled
					snapToAlignment='start'
					snapToOffsets={width}
					horizontal
					scrollEventThrottle={16}
					onScroll={this.change}
					showsHorizontalScrollIndicator={false}
					style={
						{
							// width,
							// height: width, // to maintain aspect ratio of 4:5 based on width
							// height: "auto",
							// paddingBottom: 20,
						}
					}>
					{postImageUri.map((item, index) => (
						<View
							style={{
								width: width,
							}}>
							{item.type === "image" ? (
								<img
									style={{
										width,
										// resizeMode: "contain",
									}}
									src={item.uri}
									alt='Image'
									className='responsive'
								/>
							) : (
								this.renderVideo(item, screenWidth)
							)}
						</View>
					))}
					{/* {postImageUri.map((image, index) => (
						<Image
							key={index}
							source={image.uri}
							style={{
								width,

								height: "auto",
								resizeMode: "contain",
								paddingBottom: 20,
							}}
						/>
					))} */}
				</ScrollView>
				<View
					style={{
						flexDirection: "row",
						position: "absolute",
						bottom: 0,
						alignSelf: "center",
					}}>
					{postImageUri.map((i, k) => (
						<TouchableOpacity
							key={k}
							style={
								k === active
									? styles.pagingActive
									: styles.paging
							}
						/>
					))}
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	paging: {
		width: 10,
		height: 10,
		borderRadius: 5,
		margin: 3,
		backgroundColor: "#E8E8E8",
	},
	pagingActive: {
		width: 10,
		height: 10,
		borderRadius: 5,
		margin: 3,
		backgroundColor: "#0098FD",
	},
});

export default Carousel;
