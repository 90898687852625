import React, { Component, useState } from "react";
import {
	View,
	Text,
	TextInput,
	TouchableOpacity,
	ScrollView,
	Modal,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import FullCalendar from "./FullCalendar";
import ImageUploaderComp from "./ImageUploaderComp";
import {
	CREATIVE_ASSET_TYPES,
	getAllAssetTypes,
} from "./CreativeRequestParameters";

const getSizePreviewDimensions = (sizeString) => {
	// Extract dimensions from size string (e.g., "16:9 (1920x1080px)" -> "16:9")
	const ratio = sizeString.split(" ")[0];
	const [width, height] = ratio.split(":").map((num) => parseInt(num));

	// Base width for preview
	const baseWidth = 80;
	const scale = baseWidth / width;

	return {
		width: width * scale,
		height: height * scale,
	};
};

export default class RequestForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			brief: "",
			startDate: new Date(),
			dueDate: new Date(),
			assets: [],
			driveUrl: "",
			...props.request, // Spread existing request data if editing
		};
	}

	addAsset = (asset) => {
		this.setState((prevState) => ({
			assets: [...prevState.assets, asset],
		}));
	};

	removeAsset = (assetId) => {
		this.setState((prevState) => ({
			assets: prevState.assets.filter((asset) => asset._id !== assetId),
		}));
	};

	updateAsset = (index, field, value) => {
		this.setState((prevState) => {
			const newAssets = [...prevState.assets];
			newAssets[index] = {
				...newAssets[index],
				[field]: value,
			};
			return { assets: newAssets };
		});
	};

	handleSubmit = () => {
		const request = {
			...this.state,
			status: "Pending",
		};
		this.props.onSubmit(request);
	};

	render() {
		return (
			<Modal visible={true} animationType='slide'>
				<View style={styles.modalContainer}>
					<View style={styles.formContainer}>
						<View style={styles.header}>
							<Text style={styles.title}>
								{this.props.request
									? "Edit Request"
									: "New Creative Request"}
							</Text>
							<TouchableOpacity
								style={styles.closeButton}
								onPress={this.props.onClose}>
								<Text style={styles.closeButtonText}>×</Text>
							</TouchableOpacity>
						</View>

						<ScrollView style={styles.scrollContainer}>
							<View style={styles.formSection}>
								<Text style={styles.sectionLabel}>
									Basic Information
								</Text>
								<TextInput
									style={styles.input}
									placeholder='Request Title'
									value={this.state.title}
									onChangeText={(title) =>
										this.setState({ title })
									}
								/>

								<TextInput
									style={[styles.input, styles.textArea]}
									placeholder='Brief Description'
									multiline
									value={this.state.brief}
									onChangeText={(brief) =>
										this.setState({ brief })
									}
								/>
							</View>

							<View style={styles.formSection}>
								<View style={styles.calendarContainer}>
									<FullCalendar
										startDate={(date) =>
											this.setState({ startDate: date })
										}
										endDate={(date) =>
											this.setState({ dueDate: date })
										}
									/>
								</View>
							</View>

							<View style={styles.formSection}>
								<Text style={styles.sectionLabel}>
									Required Assets
								</Text>
								<AssetSelector onSelect={this.addAsset} />

								<View style={styles.selectedAssetsContainer}>
									{this.state.assets.map((asset, index) => (
										<View
											key={asset._id}
											style={styles.selectedAsset}>
											<View
												style={
													styles.selectedAssetContent
												}>
												<View
													style={
														styles.selectedAssetHeader
													}>
													<View
														style={
															styles.selectedAssetInfo
														}>
														<Text
															style={
																styles.selectedAssetTitle
															}>
															{asset.type}
														</Text>
														<Text
															style={
																styles.selectedAssetSize
															}>
															{asset.size}
														</Text>
													</View>
													<TouchableOpacity
														style={
															styles.removeAssetButton
														}
														onPress={() =>
															this.removeAsset(
																asset._id,
															)
														}>
														<Text
															style={
																styles.removeAssetText
															}>
															×
														</Text>
													</TouchableOpacity>
												</View>

												<TextInput
													style={
														styles.assetBriefInput
													}
													placeholder='Add content brief for this asset...'
													value={asset.brief}
													onChangeText={(text) => {
														const updatedAssets = [
															...this.state
																.assets,
														];
														updatedAssets[index] = {
															...asset,
															brief: text,
															history: [
																...asset.history,
																{
																	action: "Brief Updated",
																	timestamp:
																		new Date().toISOString(),
																	details:
																		"Content brief modified",
																},
															],
														};
														this.setState({
															assets: updatedAssets,
														});
													}}
													multiline
													numberOfLines={3}
												/>

												{asset.specs && (
													<Text
														style={
															styles.selectedAssetSpecs
														}>
														Specs: {asset.specs}
													</Text>
												)}
											</View>
										</View>
									))}
								</View>
							</View>

							<View style={styles.formSection}>
								<Text style={styles.sectionLabel}>
									Additional Information
								</Text>
								<TextInput
									style={styles.input}
									placeholder='Google Drive URL'
									value={this.state.driveUrl}
									onChangeText={(driveUrl) =>
										this.setState({ driveUrl })
									}
								/>
							</View>
						</ScrollView>

						<View style={styles.footer}>
							<TouchableOpacity
								style={styles.cancelButton}
								onPress={this.props.onClose}>
								<Text style={styles.cancelButtonText}>
									Cancel
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								style={styles.submitButton}
								onPress={this.handleSubmit}>
								<Text style={styles.submitButtonText}>
									{this.props.request
										? "Update Request"
										: "Create Request"}
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}

const styles = {
	modalContainer: {
		flex: 1,
		backgroundColor: "rgba(0,0,0,0.5)",
		justifyContent: "center",
		alignItems: "center",
		padding: 20,
	},
	formContainer: {
		backgroundColor: "#FFF",
		borderRadius: 12,
		width: "100%",
		maxWidth: 800,
		maxHeight: "90%",
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 20,
		borderBottomWidth: 1,
		borderBottomColor: "#E5E5E5",
	},
	title: {
		fontSize: 24,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		fontFamily: "Montserrat",
	},
	closeButton: {
		padding: 8,
		borderRadius: 20,
		backgroundColor: "#F0F0F0",
		width: 36,
		height: 36,
		alignItems: "center",
		justifyContent: "center",
	},
	closeButtonText: {
		fontSize: 20,
		color: APP_COLOURS.BG2,
		lineHeight: 20,
	},
	scrollContainer: {
		padding: 20,
	},
	formSection: {
		marginBottom: 24,
	},
	sectionLabel: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 12,
		fontFamily: "Montserrat",
	},
	input: {
		borderWidth: 1,
		borderColor: "#E5E5E5",
		borderRadius: 8,
		padding: 12,
		marginBottom: 12,
		fontSize: 14,
		backgroundColor: "#FFF",
		fontFamily: "Montserrat",
	},
	textArea: {
		height: 100,
		textAlignVertical: "top",
	},
	calendarContainer: {
		backgroundColor: "#F8F9FA",
		borderRadius: 8,
		// padding: 10,
		marginBottom: 12,
		width: 400,
	},
	selectedAssetsContainer: {
		marginTop: 12,
	},
	footer: {
		flexDirection: "row",
		justifyContent: "flex-end",
		padding: 20,
		borderTopWidth: 1,
		borderTopColor: "#E5E5E5",
		gap: 12,
	},
	cancelButton: {
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: "#E5E5E5",
	},
	cancelButtonText: {
		color: APP_COLOURS.BG2,
		fontSize: 14,
		fontWeight: "600",
		fontFamily: "Montserrat",
	},
	submitButton: {
		backgroundColor: APP_COLOURS.GREEN,
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 6,
	},
	submitButtonText: {
		color: "#FFF",
		fontSize: 14,
		fontWeight: "600",
		fontFamily: "Montserrat",
	},
	assetSelector: {
		flex: 1,
		marginBottom: 20,
	},
	categories: {
		flexDirection: "row",
		paddingVertical: 10,
	},
	categoryButton: {
		paddingHorizontal: 15,
		paddingVertical: 8,
		borderRadius: 20,
		backgroundColor: "#F0F0F0",
		marginRight: 10,
		borderWidth: 1,
		borderColor: "#E5E5E5",
	},
	categoryButtonActive: {
		backgroundColor: APP_COLOURS.BLUE,
		borderColor: APP_COLOURS.BLUE,
	},
	categoryText: {
		fontSize: 14,
		fontWeight: "500",
		color: APP_COLOURS.BG2,
	},
	categoryTextActive: {
		color: "#FFF",
	},
	assetList: {
		maxHeight: 300,
		marginTop: 15,
	},
	assetItem: {
		backgroundColor: "#F8F9FA",
		padding: 15,
		borderRadius: 8,
		marginBottom: 10,
		borderWidth: 1,
		borderColor: "#E5E5E5",
	},
	assetTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 5,
	},
	assetSize: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		marginBottom: 3,
	},
	assetDesc: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		marginBottom: 3,
	},
	assetSpecs: {
		fontSize: 12,
		color: APP_COLOURS.BG4,
		fontStyle: "italic",
	},
	selectedAsset: {
		backgroundColor: "#F8F9FA",
		borderWidth: 1,
		borderColor: "#E5E5E5",
		borderRadius: 8,
		marginBottom: 10,
		padding: 12,
	},
	selectedAssetHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-start",
		marginBottom: 10,
	},
	selectedAssetInfo: {
		flex: 1,
	},
	selectedAssetContent: {
		flex: 1,
	},
	selectedAssetTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 4,
	},
	selectedAssetSize: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
	},
	selectedAssetSpecs: {
		fontSize: 12,
		color: APP_COLOURS.BG4,
		fontStyle: "italic",
		marginTop: 8,
	},
	assetBriefInput: {
		backgroundColor: "#FFF",
		borderWidth: 1,
		borderColor: "#E5E5E5",
		borderRadius: 6,
		padding: 10,
		minHeight: 80,
		textAlignVertical: "top",
		fontSize: 14,
		marginBottom: 8,
	},
	removeAssetButton: {
		backgroundColor: "#FF0000",
		width: 24,
		height: 24,
		borderRadius: 12,
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 10,
	},
	removeAssetText: {
		color: "#FFF",
		fontSize: 16,
		fontWeight: "600",
		lineHeight: 24,
	},
	assetItemHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 8,
	},
	assetItemInfo: {
		flex: 1,
	},
	sizePreviewContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 8,
		marginBottom: 4,
	},
	sizePreview: {
		backgroundColor: APP_COLOURS.BLUE,
		opacity: 0.2,
		marginRight: 10,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: APP_COLOURS.BLUE,
	},
	sizePreviewText: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
	},
};

const AssetSelector = ({ onSelect }) => {
	const [selectedCategory, setSelectedCategory] = useState(null);

	const renderSizePreview = (size) => {
		const dimensions = getSizePreviewDimensions(size);
		return (
			<View style={styles.sizePreviewContainer}>
				<View
					style={[
						styles.sizePreview,
						{
							width: dimensions.width,
							height: dimensions.height,
						},
					]}
				/>
				<Text style={styles.sizePreviewText}>{size}</Text>
			</View>
		);
	};

	return (
		<View style={styles.assetSelector}>
			<ScrollView horizontal showsHorizontalScrollIndicator={false}>
				<View style={styles.categories}>
					{Object.keys(CREATIVE_ASSET_TYPES).map((categoryKey) => (
						<TouchableOpacity
							key={categoryKey}
							style={[
								styles.categoryButton,
								selectedCategory === categoryKey &&
									styles.categoryButtonActive,
							]}
							onPress={() => setSelectedCategory(categoryKey)}>
							<Text
								style={[
									styles.categoryText,
									selectedCategory === categoryKey &&
										styles.categoryTextActive,
								]}>
								{CREATIVE_ASSET_TYPES[categoryKey].category}
							</Text>
						</TouchableOpacity>
					))}
				</View>
			</ScrollView>

			{selectedCategory && (
				<ScrollView style={styles.assetList}>
					{CREATIVE_ASSET_TYPES[selectedCategory].assets.map(
						(asset, index) => (
							<TouchableOpacity
								key={`${selectedCategory}-${index}`}
								style={styles.assetItem}
								onPress={() => {
									onSelect({
										...asset,
										_id: `${Date.now()}-${index}`,
										status: "Pending",
										files: [],
										comments: [],
										brief: "",
										history: [
											{
												action: "Created",
												timestamp:
													new Date().toISOString(),
												details:
													"Asset added to request",
											},
										],
									});
									setSelectedCategory(null);
								}}>
								<View style={styles.assetItemHeader}>
									<View style={styles.assetItemInfo}>
										<Text style={styles.assetTitle}>
											{asset.type}
										</Text>
										{renderSizePreview(asset.size)}
									</View>
								</View>
								<Text style={styles.assetDesc}>
									{asset.description}
								</Text>
								<Text style={styles.assetSpecs}>
									{asset.specs}
								</Text>
							</TouchableOpacity>
						),
					)}
				</ScrollView>
			)}
		</View>
	);
};
