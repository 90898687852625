import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Modal,
	TextInput,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

const CHECKLIST_CATEGORIES = {
	PRODUCT: "Product Readiness",
	RETAIL: "Retail Readiness",
	CONTENT: "Content Readiness",
	TRACKING: "Tracking Setup",
	COMPLIANCE: "Compliance",
};

const CHECKLIST_ITEMS = [
	{
		id: 1,
		category: CHECKLIST_CATEGORIES.PRODUCT,
		title: "Product Stock Availability",
		description: "Confirm sufficient stock levels for campaign duration",
		subItems: [
			"Check warehouse stock levels",
			"Verify stock allocation per retailer",
			"Confirm production schedule if needed",
		],
		critical: true,
	},
	{
		id: 2,
		category: CHECKLIST_CATEGORIES.PRODUCT,
		title: "Product Pricing",
		description: "Verify pricing strategy and promotional offers",
		subItems: [
			"Confirm promotional pricing",
			"Check competitor pricing",
			"Verify margin calculations",
		],
		critical: true,
	},
	{
		id: 3,
		category: CHECKLIST_CATEGORIES.RETAIL,
		title: "Retailer Stock Levels",
		description: "Confirm stock levels across retail partners",
		subItems: [
			"Check current stock levels",
			"Verify incoming stock",
			"Confirm stock distribution plan",
		],
		critical: true,
	},
	{
		id: 4,
		category: CHECKLIST_CATEGORIES.RETAIL,
		title: "Retailer Platform Setup",
		description: "Ensure all retail platforms are campaign-ready",
		subItems: [
			"Verify product listings",
			"Check promotional banners",
			"Confirm tracking pixels",
		],
		critical: true,
	},
	{
		id: 5,
		category: CHECKLIST_CATEGORIES.CONTENT,
		title: "Creative Assets",
		description: "Verify all required creative content is ready",
		subItems: [
			"Check campaign visuals",
			"Verify video content",
			"Confirm copy and messaging",
		],
		critical: true,
	},
	{
		id: 6,
		category: CHECKLIST_CATEGORIES.CONTENT,
		title: "Platform-Specific Content",
		description: "Ensure content is optimized for each platform",
		subItems: [
			"Social media content ready",
			"Website banners prepared",
			"Email marketing content approved",
		],
		critical: false,
	},
	{
		id: 7,
		category: CHECKLIST_CATEGORIES.TRACKING,
		title: "Analytics Setup",
		description: "Confirm all tracking mechanisms are in place",
		subItems: [
			"UTM parameters configured",
			"Conversion tracking setup",
			"Performance dashboards ready",
		],
		critical: true,
	},
	{
		id: 8,
		category: CHECKLIST_CATEGORIES.COMPLIANCE,
		title: "Legal Compliance",
		description: "Ensure all legal requirements are met",
		subItems: [
			"Terms and conditions approved",
			"Promotional claims verified",
			"Privacy compliance checked",
		],
		critical: true,
	},
];

export default class CampaignLaunchChecklist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checklist: CHECKLIST_ITEMS.map((item) => ({
				...item,
				status: "pending",
				notes: "",
				completedSubItems: [],
			})),
			showNoteModal: false,
			selectedItem: null,
			canLaunch: false,
		};
	}

	updateItemStatus = (itemId, status) => {
		this.setState(
			(prevState) => ({
				checklist: prevState.checklist.map((item) =>
					item.id === itemId ? { ...item, status } : item,
				),
			}),
			this.checkLaunchReadiness,
		);
	};

	toggleSubItem = (itemId, subItem) => {
		this.setState(
			(prevState) => ({
				checklist: prevState.checklist.map((item) => {
					if (item.id === itemId) {
						const completedSubItems =
							item.completedSubItems.includes(subItem)
								? item.completedSubItems.filter(
										(si) => si !== subItem,
								  )
								: [...item.completedSubItems, subItem];

						return {
							...item,
							completedSubItems,
							status:
								completedSubItems.length ===
								item.subItems.length
									? "completed"
									: "in-progress",
						};
					}
					return item;
				}),
			}),
			this.checkLaunchReadiness,
		);
	};

	checkLaunchReadiness = () => {
		const canLaunch = this.state.checklist
			.filter((item) => item.critical)
			.every((item) => item.status === "completed");

		this.setState({ canLaunch });
	};

	render() {
		return (
			<View style={styles.container}>
				<View style={styles.header}>
					<Text style={styles.title}>Campaign Launch Checklist</Text>
					<View style={styles.launchStatus}>
						<Text style={styles.launchStatusText}>
							Launch Status:{" "}
							{this.state.canLaunch ? "Ready" : "Pending"}
						</Text>
						<View
							style={[
								styles.statusIndicator,
								{
									backgroundColor: this.state.canLaunch
										? APP_COLOURS.GREEN
										: APP_COLOURS.YELLOW,
								},
							]}
						/>
					</View>
				</View>

				<ScrollView style={styles.checklistContainer}>
					{Object.values(CHECKLIST_CATEGORIES).map((category) => (
						<View key={category} style={styles.categorySection}>
							<Text style={styles.categoryTitle}>{category}</Text>

							{this.state.checklist
								.filter((item) => item.category === category)
								.map((item) => (
									<View
										key={item.id}
										style={styles.checklistItem}>
										<View style={styles.itemHeader}>
											<Text style={styles.itemTitle}>
												{item.title}
												{item.critical && (
													<Text
														style={
															styles.criticalTag
														}>
														{" "}
														*
													</Text>
												)}
											</Text>
											<Text
												style={styles.itemDescription}>
												{item.description}
											</Text>
										</View>

										<View style={styles.subItemsContainer}>
											{item.subItems.map(
												(subItem, index) => (
													<TouchableOpacity
														key={index}
														style={styles.subItem}
														onPress={() =>
															this.toggleSubItem(
																item.id,
																subItem,
															)
														}>
														<View
															style={[
																styles.checkbox,
																item.completedSubItems.includes(
																	subItem,
																) &&
																	styles.checkboxChecked,
															]}
														/>
														<Text
															style={
																styles.subItemText
															}>
															{subItem}
														</Text>
													</TouchableOpacity>
												),
											)}
										</View>

										<View style={styles.itemFooter}>
											<TouchableOpacity
												style={styles.noteButton}
												onPress={() =>
													this.setState({
														showNoteModal: true,
														selectedItem: item,
													})
												}>
												<Text
													style={
														styles.noteButtonText
													}>
													Add Note
												</Text>
											</TouchableOpacity>
											<Text
												style={[
													styles.status,
													{
														color:
															item.status ===
															"completed"
																? APP_COLOURS.GREEN
																: APP_COLOURS.YELLOW,
													},
												]}>
												{item.status.toUpperCase()}
											</Text>
										</View>
									</View>
								))}
						</View>
					))}
				</ScrollView>

				{this.state.canLaunch && (
					<TouchableOpacity style={styles.launchButton}>
						<Text style={styles.launchButtonText}>
							Launch Campaign
						</Text>
					</TouchableOpacity>
				)}

				<Modal
					visible={this.state.showNoteModal}
					transparent={true}
					animationType='slide'>
					<View style={styles.modalOverlay}>
						<View style={styles.modalContent}>
							<Text style={styles.modalTitle}>Add Note</Text>
							<TextInput
								style={styles.noteInput}
								multiline
								placeholder='Enter notes here...'
								value={this.state.selectedItem?.notes}
								onChangeText={(text) => {
									if (this.state.selectedItem) {
										this.setState((prevState) => ({
											checklist: prevState.checklist.map(
												(item) =>
													item.id ===
													this.state.selectedItem.id
														? {
																...item,
																notes: text,
														  }
														: item,
											),
										}));
									}
								}}
							/>
							<TouchableOpacity
								style={styles.closeButton}
								onPress={() =>
									this.setState({
										showNoteModal: false,
										selectedItem: null,
									})
								}>
								<Text style={styles.closeButtonText}>
									Close
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</Modal>
			</View>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		backgroundColor: "#f8fafc",
		padding: 20,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	title: {
		fontSize: 24,
		fontWeight: "bold",
		color: APP_COLOURS.BG2,
	},
	launchStatus: {
		flexDirection: "row",
		alignItems: "center",
	},
	launchStatusText: {
		fontSize: 16,
		marginRight: 10,
		color: APP_COLOURS.BG2,
	},
	statusIndicator: {
		width: 12,
		height: 12,
		borderRadius: 6,
	},
	checklistContainer: {
		flex: 1,
	},
	categorySection: {
		marginBottom: 25,
	},
	categoryTitle: {
		fontSize: 18,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 15,
	},
	checklistItem: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 15,
		marginBottom: 15,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 3,
	},
	itemHeader: {
		marginBottom: 15,
	},
	itemTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 5,
	},
	criticalTag: {
		color: APP_COLOURS.RED,
		fontWeight: "bold",
	},
	itemDescription: {
		fontSize: 14,
		color: "#666",
	},
	subItemsContainer: {
		marginBottom: 15,
	},
	subItem: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 10,
	},
	checkbox: {
		width: 20,
		height: 20,
		borderRadius: 4,
		borderWidth: 2,
		borderColor: APP_COLOURS.PURPLE,
		marginRight: 10,
	},
	checkboxChecked: {
		backgroundColor: APP_COLOURS.PURPLE,
	},
	subItemText: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
	},
	itemFooter: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: 10,
	},
	noteButton: {
		backgroundColor: "#f3f4f6",
		padding: 8,
		paddingHorizontal: 12,
		borderRadius: 5,
	},
	noteButtonText: {
		color: APP_COLOURS.BG2,
		fontSize: 12,
	},
	status: {
		fontSize: 12,
		fontWeight: "600",
	},
	launchButton: {
		backgroundColor: APP_COLOURS.GREEN,
		padding: 15,
		borderRadius: 8,
		alignItems: "center",
		marginTop: 20,
	},
	launchButtonText: {
		color: "white",
		fontSize: 16,
		fontWeight: "600",
	},
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		width: "90%",
		maxWidth: 500,
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 15,
	},
	noteInput: {
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 5,
		padding: 10,
		height: 100,
		marginBottom: 15,
		textAlignVertical: "top",
	},
	closeButton: {
		backgroundColor: APP_COLOURS.RED,
		padding: 10,
		borderRadius: 5,
		alignItems: "center",
	},
	closeButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
};
