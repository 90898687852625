import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}

export default class FullCalendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDates: false,
			startDate: "",
			endDate: "",
			selectedDate: moment(this.props.selectedDate).format("DD/MM/YYYY"),
			currentMonthInView: moment(this.props.selectedDate),
			currentMomnth: moment(this.props.selectedDate),
			dates: Array.from(
				Array(moment(this.props.selectedDate).daysInMonth()).keys(),
			),
			weekStart:
				moment(moment(this.props.selectedDate).startOf("month")).format(
					"w",
				) - 1,
			weekEnd:
				moment(moment(this.props.selectedDate).endOf("month")).format(
					"w",
				) + 1,
			totalWeeks:
				moment(moment(this.props.selectedDate).endOf("month")).format(
					"w",
				) -
				moment(moment(this.props.selectedDate).startOf("month")).format(
					"w",
				) +
				1,

			datesArray: [],
			dayOfWeekArray: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

			schedule: [],

			loading: true,
			scheduleType: "",

			compWidth: this.props.compWidth || 350,
		};
	}

	componentDidMount() {
		this.sideCalc();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedDate !== this.props.selectedDate) {
			this.setState({
				selectedDate: moment(this.props.selectedDate).format(
					"DD/MM/YYYY",
				),
			});
		}
	}

	changeMonth = async (change) => {
		let newMonth = "";
		if (change === "next") {
			newMonth = moment(this.state.currentMonthInView).add(1, "month");
		} else {
			newMonth = moment(this.state.currentMonthInView).subtract(
				1,
				"month",
			);
		}

		await this.setState({
			currentMonthInView: newMonth,
		});

		this.sideCalc();
	};

	render() {
		const calendarList = this.props.calendarList || [];

		return (
			<View style={styles.container}>
				<View
					onLayout={(e) => {
						this.setState({
							layoutWidth: e.nativeEvent.layout.width,
							loading: false,
						});
					}}>
					<View style={styles.header}>
						<TouchableOpacity
							onPress={() => this.changeMonth("prev")}>
							<AutoHeightImage
								width={50}
								height={35}
								source={require("../../../assets/images/left_btn.png")}
							/>
						</TouchableOpacity>
						<View>
							<Text style={styles.monthText}>
								{moment(this.state.currentMonthInView).format(
									"MMMM YYYY",
								)}
							</Text>
						</View>
						<TouchableOpacity
							onPress={() => this.changeMonth("next")}>
							<AutoHeightImage
								width={50}
								height={35}
								source={require("../../../assets/images/right_btn.png")}
							/>
						</TouchableOpacity>
					</View>

					<View style={styles.calendarContainer}>
						{this.heading()}
						<View style={styles.datesContainer}>
							{this.state.datesArray.map((d, i) =>
								this.renderDate(d, i, calendarList),
							)}
						</View>
					</View>
				</View>
			</View>
		);
	}

	renderDate = (d, i, calendarList) => {
		const today = moment().format("DD/MM/YYYY");
		const layoutWidth = this.state.compWidth / 7;
		const event = calendarList.filter(
			(c) =>
				moment(c.date).format("DDMMYYYY") ===
				moment(d, "DD/MM/YYYY").format("DDMMYYYY"),
		);

		const isAfter = moment(d, "DD/MM/YYYY").isSameOrAfter(
			moment(this.state.startDate, "DD/MM/YYYY"),
		);
		const isBefore = moment(this.state.endDate, "DD/MM/YYYY").isSameOrAfter(
			moment(d, "DD/MM/YYYY"),
		);

		return (
			<View
				key={i}
				style={[
					styles.dateCell,
					{
						width: layoutWidth - 0.5,
						height: layoutWidth - 0.5,
						backgroundColor:
							isAfter && isBefore
								? "rgba(63, 101, 244, 0.2)"
								: "transparent",
					},
				]}>
				<TouchableOpacity
					onPress={() => {
						this.setState({ selectedDate: d, endDate: d });
						if (this.props.endDate) {
							this.props.endDate(
								moment(d, "DD/MM/YYYY")
									.endOf("day")
									.toISOString(),
							);
						}
					}}>
					<View
						style={[
							styles.dateCellInner,
							{
								width: layoutWidth - 1,
								height: layoutWidth - 1,
								backgroundColor:
									this.state.selectedDate === d
										? APP_COLOURS.RED2
										: "transparent",
								borderWidth: today === d ? 1 : 0,
								opacity: this.getDateOpacity(d),
							},
						]}>
						<Text
							style={[
								styles.dateText,
								{
									color:
										this.state.selectedDate === d
											? "#FFF"
											: APP_COLOURS.BG2,
									fontWeight:
										this.state.selectedDate === d
											? "600"
											: "300",
								},
							]}>
							{moment(d, "DD/MM/YYYY").format("DD")}
						</Text>
						{this.renderDateIndicators(d, event)}
					</View>
				</TouchableOpacity>
			</View>
		);
	};

	getDateOpacity = (date) => {
		const isCurrentMonth =
			moment(date, "DD/MM/YYYY").format("MM") ===
			moment(this.state.currentMonthInView).format("MM");
		const isWeekend = ["Sun", "Sat"].includes(
			moment(date, "DD/MM/YYYY").format("ddd"),
		);

		if (!isCurrentMonth) return 0.3;
		if (isWeekend) return 0.4;
		return 1;
	};

	renderDateIndicators = (date, events) => (
		<View style={styles.indicators}>
			{events
				.filter((evt) => evt.category === "MEETING")
				.map((_, iii) => (
					<View
						key={iii}
						style={[
							styles.indicator,
							{ backgroundColor: APP_COLOURS.RED },
						]}
					/>
				))}
			{events
				.filter((evt) => evt.category === "EVENT")
				.map((_, ii) => (
					<View
						key={ii}
						style={[
							styles.indicator,
							{ backgroundColor: APP_COLOURS.BLUE },
						]}
					/>
				))}
		</View>
	);

	heading = () => (
		<View style={styles.weekDays}>
			{this.state.dayOfWeekArray.map((d, i) => (
				<View
					key={i}
					style={[
						styles.weekDay,
						{ width: this.state.compWidth / 7 },
					]}>
					<Text style={styles.weekDayText}>{d}</Text>
				</View>
			))}
		</View>
	);

	sideCalc = () => {
		let datesArray = [];
		let curr = this.state.currentMonthInView;
		let startDateInMonth = moment(curr).startOf("month");
		let startDayInMonth = moment(curr).startOf("month").format("ddd");
		let dayOfWeekArray = this.state.dayOfWeekArray;
		let arr = dayOfWeekArray.indexOf(startDayInMonth);

		if (arr > 0) {
			let dLoop = Array.from(Array(arr).keys());
			for (const d of dLoop) {
				let doff = d + 1;
				let date = moment(startDateInMonth)
					.subtract(doff, "days")
					.format("DD/MM/YYYY");
				datesArray.push(date);
			}
		}

		let dayesInMonth = Array.from(Array(moment(curr).daysInMonth()).keys());
		for (const d of dayesInMonth) {
			let date = moment(startDateInMonth)
				.add(d, "day")
				.format("DD/MM/YYYY");
			datesArray.push(date);
		}

		let datesSorted = datesArray.sort(
			(a, b) =>
				moment(a, "DD/MM/YYYY").unix() - moment(b, "DD/MM/YYYY").unix(),
		);

		this.setState({ datesArray: datesSorted });
	};
}

const styles = {
	container: {
		flex: 1,
		backgroundColor: "#FFF",
		borderRadius: 10,
		padding: 15,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	monthText: {
		color: APP_COLOURS.BG2,
		fontWeight: "700",
		fontSize: 16,
	},
	calendarContainer: {
		alignItems: "center",
	},
	weekDays: {
		flexDirection: "row",
		marginBottom: 10,
	},
	weekDay: {
		justifyContent: "center",
		alignItems: "center",
	},
	weekDayText: {
		color: APP_COLOURS.BG2,
		fontWeight: "600",
		fontSize: 13,
	},
	datesContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		paddingHorizontal: 3,
	},
	dateCell: {
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
	},
	dateCellInner: {
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		borderColor: APP_COLOURS.BG2,
	},
	dateText: {
		fontSize: 14,
	},
	indicators: {
		flexDirection: "row",
		justifyContent: "center",
		marginTop: 2,
	},
	indicator: {
		width: 5,
		height: 5,
		borderRadius: 3,
		marginHorizontal: 1,
	},
};
