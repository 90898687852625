import React, { useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Modal,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import Papa from "papaparse";

const BulkUploadBudget = ({ isVisible, onClose, onUpload, selectedBrand }) => {
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState(null);
	const [progress, setProgress] = useState({ current: 0, total: 0 });

	const handleFileUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		setUploading(true);
		setError(null);

		Papa.parse(file, {
			header: true,
			complete: async (results) => {
				try {
					const { data } = results;
					setProgress({ current: 0, total: data.length });

					for (let i = 0; i < data.length; i++) {
						const row = data[i];
						const entry = {
							brand: selectedBrand.title,
							allocation: row.allocation,
							pl_allocation: row.pl_allocation,
							spend: {
								jan: parseFloat(row.jan) || 0,
								feb: parseFloat(row.feb) || 0,
								mar: parseFloat(row.mar) || 0,
								apr: parseFloat(row.apr) || 0,
								may: parseFloat(row.may) || 0,
								jun: parseFloat(row.jun) || 0,
								jul: parseFloat(row.jul) || 0,
								aug: parseFloat(row.aug) || 0,
								sep: parseFloat(row.sep) || 0,
								oct: parseFloat(row.oct) || 0,
								nov: parseFloat(row.nov) || 0,
								dec: parseFloat(row.dec) || 0,
							},
						};

						await onUpload(entry);
						setProgress((prev) => ({ ...prev, current: i + 1 }));
					}

					onClose();
				} catch (error) {
					setError("Error processing file: " + error.message);
				}
				setUploading(false);
			},
			error: (error) => {
				setError("Error parsing CSV: " + error.message);
				setUploading(false);
			},
		});
	};

	return (
		<Modal visible={isVisible} transparent={true} animationType='slide'>
			<View style={styles.modalOverlay}>
				<View style={styles.modalContent}>
					<Text style={styles.modalTitle}>
						Bulk Upload Budget Entries
					</Text>

					<Text style={styles.instructions}>
						Upload a CSV file with the following columns:{"\n"}
						allocation, pl_allocation, jan, feb, mar, apr, may, jun,
						jul, aug, sep, oct, nov, dec
					</Text>

					{!uploading && (
						<TouchableOpacity
							style={styles.uploadButton}
							onPress={() =>
								document.getElementById("csv-upload").click()
							}>
							<Text style={styles.uploadButtonText}>
								Select CSV File
							</Text>
						</TouchableOpacity>
					)}

					<input
						type='file'
						id='csv-upload'
						accept='.csv'
						style={{ display: "none" }}
						onChange={handleFileUpload}
					/>

					{uploading && (
						<View style={styles.progressContainer}>
							<ActivityIndicator
								size='small'
								color={APP_COLOURS.PURPLE}
							/>
							<Text style={styles.progressText}>
								Uploading: {progress.current} / {progress.total}
							</Text>
						</View>
					)}

					{error && <Text style={styles.errorText}>{error}</Text>}

					<TouchableOpacity
						style={styles.closeButton}
						onPress={onClose}
						disabled={uploading}>
						<Text style={styles.closeButtonText}>Cancel</Text>
					</TouchableOpacity>
				</View>
			</View>
		</Modal>
	);
};

const styles = {
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		width: "90%",
		maxWidth: 500,
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: "bold",
		marginBottom: 15,
		color: APP_COLOURS.BG2,
	},
	instructions: {
		fontSize: 14,
		marginBottom: 20,
		color: "#666",
		lineHeight: 20,
	},
	uploadButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 15,
		borderRadius: 5,
		alignItems: "center",
		marginBottom: 15,
	},
	uploadButtonText: {
		color: "white",
		fontSize: 16,
	},
	closeButton: {
		padding: 15,
		borderRadius: 5,
		alignItems: "center",
		borderWidth: 1,
		borderColor: APP_COLOURS.RED,
	},
	closeButtonText: {
		color: APP_COLOURS.RED,
		fontSize: 16,
	},
	progressContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginVertical: 15,
	},
	progressText: {
		marginLeft: 10,
		fontSize: 14,
		color: APP_COLOURS.BG2,
	},
	errorText: {
		color: APP_COLOURS.RED,
		marginBottom: 15,
		textAlign: "center",
	},
};

export default BulkUploadBudget;
