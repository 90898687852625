import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";
import tick from "../../../assets/images/tick.png";
import CampaignSelector from "./SelectChannels";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuid() {
	return "camp-xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class SelectRetailerCampaigns extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRetailer: this.props.exists,
			id: uuid(),
			retailer: "",
			channels: [],
			totalBudget: 0,
		};
	}

	componentDidUpdate(prevProps, prevState) {}

	render() {
		let { exists, d, i } = this.props;
		return (
			<View
				style={{
					marginBottom: 10,
					borderColor: APP_COLOURS.DESELECTEDCOLOR,
					borderWidth: 1,
					borderRadius: 10,
				}}>
				<TouchableOpacity
					onPress={async () => {
						if (exists) {
							await this.props.removeChannel(this.state.id);
							await this.setState({
								retailer: "",
								channels: [],
								totalBudget: 0,
							});
							await this.props.selectRetailer(d);
						} else {
							await this.props.selectRetailer(d);
							await this.setState({
								selectedRetailer: exists,
							});
						}
					}}>
					<View
						key={i}
						style={{
							flexDirection: "row",
							alignItems: "center",
							// backgroundColor: APP_COLOURS.DESELECTEDCOLOR,
							borderRadius: 5,
							marginBottom: 10,
						}}>
						<View
							style={{
								opacity: exists ? 1 : 0.3,
								padding: 5,
								borderRadius: 4,
								// borderWidth: 0.5,
								marginRight: 5,
							}}>
							<Image
								source={tick}
								style={{
									width: 16,
									height: 16,
									resizeMode: "contain",
									opacity: exists ? 1 : 0.2,
								}}
							/>
						</View>
						<View style={{ flex: 1 }}>
							<Text
								style={{
									// opacity: exists ? 1 : 0.4,
									fontFamily: "Gotham",
									fontSize: 18,
									color: APP_COLOURS.BG3,
									fontWeight: "500",

									padding: 4,
									borderRadius: 8,
								}}>
								{d}
							</Text>
						</View>

						<Text
							style={{
								opacity: exists ? 1 : 0.4,
								fontFamily: "Gotham",
								fontSize: 18,
								color: APP_COLOURS.BG3,
								fontWeight: "500",
								padding: 4,
								borderRadius: 8,
							}}>
							R {currencyFormat(this.state.totalBudget)}
						</Text>
					</View>
				</TouchableOpacity>

				{exists ? (
					<CampaignSelector
						retailer={d}
						spendType={this.props.spendType}
						selectedSitesParent={(channels) => {
							const total = channels.reduce((sum, campaign) => {
								const budget = parseFloat(campaign.budget) || 0;
								return sum + budget;
							}, 0);

							let obj = {
								id: this.state.id,
								retailer: d,
								channels: channels,
								totalBudget: total,
							};

							// console.log(obj);
							this.props.updateParent(obj);

							this.setState({
								...obj,
							});
						}}
					/>
				) : null}
			</View>
		);
	}
}
