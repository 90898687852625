import React, { useState } from "react";
import {
	View,
	ScrollView,
	Text,
	TouchableOpacity,
	StyleSheet,
	Image,
} from "react-native";

const TermsAndConditions = () => {
	const [accepted, setAccepted] = useState(false);

	const handleAccept = () => {
		// Handle the acceptance of the terms, e.g., save to state, send to server, etc.
		setAccepted(true);
		alert("You have accepted the terms and conditions.");
		// Here you might want to navigate the user away from the terms or record their acceptance in your application's state
	};

	return (
		<ScrollView style={styles.container}>
			{/* Accept button */}
			{/* {!accepted && (
				<TouchableOpacity style={styles.button} onPress={handleAccept}>
					<Text style={styles.buttonText}>
						Accept Terms and Conditions
					</Text>
				</TouchableOpacity>
			)} */}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		marginHorizontal: 20,
		width: window.innerWidth,
		maxWidth: 1000,
	},
	title: {},
	paragraph: {
		fontSize: 16,
		marginBottom: 10,
		lineHeight: 24,
	},
	button: {
		backgroundColor: "#0066CC",
		padding: 15,
		borderRadius: 5,
		marginTop: 20,
		marginBottom: 20,
	},
	buttonText: {
		color: "white",
		fontSize: 18,
		textAlign: "center",
	},
});

export default TermsAndConditions;
