import React, { useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	StyleSheet,
	FlatList,
	TextInput,
} from "react-native";
import { CHASELISTDETAILS } from "../../APP_VARS";

const APP_COLOURS = {
	BG3: "#333", // Replace with actual color
	DESELECTEDCOLOR: "#f2f2f2", // Replace with actual color
};

const tick = require("../../../assets/images/tick.png");

function uuid() {
	return "chann-xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

const CampaignSelector = ({ selectedSitesParent, spendType }) => {
	const [selectedItems, setSelectedItems] = useState([]);

	const toggleSelection = (item) => {
		const exists = selectedItems.some((site) => site.id === item.id);
		let updatedList;

		if (exists) {
			updatedList = selectedItems.filter((site) => site.id !== item.id);
		} else {
			updatedList = [...selectedItems, { ...item, budget: "" }];
		}

		setSelectedItems(updatedList);
		selectedSitesParent(updatedList); // Pass updated list to parent
	};

	const updateBudget = (id, budget) => {
		let budgetMinAgency = budget - budget * 0.085;

		if (spendType === "excludesAgency") {
			budgetMinAgency = budget;
		} else {
			budgetMinAgency = budget - budget * 0.085;
		}

		const updatedList = selectedItems.map((item) =>
			item.id === id
				? { ...item, budget, budgetMinAgency, uuid: uuid() }
				: item,
		);
		setSelectedItems(updatedList);
		selectedSitesParent(updatedList);
	};

	const renderCampaign = ({ item }) => {
		const exists = selectedItems.some((site) => site.id === item.id);
		const selectedItem = selectedItems.find((site) => site.id === item.id);

		return (
			<View
				style={{
					flexDirection: "row",
					// alignItems: "center",
					// backgroundColor: APP_COLOURS.DESELECTEDCOLOR,
					borderRadius: 5,
					marginBottom: 1,
					padding: 10,
					flex: 1,
				}}>
				<TouchableOpacity
					style={{ flex: 1 }}
					onPress={() => toggleSelection(item)}>
					<View style={styles.campaignContainer}>
						<View
							style={[
								styles.iconContainer,
								{ opacity: exists ? 1 : 0.2 },
							]}>
							<Image
								source={tick}
								style={[
									styles.tickImage,
									{ opacity: exists ? 1 : 0.2 },
								]}
							/>
						</View>
						<View>
							<Text
								style={[
									styles.campaignText,
									{ opacity: exists ? 1 : 0.4 },
								]}>
								{item.MediaOwner} - {item.Site} -{" "}
								{item.CampaignObjective}
							</Text>
						</View>
					</View>
				</TouchableOpacity>
				{exists && (
					<TextInput
						style={styles.budgetInput}
						keyboardType='numeric'
						placeholder='Enter budget'
						value={selectedItem?.budget || ""}
						onChangeText={(text) => updateBudget(item.id, text)}
					/>
				)}
			</View>
		);
	};

	return (
		<View style={styles.container}>
			<FlatList
				data={CHASELISTDETAILS}
				keyExtractor={(item, index) => index.toString()}
				renderItem={renderCampaign}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		padding: 1,
	},
	campaignContainer: {
		flexDirection: "row",
		alignItems: "center",
		padding: 5,
		// width: 600,
		flex: 1,
	},
	iconContainer: {
		padding: 5,
		borderRadius: 4,
		marginRight: 10,
	},
	tickImage: {
		width: 16,
		height: 16,
		resizeMode: "contain",
	},
	campaignText: {
		flex: 1,
		fontFamily: "Gotham",
		fontSize: 15,
		color: APP_COLOURS.BG3,
		fontWeight: "500",
		padding: 4,
		borderRadius: 8,
	},
	budgetInput: {
		width: 150,
		height: 40,
		borderColor: "#ccc",
		borderWidth: 1,
		borderRadius: 5,
		paddingHorizontal: 8,
		fontFamily: "Gotham",
		fontWeight: "500",
		fontSize: 14,
		textAlign: "right",
		backgroundColor: "#FFF",
	},
});

export default CampaignSelector;
