import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import {
	crudCreate,
	crudDelete,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import UsersSelectList from "../UsersSelectList";
import AutoHeightImage from "../AutoHeightImageComp";
import Campaigns from "./Campaigns";
import BrandTabs from "../BrandTabs";
import SmallerTabs from "../TopTabs/SmallerTabs";
import BrandKPIs from "./BrandKPIs";
import RetailerContacts from "./RetailerContacts";
import Budget from "./Budget";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function firstAndLast(array) {
	var firstItem = array[0];
	var lastItem = array[array.length - 1];

	var objOutput = {
		start: firstItem,
		end: lastItem,
	};

	return objOutput;
}

function calculateTotal(data) {
	let total = 0;
	data.forEach((item) => {
		total += parseFloat(item.total);
	});
	return total;
}

export default class PerformanceMediaIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedBrand: {
				id: "",
			},
			selectedTab: "KPIs",
		};
	}

	render() {
		return (
			<View
				style={{
					borderRadius: 10,
					width: "100%",
					flex: 1,
					opacity: this.state.loading ? 0 : 1,
				}}>
				<View style={{ flex: 1 }}>
					<View style={{ marginRight: 1 }}>
						<View
							style={{
								flex: 1,
								marginBottom: 5,
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 10,
							}}>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<View style={{ padding: 10 }}>
									<TouchableOpacity
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}
										onPress={() => {}}>
										<Text
											adjustsFontSizeToFit
											style={{
												fontWeight: "800",
												fontFamily: "Gotham",
												color: APP_COLOURS.BG2,
												fontSize: 13,
												marginLeft: 4,
											}}>
											tetrice
										</Text>
									</TouchableOpacity>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 28,
											fontWeight: "900",
											fontFamily: "Gotham",
											// marginTop: 20,
											// marginTop: 6,
										}}>
										Performance media
									</Text>

									<Text
										style={{
											fontSize: 16,
											color: APP_COLOURS.BG2,
											fontWeight: "400",
											padding: 2,
											// marginBottom: 20,
										}}>
										Performance media, SEO, PPC and creative
									</Text>
								</View>
								<View>
									<AutoHeightImage
										width={90}
										source={require("../../../assets/images/workfromhome.png")}
									/>
								</View>
							</View>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							backgroundColor: "#EFF5FC",
							padding: 5,
							borderRadius: 10,
						}}>
						<ScrollView>
							<BrandTabs
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								selectedSubPage={(d) =>
									this.setState({
										selectedBrand: d,
									})
								}
							/>

							{this.state.selectedBrand.id !== "" && (
								<>
									<SmallerTabs
										selectedSubPage={(d) => {
											this.setState({
												selectedTab: d,
											});
										}}
										tabs={[
											"KPIs",
											"Budgets",
											"Campaigns",
											"Retailers",
										]}
									/>

									{this.state.selectedTab === "KPIs" ? (
										<BrandKPIs
											selectedBrand={
												this.state.selectedBrand
											}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
										/>
									) : this.state.selectedTab === "Budgets" ? (
										<Budget
											selectedBrand={
												this.state.selectedBrand
											}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
										/>
									) : this.state.selectedTab ===
									  "Retailers" ? (
										<RetailerContacts
											selectedBrand={
												this.state.selectedBrand
											}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
										/>
									) : (
										<Campaigns
											{...this.state}
											selectedBrand={
												this.state.selectedBrand
											}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
										/>
									)}
								</>
							)}
						</ScrollView>
					</View>
				</View>
				{this.state.showModalArea ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: this.props.screenWidth,
								height: this.props.screenHeight,
								backgroundColor: "rgba(52, 52, 52, 0.6)",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									backgroundColor: "rgba(52, 52, 52, 0.6)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showModalArea: false,
									});
								}}
							/>
							<View
								style={{
									top: this.state.yPos + 10,
									left: this.state.xPos - 270,
									width: 350,
									height: 300,
									// backgroundColor: APP_COLOURS.BG,
									backgroundColor: "#FFF",
									borderRadius: 15,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<UsersSelectList
									assignment={
										this.state.selectedService.assignment
									}
									assignment_ids={
										this.state.selectedService
											.assignment_ids
									}
									isStandalone={true}
									addAssignment={(ass, ids) => {
										this.setState({
											showModalArea: "",
										});
										// this.addAssignment(ass, ids);
										console.log(
											this.state.selectedService.id,
											ass,
											ids,
										);

										let obj = {
											id: this.state.selectedService.id,
											assignment: ass,
											assignment_ids: ids,
										};
										crudUpdate(
											this.state.crudDocumentName,
											obj,
											(status, d) => {
												// console.log(status, d);
												console.log("updateItem");
												if (status === 200) {
													this.getAllServices();
												}
											},
										);
									}}
								/>
							</View>
						</View>
					</Modal>
				) : null}
			</View>
		);
	}

	async componentDidMount() {}
}
