import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import moment from "moment";
import AddBudgetEntry from "./AddBudgetEntry";
import EditBudgetEntry from "./EditBudgetEntry";
import { crudCreate, crudUpdate } from "../../CRUDHELPER";
import BulkUploadBudget from "./BulkUploadBudget";

function currencyFormat(num) {
	if (num === undefined || num === null) return "0.00";
	const number = parseFloat(num);
	if (isNaN(number)) return "0.00";
	return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const mockData = [
	{
		brand: "EIGHT HOUR",
		allocation: "DIGITAL RETAILERS",
		pl_allocation: "Digital (RETAILERS)",
		spend: {
			jan: 50000.0,
			feb: 160000.0,
			mar: 50000.0,
			apr: 50000.0,
			may: 180000.0,
			jun: 50000.0,
			jul: 150000.0,
			aug: 50000.0,
			sep: 50000.0,
			oct: 50000.0,
			nov: 20000.0,
			dec: 50000.0,
		},
	},
	{
		brand: "EIGHT HOUR",
		allocation: "DIRECTMARKETING",
		pl_allocation: "Other Consumer Promo DIRECT MARKETING",
		spend: {
			jan: 0,
			feb: 20000.0,
			mar: 0,
			apr: 0,
			may: 0,
			jun: 0,
			jul: 20000.0,
			aug: 0,
			sep: 0,
			oct: 0,
			nov: 0,
			dec: 0,
		},
	},
	// Add more mock data entries as needed
];

export default class Budget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			budgetData: [],
			showAddModal: false,
			showEditModal: false,
			selectedEntry: null,
			selectedIndex: null,
			error: null,
			showBulkUploadModal: false,
		};
	}

	componentDidMount() {
		this.fetchBudgetData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedBrand.id !== this.props.selectedBrand.id) {
			this.fetchBudgetData();
		}
	}

	fetchBudgetData = async () => {
		this.setState({ loading: true });
		try {
			let user_data = await localStorage.getItem("user_data");
			user_data = JSON.parse(user_data);

			if (!user_data || !user_data.accessToken) {
				throw new Error("No access token found");
			}

			const requestOptions = {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Origin: "",
					dataType: "json",
					authorization: `Bearer ${user_data.accessToken}`,
				},
				body: JSON.stringify({
					brand_id: this.props.selectedBrand.id,
				}),
			};

			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook/aaf081a0-e4af-44b3-8120-09174ecdbf12",
				requestOptions,
			);

			if (response.status === 400) {
				this.setState({
					budgetData: [],
					loading: false,
					error: null,
				});
				return;
			}

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			this.setState({
				budgetData: data,
				loading: false,
				error: null,
			});
		} catch (error) {
			console.error("Error fetching budget data:", error);
			this.setState({
				error: error.message || "Failed to load budget data",
				loading: false,
			});
		}
	};

	calculateQuarterTotal = (data, months) => {
		return months.reduce((sum, month) => {
			const value = data.spend[month];
			if (value === undefined || value === null) return sum;
			const number = parseFloat(value);
			return sum + (isNaN(number) ? 0 : number);
		}, 0);
	};

	calculateRowTotal = (data) => {
		if (!data.spend) return 0;
		return Object.values(data.spend).reduce((sum, amount) => {
			if (amount === undefined || amount === null) return sum;
			const number = parseFloat(amount);
			return sum + (isNaN(number) ? 0 : number);
		}, 0);
	};

	handleNewEntry = async (entry) => {
		try {
			let user_data = await localStorage.getItem("user_data");
			user_data = JSON.parse(user_data);

			if (!user_data || !user_data.accessToken) {
				throw new Error("No access token found");
			}

			const requestOptions = {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Origin: "",
					dataType: "json",
					authorization: `Bearer ${user_data.accessToken}`,
				},
				body: JSON.stringify({
					...entry,
					brand_id: this.props.selectedBrand.id,
					brand: this.props.selectedBrand,
				}),
			};

			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook-test/fa4cc0dc-8774-4f61-b8b2-1425bf2b6882",
				requestOptions,
			);

			if (!response.ok) {
				throw new Error("Failed to add budget entry");
			}

			await this.fetchBudgetData();
			this.setState({ showAddModal: false });
		} catch (error) {
			console.error("Error adding budget entry:", error);
			alert("Failed to add budget entry: " + error.message);
		}
	};

	handleEditEntry = async (updatedEntry, index) => {
		try {
			let user_data = await localStorage.getItem("user_data");
			user_data = JSON.parse(user_data);

			if (!user_data || !user_data.accessToken) {
				throw new Error("No access token found");
			}

			const requestOptions = {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Origin: "",
					dataType: "json",
					authorization: `Bearer ${user_data.accessToken}`,
				},
				body: JSON.stringify({
					...updatedEntry,
					brand_id: this.props.selectedBrand.id,
					brand: this.props.selectedBrand,
				}),
			};

			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook-test/fa4cc0dc-8774-4f61-b8b2-1425bf2b6882",
				requestOptions,
			);

			if (!response.ok) {
				throw new Error("Failed to update budget entry");
			}

			await this.fetchBudgetData();
			this.setState({
				showEditModal: false,
				selectedEntry: null,
				selectedIndex: null,
			});
		} catch (error) {
			console.error("Error updating budget entry:", error);
			alert("Failed to update budget entry: " + error.message);
		}
	};

	// Add this method to calculate totals for all entries
	calculateColumnTotals = () => {
		const totals = {
			q1: 0,
			q2: 0,
			q3: 0,
			q4: 0,
			jan: 0,
			feb: 0,
			mar: 0,
			apr: 0,
			may: 0,
			jun: 0,
			jul: 0,
			aug: 0,
			sep: 0,
			oct: 0,
			nov: 0,
			dec: 0,
			total: 0,
		};

		this.state.budgetData.forEach((item) => {
			// Add monthly totals
			Object.entries(item.spend || {}).forEach(([month, amount]) => {
				totals[month] += parseFloat(amount) || 0;
			});

			// Add quarter totals
			totals.q1 += this.calculateQuarterTotal(item, [
				"jan",
				"feb",
				"mar",
			]);
			totals.q2 += this.calculateQuarterTotal(item, [
				"apr",
				"may",
				"jun",
			]);
			totals.q3 += this.calculateQuarterTotal(item, [
				"jul",
				"aug",
				"sep",
			]);
			totals.q4 += this.calculateQuarterTotal(item, [
				"oct",
				"nov",
				"dec",
			]);

			// Add to grand total
			totals.total += this.calculateRowTotal(item);
		});

		return totals;
	};

	render() {
		if (this.state.loading) {
			return (
				<View style={styles.loadingContainer}>
					<ActivityIndicator
						size='large'
						color={APP_COLOURS.PURPLE}
					/>
					<Text style={styles.loadingText}>
						Loading budget data...
					</Text>
				</View>
			);
		}

		if (this.state.error) {
			return (
				<View style={styles.errorContainer}>
					<Text style={styles.errorText}>{this.state.error}</Text>
					<TouchableOpacity
						style={styles.retryButton}
						onPress={this.fetchBudgetData}>
						<Text style={styles.retryButtonText}>Retry</Text>
					</TouchableOpacity>
				</View>
			);
		}

		const totals = this.calculateColumnTotals();

		return (
			<View style={{ flex: 1, padding: 5, backgroundColor: "#EFF5FC" }}>
				<ScrollView horizontal>
					<View>
						<View style={styles.headerRow}>
							<Text style={[styles.headerCell, { width: 120 }]}>
								BRAND
							</Text>
							<Text style={[styles.headerCell, { width: 120 }]}>
								FRANCHISE
							</Text>
							<Text style={[styles.headerCell, { width: 120 }]}>
								ALLOCATION
							</Text>
							<Text style={[styles.headerCell, { width: 200 }]}>
								P&L SPEND ALLOCATION
							</Text>

							<Text style={[styles.headerCell, { width: 60 }]}>
								Q1
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								JAN
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								FEB
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								MARCH
							</Text>

							<Text style={[styles.headerCell, { width: 60 }]}>
								Q2
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								APRIL
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								MAY
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								JUNE
							</Text>

							<Text style={[styles.headerCell, { width: 60 }]}>
								Q3
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								JULY
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								AUG
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								SEP
							</Text>

							<Text style={[styles.headerCell, { width: 60 }]}>
								Q4
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								OCT
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								NOV
							</Text>
							<Text style={[styles.headerCell, { width: 80 }]}>
								DEC
							</Text>

							<Text style={[styles.headerCell, { width: 100 }]}>
								TOTAL
							</Text>
						</View>

						<ScrollView>
							{this.state.budgetData.map((item, index) => (
								<TouchableOpacity
									key={index}
									onPress={() =>
										this.setState({
											showEditModal: true,
											selectedEntry: item,
											selectedIndex: index,
										})
									}
									style={[
										styles.row,
										{
											backgroundColor:
												index % 2 === 0
													? "#FFF"
													: "#F5F5F5",
										},
									]}>
									<Text style={[styles.cell, { width: 120 }]}>
										{typeof item.brand === "object"
											? item.brand.title
											: item.brand}
									</Text>
									<Text style={[styles.cell, { width: 120 }]}>
										{item.franchise || ""}
									</Text>
									<Text style={[styles.cell, { width: 120 }]}>
										{item.allocation}
									</Text>
									<Text style={[styles.cell, { width: 200 }]}>
										{item.pl_allocation}
									</Text>

									<Text style={[styles.cell, { width: 60 }]}>
										{currencyFormat(
											this.calculateQuarterTotal(item, [
												"jan",
												"feb",
												"mar",
											]),
										)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.jan || 0)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.feb || 0)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.mar || 0)}
									</Text>

									<Text style={[styles.cell, { width: 60 }]}>
										{currencyFormat(
											this.calculateQuarterTotal(item, [
												"apr",
												"may",
												"jun",
											]),
										)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.apr || 0)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.may || 0)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.jun || 0)}
									</Text>

									<Text style={[styles.cell, { width: 60 }]}>
										{currencyFormat(
											this.calculateQuarterTotal(item, [
												"jul",
												"aug",
												"sep",
											]),
										)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.jul || 0)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.aug || 0)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.sep || 0)}
									</Text>

									<Text style={[styles.cell, { width: 60 }]}>
										{currencyFormat(
											this.calculateQuarterTotal(item, [
												"oct",
												"nov",
												"dec",
											]),
										)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.oct || 0)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.nov || 0)}
									</Text>
									<Text style={[styles.cell, { width: 80 }]}>
										{currencyFormat(item.spend?.dec || 0)}
									</Text>

									<Text style={[styles.cell, { width: 100 }]}>
										{currencyFormat(
											this.calculateRowTotal(item),
										)}
									</Text>
								</TouchableOpacity>
							))}

							{/* Add totals row */}
							<View style={styles.totalsRow}>
								<Text
									style={[styles.totalCell, { width: 120 }]}>
									TOTAL
								</Text>
								<Text
									style={[
										styles.totalCell,
										{ width: 120 },
									]}></Text>
								<Text
									style={[
										styles.totalCell,
										{ width: 200 },
									]}></Text>

								<Text style={[styles.totalCell, { width: 60 }]}>
									{currencyFormat(totals.q1)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.jan)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.feb)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.mar)}
								</Text>

								<Text style={[styles.totalCell, { width: 60 }]}>
									{currencyFormat(totals.q2)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.apr)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.may)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.jun)}
								</Text>

								<Text style={[styles.totalCell, { width: 60 }]}>
									{currencyFormat(totals.q3)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.jul)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.aug)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.sep)}
								</Text>

								<Text style={[styles.totalCell, { width: 60 }]}>
									{currencyFormat(totals.q4)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.oct)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.nov)}
								</Text>
								<Text style={[styles.totalCell, { width: 80 }]}>
									{currencyFormat(totals.dec)}
								</Text>

								<Text
									style={[styles.totalCell, { width: 100 }]}>
									{currencyFormat(totals.total)}
								</Text>
							</View>
						</ScrollView>
					</View>
				</ScrollView>

				<View style={styles.buttonContainer}>
					<TouchableOpacity
						style={styles.addButton}
						onPress={() => this.setState({ showAddModal: true })}>
						<Text style={styles.addButtonText}>
							+ Add Budget Entry
						</Text>
					</TouchableOpacity>

					<TouchableOpacity
						style={[styles.addButton, { marginLeft: 10 }]}
						onPress={() =>
							this.setState({ showBulkUploadModal: true })
						}>
						<Text style={styles.addButtonText}>Bulk Upload</Text>
					</TouchableOpacity>
				</View>

				{this.state.showAddModal && (
					<AddBudgetEntry
						onSubmit={this.handleNewEntry}
						close={() => this.setState({ showAddModal: false })}
					/>
				)}

				{this.state.showEditModal && (
					<EditBudgetEntry
						entry={this.state.selectedEntry}
						index={this.state.selectedIndex}
						onSubmit={this.handleEditEntry}
						close={() =>
							this.setState({
								showEditModal: false,
								selectedEntry: null,
								selectedIndex: null,
							})
						}
					/>
				)}

				{this.state.showBulkUploadModal && (
					<BulkUploadBudget
						isVisible={this.state.showBulkUploadModal}
						onClose={() =>
							this.setState({ showBulkUploadModal: false })
						}
						onUpload={this.handleNewEntry}
						selectedBrand={this.props.selectedBrand}
					/>
				)}
			</View>
		);
	}
}

const styles = {
	headerRow: {
		flexDirection: "row",
		backgroundColor: APP_COLOURS.BG2,
		padding: 10,
	},
	headerCell: {
		color: "#FFF",
		fontSize: 12,
		fontWeight: "600",
		padding: 5,
	},
	row: {
		flexDirection: "row",
		borderBottomWidth: 1,
		borderBottomColor: "#E8E8E8",
		padding: 10,
	},
	cell: {
		fontSize: 12,
		padding: 5,
	},
	addButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 15,
		borderRadius: 25,
		margin: 20,
		alignItems: "center",
	},
	addButtonText: {
		color: "#FFF",
		fontSize: 14,
		fontWeight: "600",
	},
	loadingContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#EFF5FC",
	},
	loadingText: {
		marginTop: 10,
		color: APP_COLOURS.BG2,
		fontSize: 14,
	},
	errorContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#EFF5FC",
		padding: 20,
	},
	errorText: {
		color: APP_COLOURS.RED,
		fontSize: 14,
		marginBottom: 15,
		textAlign: "center",
	},
	retryButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
	},
	retryButtonText: {
		color: "#FFF",
		fontSize: 14,
	},
	totalsRow: {
		flexDirection: "row",
		backgroundColor: APP_COLOURS.BG2,
		padding: 10,
		borderTopWidth: 2,
		borderTopColor: APP_COLOURS.PURPLE,
	},
	totalCell: {
		fontSize: 12,
		padding: 5,
		color: "#FFF",
		fontWeight: "600",
	},
	quarterCell: {
		backgroundColor: "#F8F9FA",
		fontWeight: "600",
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "center",
		padding: 20,
	},
};
