import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import YearlyComparison from "./YearlyComparison";
import ChannelPerformance from "./ChannelPerformance";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default class BrandKPIs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedView: "current",
			strategyNotes: {
				influencerStrategy: {
					description:
						"Leverage influencers more effectively to drive full-funnel results.",
					focus: "Measure UTM links to identify which influencers drive conversions versus awareness for better segmentation.",
				},
				omniChannelIntegration: {
					description:
						"Ensure consistent customer experiences across all digital and in-store touchpoints.",
					focus: "Coordinate digital campaigns with in-store promotions and activations.",
				},
				brandDirection: {
					description:
						"Focus on increasing profitability while reducing business costs.",
					focus: "Move towards a multi-movement (maturity) phase.",
				},
				searchStrategy: {
					description:
						"Optimize SEO efforts and invest in PPC advertising.",
					focus: "Improve keyword rankings and conversion rates.",
				},
			},
			kpiData: {
				// Growth Targets
				sellOutGrowthTarget: 12,
				currentSellOutGrowth: 8.5,
				targetYear: 2025,

				// E-commerce Metrics
				currentEcommerceContribution: 10,
				targetEcommerceContribution: 15,
				ecommerceGrowthRate: 25,
				ecommerceRevenue: 5000000,

				// Financial KPIs
				totalSpend: 2500000,
				targetROAS: 3.5,
				currentROAS: 2.8,
				ecommerceContribution: 10,
				sellOutGrowth: 12,

				// Performance KPIs
				impressions: 15000000,
				clicks: 250000,
				conversions: 12500,
				ctr: 1.67,
				conversionRate: 5,
				cpc: 10,
				cpa: 200,

				// Brand Growth KPIs
				marketShare: 15,
				brandAwareness: 65,
				crossCategoryPurchases: 25,
				customerRetention: 70,

				// Channel Performance
				socialMediaEngagement: 8.5,
				influencerROI: 4.2,
				affiliateConversions: 1200,
				seoRanking: 85,

				// Brand Strategy Metrics
				valuePropositionScore: 85,
				brandPositioningIndex: 78,
				eightHourCreamFocus: 92,
				customerSegmentReach: {
					premium: 65,
					midTier: 45,
					valueSeeker: 30,
				},
				segmentEngagement: {
					skincare: 75,
					fragrance: 45,
					cosmetics: 60,
				},
				brandPositioningMetrics: {
					luxuryPerception: 82,
					valueForMoney: 75,
					qualityPerception: 88,
					brandTrust: 85,
				},

				// Digital Marketing Performance
				targetedAdvertising: {
					reachScore: 82,
					demographicAccuracy: 91,
					brandAwarenessLift: 15,
				},
				contentEngagement: {
					platformAlignment: 88,
					retailerStrategyFit: 85,
					engagementRate: 7.2,
				},
				ecommerceOptimization: {
					etailerPresenceScore: 78,
					productPageOptimization: 85,
					conversionRateImprovement: 25,
				},
				abTesting: {
					activeTests: 12,
					successfulOptimizations: 8,
					performanceImprovement: 18,
				},
				influencerMetrics: {
					conversionRate: 3.2,
					awarenessContribution: 22,
					utmEffectiveness: 75,
					prAlignment: 90,
				},
				profitabilityMetrics: {
					costReduction: 15,
					profitabilityIncrease: 22,
					maturityScore: 68,
				},
				searchMetrics: {
					seoScore: 82,
					ppcEfficiency: 88,
					keywordRanking: 4.2,
					searchVisibility: 75,
				},
				omniChannelMetrics: {
					experienceConsistency: 85,
					channelIntegration: 78,
					campaignAlignment: 92,
					inStoreDigitalSync: 80,
				},
			},
		};
	}

	renderKPICard = (title, value, subtext, color = APP_COLOURS.BG2) => (
		<View
			style={{
				backgroundColor: "#FFF",
				borderRadius: 10,
				padding: 20,
				margin: 10,
				width: 280,
				shadowColor: "#000",
				shadowOffset: { width: 0, height: 2 },
				shadowOpacity: 0.1,
				shadowRadius: 4,
			}}>
			<Text
				style={{
					fontSize: 14,
					color: APP_COLOURS.BG3,
					fontWeight: "500",
					marginBottom: 10,
				}}>
				{title}
			</Text>
			<Text
				style={{
					fontSize: 24,
					color: color,
					fontWeight: "700",
					marginBottom: 5,
				}}>
				{value}
			</Text>
			{subtext && (
				<Text
					style={{
						fontSize: 12,
						color: APP_COLOURS.BG5,
						fontWeight: "400",
					}}>
					{subtext}
				</Text>
			)}
		</View>
	);

	renderStrategyNotes = (sectionKey) => {
		const notes = this.state.strategyNotes[sectionKey];
		if (!notes) return null;

		return (
			<View
				style={{
					marginTop: 20,
					padding: 15,
					backgroundColor: "#F8F9FA",
					borderRadius: 8,
					borderWidth: 1,
					borderColor: APP_COLOURS.BG5,
					marginHorizontal: 10,
				}}>
				<Text
					style={{
						fontSize: 14,
						fontWeight: "600",
						color: APP_COLOURS.BG2,
						marginBottom: 8,
					}}>
					Strategy Notes
				</Text>
				<TextInput
					style={{
						backgroundColor: "#FFF",
						borderWidth: 1,
						borderColor: APP_COLOURS.BG5,
						borderRadius: 6,
						padding: 12,
						marginBottom: 16,
						minHeight: 80,
						textAlignVertical: "top",
						color: APP_COLOURS.BG3,
						fontSize: 14,
					}}
					multiline
					placeholder='Enter strategy description...'
					value={notes.description}
					onChangeText={(text) => {
						this.setState((prevState) => ({
							strategyNotes: {
								...prevState.strategyNotes,
								[sectionKey]: {
									...prevState.strategyNotes[sectionKey],
									description: text,
								},
							},
						}));
					}}
				/>

				<Text
					style={{
						fontSize: 14,
						fontWeight: "600",
						color: APP_COLOURS.BG2,
						marginBottom: 8,
					}}>
					Focus Areas
				</Text>
				<TextInput
					style={{
						backgroundColor: "#FFF",
						borderWidth: 1,
						borderColor: APP_COLOURS.BG5,
						borderRadius: 6,
						padding: 12,
						marginBottom: 16,
						minHeight: 80,
						textAlignVertical: "top",
						color: APP_COLOURS.BG3,
						fontSize: 14,
					}}
					multiline
					placeholder='Enter focus areas...'
					value={notes.focus}
					onChangeText={(text) => {
						this.setState((prevState) => ({
							strategyNotes: {
								...prevState.strategyNotes,
								[sectionKey]: {
									...prevState.strategyNotes[sectionKey],
									focus: text,
								},
							},
						}));
					}}
				/>
			</View>
		);
	};

	render() {
		const { kpiData } = this.state;
		const { selectedBrand } = this.props;

		return (
			<View
				style={{
					flex: 1,
					backgroundColor: "#EFF5FC",
					padding: 20,
				}}>
				<View style={{ flexDirection: "row", marginBottom: 20 }}>
					<TouchableOpacity
						style={{
							backgroundColor:
								this.state.selectedView === "channel"
									? APP_COLOURS.BLUE
									: "#FFF",
							padding: 10,
							borderRadius: 8,
							marginRight: 10,
						}}
						onPress={() =>
							this.setState({ selectedView: "channel" })
						}>
						<Text
							style={{
								color:
									this.state.selectedView === "channel"
										? "#FFF"
										: APP_COLOURS.BG2,
								fontWeight: "600",
							}}>
							Channel Performance
						</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={{
							backgroundColor:
								this.state.selectedView === "current"
									? APP_COLOURS.BLUE
									: "#FFF",
							padding: 10,
							borderRadius: 8,
							marginRight: 10,
						}}
						onPress={() =>
							this.setState({ selectedView: "current" })
						}>
						<Text
							style={{
								color:
									this.state.selectedView === "current"
										? "#FFF"
										: APP_COLOURS.BG2,
								fontWeight: "600",
							}}>
							Current Performance
						</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={{
							backgroundColor:
								this.state.selectedView === "yearly"
									? APP_COLOURS.BLUE
									: "#FFF",
							padding: 10,
							borderRadius: 8,
						}}
						onPress={() =>
							this.setState({ selectedView: "yearly" })
						}>
						<Text
							style={{
								color:
									this.state.selectedView === "yearly"
										? "#FFF"
										: APP_COLOURS.BG2,
								fontWeight: "600",
							}}>
							Year-over-Year Analysis
						</Text>
					</TouchableOpacity>
				</View>

				{this.state.selectedView === "current" ? (
					<ScrollView>
						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Growth Targets
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Sell-Out Growth Target",
									`${kpiData.sellOutGrowthTarget}%`,
									`Target for ${kpiData.targetYear}`,
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Current Sell-Out Growth",
									`${kpiData.currentSellOutGrowth}%`,
									"Current growth rate",
									kpiData.currentSellOutGrowth >=
										kpiData.sellOutGrowthTarget
										? APP_COLOURS.GREEN
										: APP_COLOURS.RED,
								)}
								{this.renderKPICard(
									"Growth Gap",
									`${(
										kpiData.sellOutGrowthTarget -
										kpiData.currentSellOutGrowth
									).toFixed(1)}%`,
									"Remaining growth needed",
									APP_COLOURS.PURPLE,
								)}
								{this.renderKPICard(
									"Target Achievement",
									`${(
										(kpiData.currentSellOutGrowth /
											kpiData.sellOutGrowthTarget) *
										100
									).toFixed(1)}%`,
									"Progress towards target",
									APP_COLOURS.BLUE,
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								E-commerce Performance
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Current E-commerce Contribution",
									`${kpiData.currentEcommerceContribution}%`,
									"Current contribution to total sales",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"2025 E-commerce Target",
									`${kpiData.targetEcommerceContribution}%`,
									"Target contribution by 2025",
									APP_COLOURS.PURPLE,
								)}
								{this.renderKPICard(
									"E-commerce Growth Rate",
									`${kpiData.ecommerceGrowthRate}%`,
									"Year-over-year growth rate",
									kpiData.ecommerceGrowthRate >= 20
										? APP_COLOURS.GREEN
										: APP_COLOURS.RED,
								)}
								{this.renderKPICard(
									"E-commerce Revenue",
									`R ${currencyFormat(
										kpiData.ecommerceRevenue,
									)}`,
									"Total e-commerce revenue YTD",
									APP_COLOURS.BG2,
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Financial Performance
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Total Media Spend",
									`R ${currencyFormat(kpiData.totalSpend)}`,
									"Year to date spend",
								)}
								{this.renderKPICard(
									"Target ROAS",
									`${kpiData.targetROAS}x`,
									"Return on Ad Spend Target",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Current ROAS",
									`${kpiData.currentROAS}x`,
									"Current Return on Ad Spend",
									kpiData.currentROAS >= kpiData.targetROAS
										? APP_COLOURS.GREEN
										: APP_COLOURS.RED,
								)}
								{this.renderKPICard(
									"E-commerce Contribution",
									`${kpiData.ecommerceContribution}%`,
									`Target: ${kpiData.targetEcommerceContribution}% by 2025`,
									kpiData.ecommerceContribution >=
										kpiData.targetEcommerceContribution
										? APP_COLOURS.GREEN
										: APP_COLOURS.BLUE,
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Brand Growth Metrics
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Market Share",
									`${kpiData.marketShare}%`,
									"Current market share position",
								)}
								{this.renderKPICard(
									"Brand Awareness",
									`${kpiData.brandAwareness}%`,
									"Overall brand awareness",
								)}
								{this.renderKPICard(
									"Cross-Category Purchases",
									`${kpiData.crossCategoryPurchases}%`,
									"Customers buying multiple categories",
								)}
								{this.renderKPICard(
									"Customer Retention",
									`${kpiData.customerRetention}%`,
									"Customer retention rate",
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Channel Performance
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Social Media Engagement",
									`${kpiData.socialMediaEngagement}%`,
									"Average engagement rate",
								)}
								{this.renderKPICard(
									"Influencer ROI",
									`${kpiData.influencerROI}x`,
									"Return on influencer investment",
								)}
								{this.renderKPICard(
									"Affiliate Conversions",
									kpiData.affiliateConversions.toLocaleString(),
									"Monthly affiliate sales",
								)}
								{this.renderKPICard(
									"SEO Performance",
									`${kpiData.seoRanking}%`,
									"Average keyword ranking position",
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Campaign Performance
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Impressions",
									kpiData.impressions.toLocaleString(),
									"Total impressions delivered",
								)}
								{this.renderKPICard(
									"Click Through Rate",
									`${kpiData.ctr}%`,
									"Average CTR across campaigns",
								)}
								{this.renderKPICard(
									"Conversion Rate",
									`${kpiData.conversionRate}%`,
									"Average conversion rate",
								)}
								{this.renderKPICard(
									"Cost per Acquisition",
									`R ${kpiData.cpa}`,
									"Average cost per conversion",
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Brand Strategy & Positioning
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Eight Hour Cream Focus",
									`${kpiData.eightHourCreamFocus}%`,
									"Brand focus score for 8HR product line",
									APP_COLOURS.PURPLE,
								)}
								{this.renderKPICard(
									"Value Proposition Score",
									`${kpiData.valuePropositionScore}%`,
									"Overall value proposition effectiveness",
									kpiData.valuePropositionScore >= 80
										? APP_COLOURS.GREEN
										: APP_COLOURS.ORANGE,
								)}
								{this.renderKPICard(
									"Brand Positioning Index",
									`${kpiData.brandPositioningIndex}%`,
									"Market positioning effectiveness",
									kpiData.brandPositioningIndex >= 75
										? APP_COLOURS.GREEN
										: APP_COLOURS.ORANGE,
								)}
							</View>

							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.BG2,
									fontWeight: "500",
									marginTop: 20,
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Customer Segment Reach
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Premium Segment",
									`${kpiData.customerSegmentReach.premium}%`,
									"High-end customer reach",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Mid-Tier Segment",
									`${kpiData.customerSegmentReach.midTier}%`,
									"Mid-market customer reach",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Value Segment",
									`${kpiData.customerSegmentReach.valueSeeker}%`,
									"Value-conscious customer reach",
									APP_COLOURS.BLUE,
								)}
							</View>

							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.BG2,
									fontWeight: "500",
									marginTop: 20,
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Category Performance
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Skincare Engagement",
									`${kpiData.segmentEngagement.skincare}%`,
									"Skincare category performance",
									APP_COLOURS.GREEN,
								)}
								{this.renderKPICard(
									"Fragrance Engagement",
									`${kpiData.segmentEngagement.fragrance}%`,
									"Fragrance category performance",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Cosmetics Engagement",
									`${kpiData.segmentEngagement.cosmetics}%`,
									"Cosmetics category performance",
									APP_COLOURS.PURPLE,
								)}
							</View>

							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.BG2,
									fontWeight: "500",
									marginTop: 20,
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Brand Perception
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Luxury Perception",
									`${kpiData.brandPositioningMetrics.luxuryPerception}%`,
									"Brand luxury index score",
									APP_COLOURS.PURPLE,
								)}
								{this.renderKPICard(
									"Value for Money",
									`${kpiData.brandPositioningMetrics.valueForMoney}%`,
									"Price-value perception",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Quality Perception",
									`${kpiData.brandPositioningMetrics.qualityPerception}%`,
									"Product quality rating",
									APP_COLOURS.GREEN,
								)}
								{this.renderKPICard(
									"Brand Trust",
									`${kpiData.brandPositioningMetrics.brandTrust}%`,
									"Overall brand trust score",
									APP_COLOURS.BLUE,
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Digital Marketing Performance
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Demographic Targeting",
									`${kpiData.targetedAdvertising.demographicAccuracy}%`,
									"Targeting accuracy score",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Brand Awareness Lift",
									`${kpiData.targetedAdvertising.brandAwarenessLift}%`,
									"Increase in brand awareness",
									APP_COLOURS.GREEN,
								)}
								{this.renderKPICard(
									"Content Platform Alignment",
									`${kpiData.contentEngagement.platformAlignment}%`,
									"Content-platform fit score",
									APP_COLOURS.PURPLE,
								)}
								{this.renderKPICard(
									"Retailer Strategy Alignment",
									`${kpiData.contentEngagement.retailerStrategyFit}%`,
									"Content-retailer strategy fit",
									APP_COLOURS.BLUE,
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Testing & Optimization
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Active A/B Tests",
									kpiData.abTesting.activeTests,
									"Current running tests",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Successful Optimizations",
									kpiData.abTesting.successfulOptimizations,
									"Implemented improvements",
									APP_COLOURS.GREEN,
								)}
								{this.renderKPICard(
									"Performance Improvement",
									`${kpiData.abTesting.performanceImprovement}%`,
									"Overall optimization impact",
									APP_COLOURS.PURPLE,
								)}
							</View>
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Influencer Strategy
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Influencer Conversion Rate",
									`${kpiData.influencerMetrics.conversionRate}%`,
									"Direct conversions from influencers",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Awareness Contribution",
									`${kpiData.influencerMetrics.awarenessContribution}%`,
									"Brand awareness from influencers",
									APP_COLOURS.GREEN,
								)}
								{this.renderKPICard(
									"UTM Effectiveness",
									`${kpiData.influencerMetrics.utmEffectiveness}%`,
									"Tracking accuracy score",
									APP_COLOURS.PURPLE,
								)}
								{this.renderKPICard(
									"PR Alignment",
									`${kpiData.influencerMetrics.prAlignment}%`,
									"PR strategy alignment score",
									APP_COLOURS.BLUE,
								)}
							</View>
							{this.renderStrategyNotes("influencerStrategy")}
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Search Performance
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"SEO Score",
									`${kpiData.searchMetrics.seoScore}%`,
									"Overall SEO performance",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"PPC Efficiency",
									`${kpiData.searchMetrics.ppcEfficiency}%`,
									"PPC campaign effectiveness",
									APP_COLOURS.GREEN,
								)}
								{this.renderKPICard(
									"Keyword Ranking",
									kpiData.searchMetrics.keywordRanking,
									"Average position for key terms",
									APP_COLOURS.PURPLE,
								)}
							</View>
							{this.renderStrategyNotes("searchStrategy")}
						</View>

						<View style={{ marginBottom: 30 }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									marginBottom: 10,
									marginLeft: 10,
								}}>
								Omni-Channel Integration
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.renderKPICard(
									"Experience Consistency",
									`${kpiData.omniChannelMetrics.experienceConsistency}%`,
									"Cross-channel consistency score",
									APP_COLOURS.BLUE,
								)}
								{this.renderKPICard(
									"Channel Integration",
									`${kpiData.omniChannelMetrics.channelIntegration}%`,
									"Integration effectiveness",
									APP_COLOURS.GREEN,
								)}
								{this.renderKPICard(
									"Campaign Alignment",
									`${kpiData.omniChannelMetrics.campaignAlignment}%`,
									"Cross-channel campaign sync",
									APP_COLOURS.PURPLE,
								)}
								{this.renderKPICard(
									"Digital-Store Sync",
									`${kpiData.omniChannelMetrics.inStoreDigitalSync}%`,
									"Online-offline integration",
									APP_COLOURS.BLUE,
								)}
							</View>
							{this.renderStrategyNotes("omniChannelIntegration")}
						</View>
					</ScrollView>
				) : null}

				{this.state.selectedView === "yearly" && (
					<YearlyComparison
						selectedBrand={this.state.selectedBrand}
					/>
				)}

				{this.state.selectedView === "channel" && (
					<ChannelPerformance
						selectedBrand={this.state.selectedBrand}
						screenHeight={this.props.screenHeight}
						screenWidth={this.props.screenWidth}
					/>
				)}
			</View>
		);
	}
}
