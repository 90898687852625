import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Animated,
	PanResponder,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import RequestCard from "./RequestCard";

const COLUMN_NAMES = {
	PENDING: "Pending",
	IN_PROGRESS: "In Progress",
	REVIEW: "Under Review",
	ATTENTION_REQUIRED: "Attention Required",
	COMPLETED: "Completed",
};

export default class KanbanBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: {
				[COLUMN_NAMES.PENDING]: [],
				[COLUMN_NAMES.IN_PROGRESS]: [],

				[COLUMN_NAMES.ATTENTION_REQUIRED]: [],
				[COLUMN_NAMES.REVIEW]: [],
				[COLUMN_NAMES.COMPLETED]: [],
			},
		};
	}

	componentDidMount() {
		this.organizeRequests();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.requests !== this.props.requests) {
			this.organizeRequests();
		}
	}

	organizeRequests = () => {
		if (this.props.requests) {
			const organized = this.props.requests.reduce(
				(acc, request) => {
					const status = request.status || COLUMN_NAMES.PENDING;
					acc[status] = [...(acc[status] || []), request];
					return acc;
				},
				{
					[COLUMN_NAMES.PENDING]: [],
					[COLUMN_NAMES.IN_PROGRESS]: [],

					[COLUMN_NAMES.ATTENTION_REQUIRED]: [],
					[COLUMN_NAMES.REVIEW]: [],
					[COLUMN_NAMES.COMPLETED]: [],
				},
			);
			this.setState({ columns: organized });
		}
	};

	renderColumn = (title, requests) => (
		<View style={styles.column} key={title}>
			<View style={styles.columnHeader}>
				<Text style={styles.columnTitle}>{title}</Text>
				<Text style={styles.columnCount}>{requests.length}</Text>
			</View>
			<ScrollView style={styles.columnContent}>
				{requests.map((request) => (
					<RequestCard
						key={request._id}
						request={request}
						onStatusChange={this.props.onStatusChange}
						onEdit={this.props.onEditRequest}
						onAssetUpdate={this.props.onAssetUpdate}
						onRequestDeleted={this.props.onRequestDeleted}
						user_data={this.props.user_data}
					/>
				))}
			</ScrollView>
		</View>
	);

	render() {
		return (
			<ScrollView horizontal style={styles.container}>
				{Object.entries(this.state.columns).map(([status, requests]) =>
					this.renderColumn(status, requests),
				)}
			</ScrollView>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		backgroundColor: "#EFF5FC",
	},
	column: {
		width: 300,
		margin: 10,
		backgroundColor: "#F8F9FA",
		borderRadius: 8,
		padding: 10,
		height: "100%",
		minHeight: 500,
	},
	columnHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 10,
		padding: 10,
		backgroundColor: "#FFF",
		borderRadius: 6,
	},
	columnTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		fontFamily: "Montserrat",
	},
	columnCount: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		backgroundColor: "#E8E8E8",
		paddingHorizontal: 8,
		paddingVertical: 2,
		borderRadius: 12,
	},
	columnContent: {
		flex: 1,
	},
};
