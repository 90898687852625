import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
	Modal,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import QRCode from "react-qr-code";
// import "./resizeScale.scss";
// import "./hoverEffect.scss";
import { APP_COLOURS, Avatars } from "../../APP_VARS";
import StatusBasic from "./StatusBasic";
import GetUser from "../GetUser";

const mainImages = {
	general: require("./ab1.png"),
	tetrice: require("./pres_bg@2x.webp"),
	techfocus: require("./TechFocus.png"),
	socialmedia: require("./ab1.png"),
	vrgames: require("./VrGames.png"),
	softwaredevelopment: require("./presentation1.webp"),
	reporting: require("./reporting@1x.webp"),
	community: require("./community@1x.png"),
	website: require("./software4@1x.png"),
	website2: require("./software.png"),
};

export default class AuditLandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inView: true,
				showObjects: true,
				showPhone: true,
			});
		}, 500);
	}

	render() {
		let contain =
			window.innerWidth < window.innerHeight
				? window.innerWidth * 0.8
				: window.innerHeight * 0.45;

		let maxContain = contain > 500 ? 500 : contain;

		let ar = 636 / 545;

		let h = window.innerHeight;
		let w = window.innerWidth;

		let { h1, h2, pp, h0, mw, presentationData } = this.props;

		return (
			<View
				style={{
					width: "100%",
					backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
					alignItems: "center",
					justifyContent: "center",
					// overflow: "hidden",
				}}>
				<View
					style={{
						flexDirection: "row",
						width: "100%",
						flexWrap: "wrap",
						alignItems: "center",
						// padding: "5%",
						paddingHorizontal: "5%",
						justifyContent: "center",
					}}>
					<View
						style={{
							flex: 1,
							maxWidth: 1300,
							flexDirection: "row",
							flexWrap: "wrap",
							justifyContent: "space-between",
						}}>
						<View
							style={{
								// width: mw,
								flex: 1,
								// minHeight: window.innerHeight * 0.5,
								paddingTop: 100,
								// padding: "5%",
								minWidth: 350,
							}}>
							{/* <View
								style={{
									width: "100%",
									// marginBottom: 100,
									maxWidth: 500,
									alignItems: "center",
									justifyContent: "center",
									// height: window.innerHeight * 0.2,
								}}>
								<img
									src={require("../../../assets/images/tetrice-revlon-main.png")}
									alt='img'
									className='responsive'
								/>
							</View> */}
							<View
								style={{
									// width: window.innerWidth,
									flexDirection: "row",
									flexWrap: "wrap",
									marginBottom: "10%",
									overflow: "hidden",
									justifyContent: "space-between",
									marginTop: 50,
								}}>
								<View
									style={{
										flex: 1,
										maxWidth: 700,
										// opacity: 0.9,
										// marginTop: 50,
									}}>
									<Fade cascade top>
										<Text
											style={{
												// fontSize: h0 + 10,
												fontSize: h0 * 1.2,
												opacity: 1,
												color: "#FFF",
											}}>
											<Font family='Poppins' weight={800}>
												{presentationData?.title}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: h0 * 0.8,
												opacity: 0.5,
												color: "#FFF",
											}}>
											<Font family='Poppins' weight={600}>
												{presentationData?.title2}
											</Font>
										</Text>

										<Text
											style={{
												fontSize: h0 * 0.3,
												color: "#FFF",
											}}>
											<Font family='Poppins' weight={400}>
												{presentationData?.subTitle}
											</Font>
										</Text>
									</Fade>

									<Text
										style={{
											fontSize: pp * 0.8,
											opacity: 0.6,
											color: "#FFF",
											maxWidth: 500,
										}}>
										<Font family='Poppins' weight={300}>
											{presentationData?.description}
										</Font>
									</Text>
									{/* <Text
										style={{
											fontSize: pp,
											opacity: 0.6,
											color: "#FFF",
											maxWidth: 500,
										}}>
										<Font family='Poppins' weight={300}>
											{presentationData?.subHeading}
										</Font>
									</Text> */}
								</View>
							</View>
							<TouchableOpacity
								onPress={() => {
									this.props.editTitle();
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BLUE,
										borderRadius: 15,
										padding: 5,
										marginRight: 10,
										paddingHorizontal: 30,
										marginLeft: 10,
										alignItems: "center",
										justifyContent: "center",
										maxWidth: 200,
									}}>
									<Text
										style={{
											fontSize: 12,
											// color: APP_COLOURS.BLUE,
											color: "#FFF",
											fontWeight: "700",
										}}>
										<Font family='Montserrat' weight={300}>
											Edit title
										</Font>
									</Text>
								</View>
							</TouchableOpacity>
						</View>

						<View
							style={{
								flex: 1,
								// position: "absolute",
								// top: 0,
								// right: 0,
								minWidth: 350,
								// width: "50%",
								alignItems: "flex-end",
								justifyContent: "center",
								// backgroundColor: "red",
								height: window.innerHeight * 0.5,
								maxHeight: window.innerWidth,
							}}>
							<View
								style={{
									width: "100%",
									maxWidth: 1300,
									// borderRadius: 10,
									// borderColor: APP_COLOURS.BLUE,
									// borderWidth: 1,
								}}>
								{typeof presentationData?.mainImage !==
								"undefined" ? (
									<img
										src={
											mainImages[
												presentationData?.mainImage
											]
										}
										alt='Description'
										className='responsive'
									/>
								) : (
									<img
										src={require("./ab1.png")}
										alt='Description'
										className='responsive'
									/>
								)}
							</View>
						</View>
						<View
							style={{
								// flexDirection: "row",
								alignItems: "center",
								padding: 5,
								height: window.innerHeight * 0.5,
								paddingTop: 100,
							}}>
							<ScrollView>
								{[
									{
										title: "tetrice general",
										state: "tetrice",
									},
									{
										title: "General Focus",
										state: "general",
									},
									{
										title: "Tech Focus",
										state: "techfocus",
									},
									{
										title: "VR Games",
										state: "vrgames",
									},
									{
										title: "Social Media",
										state: "community",
									},
									{
										title: "Reporting/Audits",
										state: "reporting",
									},
									{
										title: "Software",
										state: "softwaredevelopment",
									},
									{
										title: "Website",
										state: "website",
									},
									{
										title: "PWA",
										state: "website2",
									},
								].map((d) => (
									<TouchableOpacity
										style={{
											borderRadius: 10,
											borderColor: APP_COLOURS.BLUE,
											borderWidth: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 5,
											backgroundColor:
												APP_COLOURS.BACK_TRANSPARENT2,
										}}
										onPress={() => {
											this.props.updateMainImage(
												presentationData?._id,
												d.state,
											);
										}}>
										<View
											style={{
												height: 100,
												width: 100,
											}}>
											<img
												src={mainImages[d.state]}
												alt='Description'
												className='responsive'
											/>
										</View>

										<View
											style={{
												backgroundColor:
													APP_COLOURS.BLUE,
												borderRadius: 15,
												padding: 6,
												marginRight: 10,
												paddingHorizontal: 30,
												marginLeft: 10,
												width: 150,
												borderRadius: 10,
												paddingHorizontal: 10,
											}}>
											<View
												style={{
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Text
													style={{
														fontSize: 14,
														// color: APP_COLOURS.BLUE,
														color: "#FFF",
														fontWeight: "700",
													}}>
													<Font
														family='Montserrat'
														weight={300}>
														{d.title}
													</Font>
												</Text>
											</View>
										</View>
									</TouchableOpacity>
								))}
							</ScrollView>
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
						flexWrap: "wrap",
						alignItems: "center",
						// justifyContent: "space-between",
						// marginBottom: "10%",
						width: "100%",
						// paddingHorizontal: "5%",
						// maxWidth: 1200,
						paddingHorizontal: "5%",
						justifyContent: "center",
					}}>
					{/* <View
						style={{
							width: window.innerWidth - 1200,
							maxWidth: 350,
						}}
					/> */}
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							// justifyContent: "space-between",
							flexWrap: "wrap",
							// maxWidth: 1300,
						}}>
						<View
							style={{
								width: 200,
								// flex: 1,
								marginBottom: 20,
								marginRight: "10%",
							}}>
							<img
								src={require("../../../assets/images/services.png")}
								alt='img'
								className='responsive'
							/>
						</View>
						<View
							style={{
								width: 200,
								marginBottom: 20,
							}}>
							<img
								src={require("../../../assets/images/infod.png")}
								alt='img'
								className='responsive'
							/>
						</View>
					</View>

					<StatusBasic
						id={presentationData?._id}
						status={presentationData?.status}
						fs={14}
						handleStatusChange={async (_id, _status) => {
							this.props.setStatus(_id, _status);
						}}
					/>

					<TouchableOpacity
						onPress={() => {
							this.props.sharePresentation();
						}}>
						<View
							style={{
								backgroundColor: APP_COLOURS.BLUE,
								borderRadius: 15,
								height: 40,
								marginRight: 10,
								paddingHorizontal: 30,
								marginLeft: 10,
							}}>
							<View
								style={{
									width: 80,
									height: 40,
									alignItems: "center",
									justifyContent: "center",
									borderRadius: 13,
									// backgroundColor: APP_COLOURS.,
									paddingHorizontal: 10,
								}}>
								<Text
									style={{
										fontSize: 14,
										// color: APP_COLOURS.BLUE,
										color: "#FFF",
										fontWeight: "700",
									}}>
									<Font family='Montserrat' weight={300}>
										Share
									</Font>
								</Text>
							</View>
						</View>
					</TouchableOpacity>
				</View>

				<View
					style={{
						// flex: 1,
						width: "100%",
						marginTop: 20,
						backgroundColor: APP_COLOURS.BG2,
						justifyContent: "center",
						alignItems: "center",
						paddingVertical: 20,
						paddingHorizontal: "5%",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							alignItems: "center",
							flexWrap: "wrap",
							width: "100%",
							// paddingHorizontal: "5%",
							paddingVertical: "2%",
							justifyContent: "center",
						}}>
						{/* <View
							style={{
								width: window.innerWidth - 1200,
								maxWidth: 350,
							}}
						/> */}
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								flexWrap: "wrap",
								maxWidth: 1300,
							}}>
							<TouchableOpacity
								onPress={(event) => {
									const layout = event.nativeEvent;
									let x = layout.pageX;
									let y = layout.pageY;
									console.log("assignment");
									//
								}}
								style={{}}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										alignItems: "center",
										minWidth: 200,
										paddingVertical: "1%",
									}}>
									<View
										style={{
											width: 36,
											height: 36,
											borderRadius: 20,
											resizeMode: "contain",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: "#FFF",
											borderWidth: 2,
											borderColor: "#FFF",
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 8,
											},
											shadowOpacity: 0.44,
											shadowRadius: 10.32,
											elevation: 16,
											marginRight: 10,
										}}>
										{typeof this.props.createdBy !==
										"undefined" ? (
											<Image
												source={
													Avatars[
														parseInt(
															this.props.createdBy
																?.avatar,
														)
													].pp
												}
												style={{
													width: 35,
													height: 35,
													borderRadius: 20,
													resizeMode: "contain",
												}}
											/>
										) : null}
									</View>
									<View>
										<Text
											style={{
												fontSize: 10,
												color: "#FFF",
												top: 6,
											}}>
											<Font family='Poppins' weight={400}>
												Created by:
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 16,
												color: "#FFF",
												marginBottom: 5,
											}}>
											<Font family='Poppins' weight={600}>
												{typeof presentationData?.createdBy !==
												"undefined"
													? presentationData
															?.createdBy.fullName
													: "..."}
											</Font>
										</Text>
									</View>
								</View>
								<View style={{}}>
									{presentationData.assignment_ids.length <
									1 ? (
										<View
											style={{
												//  flex: 1,
												//  backgroundColor: '#252525',
												justifyContent: "center",
												alignItems: "center",
												opacity: 0.4,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: pp,
												}}>
												+ Assignment
											</Text>
										</View>
									) : (
										<View
											style={{
												flexDirection: "row",
												// alignItems: "center",
												width:
													presentationData
														.assignment_ids.length >
													5
														? 4 * (pp / 2)
														: presentationData
																.assignment_ids
																.length *
														  (pp / 2),
												position: "absolute",
												left:
													presentationData
														.assignment_ids.length *
													(pp / 2),
											}}>
											{presentationData.assignment_ids
												.slice(0, 5)
												.map((ass, i) => {
													return (
														<GetUser
															users={
																this.props.users
															}
															user_id={ass}
															size={pp}
														/>
													);
												})}
										</View>
									)}
								</View>
							</TouchableOpacity>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									alignItems: "center",
									width: 200,
									paddingVertical: "1%",
									justifyContent: "space-evenly",
								}}>
								{/* <View
									style={{
										width: 40,
									}}>
									<img
										src={require("../assets/services.png")}
										alt='img'
										className='responsive'
									/>
								</View> */}

								<View>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											top: 6,
										}}>
										<Font family='Poppins' weight={400}>
											Date:
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											marginBottom: 5,
										}}>
										<Font family='Poppins' weight={300}>
											{moment(
												presentationData?._createTime,
											).format("DD/MM/YYYY")}
										</Font>
									</Text>
								</View>
								<View>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											top: 6,
										}}>
										<Font family='Poppins' weight={400}>
											Valid until:
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											marginBottom: 5,
										}}>
										<Font family='Poppins' weight={300}>
											{moment(
												presentationData?.end_date,
											).format("DD/MM/YYYY")}
										</Font>
									</Text>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									alignItems: "center",
									minWidth: 200,
									paddingVertical: "1%",
								}}>
								{/* <View
									style={{
										width: 40,
									}}>
									<img
										src={require("../assets/services.png")}
										alt='img'
										className='responsive'
									/>
								</View> */}
								<View>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											top: 6,
										}}>
										<Font family='Poppins' weight={400}>
											Document number:
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											marginBottom: 5,
										}}>
										<Font family='Poppins' weight={300}>
											{presentationData?._id}
										</Font>
									</Text>
								</View>
							</View>

							{this.state.preview ? (
								<TouchableOpacity
									onPress={() => {
										// window.open(
										// 	`http://localhost:3001/presentation/${presentationData?._id}/${this.props.user_data.accessToken}`,
										// 	"blank",
										// );

										window.open(
											`http://presentations.tetrice.co.za/presentation/${presentationData?._id}/${this.props.user_data.accessToken}`,
										);
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											marginTop: 10,
										}}>
										<View
											style={{
												backgroundColor: "#FFF",
												borderRadius: 5,
												// height: 100,
												// width: 100,
												padding: 5,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<QRCode
												size={200}
												value={`http://presentations.tetrice.co.za/presentation/${presentationData?._id}/${this.props.user_data.accessToken}`}
												bgColor={"#FFF"}
												fgColor={APP_COLOURS.BG2}
											/>
										</View>
										<Text
											allowFontScaling={false}
											style={{
												fontSize: 10,
												fontFamily: "Avenir Next",
												textAlign: "center",
												fontWeight: "700",
												color: APP_COLOURS.TEXTCOLOR,
												marginTop: 10,
											}}>
											PREVIEW
										</Text>
									</View>
								</TouchableOpacity>
							) : (
								<TouchableOpacity
									onPress={() => {
										this.setState({
											preview: true,
										});
									}}>
									<View
										style={{
											backgroundColor: APP_COLOURS.BLUE,
											borderRadius: 10,
											padding: 5,
											paddingHorizontal: 30,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Text
											style={{
												fontSize: 14,
												// color: APP_COLOURS.BLUE,
												color: "#FFF",
												fontWeight: "700",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												Preview
											</Font>
										</Text>
									</View>
								</TouchableOpacity>
							)}
						</View>
					</View>
				</View>
			</View>
		);
	}
}
