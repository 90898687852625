import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TextInput,
	TouchableOpacity,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import { crudUpdate } from "../../CRUDHELPER";

class RetailerContacts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			retailers: [
				{
					id: "1",
					name: "Dis-Chem",
					spendContribution: 25,
					brandManager: {
						name: "Sarah Johnson",
						email: "sarah.j@brand.com",
					},
					retailerManager: {
						name: "Mike Peters",
						email: "mike.p@dischem.co.za",
					},
					notes: "Monthly meetings every first Tuesday. Prefers email communication for urgent matters.",
					editingNotes: false,
				},
				{
					id: "2",
					name: "Clicks",
					spendContribution: 20,
					brandManager: {
						name: "David Smith",
						email: "david.s@brand.com",
					},
					retailerManager: {
						name: "Lisa Brown",
						email: "l.brown@clicks.co.za",
					},
					notes: "Quarterly business reviews. Strong focus on digital integration.",
					editingNotes: false,
				},
				{
					id: "3",
					name: "Retailibility/Edgars",
					spendContribution: 15,
					brandManager: {
						name: "Emma Wilson",
						email: "emma.w@brand.com",
					},
					retailerManager: {
						name: "John Davis",
						email: "j.davis@edgars.co.za",
					},
					notes: "New digital initiative starting Q3. Weekly sync calls on Thursdays.",
					editingNotes: false,
				},
				{
					id: "4",
					name: "Woolworths",
					spendContribution: 10,
					brandManager: {
						name: "Alex Thompson",
						email: "alex.t@brand.com",
					},
					retailerManager: {
						name: "Rachel Green",
						email: "r.green@woolworths.co.za",
					},
					notes: "Premium positioning focus. Monthly performance reviews.",
					editingNotes: false,
				},
				{
					id: "5",
					name: "Foschini/Bash",
					spendContribution: 5,
					brandManager: {
						name: "James Wilson",
						email: "james.w@brand.com",
					},
					retailerManager: {
						name: "Sarah Miller",
						email: "s.miller@tfg.co.za",
					},
					notes: "Digital transformation project ongoing. Bi-weekly alignment meetings.",
					editingNotes: false,
				},
				{
					id: "6",
					name: "Truworths",
					spendContribution: 5,
					brandManager: {
						name: "Michael Brown",
						email: "michael.b@brand.com",
					},
					retailerManager: {
						name: "Emily Clark",
						email: "e.clark@truworths.co.za",
					},
					notes: "Focus on omnichannel integration. Monthly strategy reviews.",
					editingNotes: false,
				},
				{
					id: "7",
					name: "Alpha Pharm",
					spendContribution: 5,
					brandManager: {
						name: "Sophie Turner",
						email: "sophie.t@brand.com",
					},
					retailerManager: {
						name: "Peter Jones",
						email: "p.jones@alphapharm.co.za",
					},
					notes: "Regional expansion plans in progress. Quarterly performance meetings.",
					editingNotes: false,
				},
				{
					id: "8",
					name: "Takealot",
					spendContribution: 5,
					brandManager: {
						name: "Chris Anderson",
						email: "chris.a@brand.com",
					},
					retailerManager: {
						name: "Michelle Lee",
						email: "m.lee@takealot.com",
					},
					notes: "E-commerce focused strategy. Weekly performance tracking.",
					editingNotes: false,
				},
				{
					id: "9",
					name: "Superbalist",
					spendContribution: 5,
					brandManager: {
						name: "Ryan Cooper",
						email: "ryan.c@brand.com",
					},
					retailerManager: {
						name: "Jessica White",
						email: "j.white@superbalist.com",
					},
					notes: "Digital-first approach. Strong focus on influencer collaborations.",
					editingNotes: false,
				},
				{
					id: "10",
					name: "Mopani",
					spendContribution: 5,
					brandManager: {
						name: "Daniel Harris",
						email: "daniel.h@brand.com",
					},
					retailerManager: {
						name: "Linda Thompson",
						email: "l.thompson@mopani.co.za",
					},
					notes: "Regional market development. Monthly strategy alignment.",
					editingNotes: false,
				},
				{
					id: "11",
					name: "Ascot",
					spendContribution: 5,
					brandManager: {
						name: "Patricia Moore",
						email: "patricia.m@brand.com",
					},
					retailerManager: {
						name: "Robert King",
						email: "r.king@ascot.co.za",
					},
					notes: "Luxury market positioning. Bi-monthly performance reviews.",
					editingNotes: false,
				},
			],
			saving: false,
			editMode: {},
		};
	}

	updateField = (id, field, subfield, value) => {
		this.setState((prevState) => ({
			retailers: prevState.retailers.map((retailer) => {
				if (retailer.id === id) {
					if (subfield) {
						return {
							...retailer,
							[field]: {
								...retailer[field],
								[subfield]: value,
							},
						};
					}
					return {
						...retailer,
						[field]: value,
					};
				}
				return retailer;
			}),
		}));
	};

	toggleEdit = (id, section) => {
		this.setState((prevState) => ({
			editMode: {
				...prevState.editMode,
				[`${id}_${section}`]: !prevState.editMode[`${id}_${section}`],
			},
		}));
	};

	saveRetailerDetails = async () => {
		this.setState({ saving: true });
		try {
			await crudUpdate(
				"retailerContacts",
				{ retailers: this.state.retailers },
				(status) => {
					if (status === 200) {
						// Clear all edit modes
						this.setState({
							editMode: {},
							saving: false,
						});
					} else {
						console.error("Failed to save retailer details");
						this.setState({ saving: false });
					}
				},
			);
		} catch (error) {
			console.error("Error saving retailer details:", error);
			this.setState({ saving: false });
		}
	};

	validateSpendContribution = (currentId, newValue) => {
		const totalOthers = this.state.retailers.reduce((sum, retailer) => {
			if (retailer.id !== currentId) {
				return sum + (retailer.spendContribution || 0);
			}
			return sum;
		}, 0);

		return totalOthers + parseFloat(newValue) <= 100;
	};

	render() {
		return (
			<ScrollView
				style={{ flex: 1, backgroundColor: "#EFF5FC", padding: 20 }}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: 20,
					}}>
					<Text
						style={{
							fontSize: 24,
							color: APP_COLOURS.BG2,
							fontWeight: "700",
						}}>
						Retailer Contacts
					</Text>
					<TouchableOpacity
						style={{
							backgroundColor: APP_COLOURS.BLUE,
							padding: 10,
							borderRadius: 8,
							flexDirection: "row",
							alignItems: "center",
						}}
						onPress={this.saveRetailerDetails}
						disabled={this.state.saving}>
						{this.state.saving ? (
							<ActivityIndicator
								color='#FFF'
								size='small'
								style={{ marginRight: 5 }}
							/>
						) : null}
						<Text style={{ color: "#FFF", fontWeight: "600" }}>
							{this.state.saving
								? "Saving..."
								: "Save All Changes"}
						</Text>
					</TouchableOpacity>
				</View>

				{this.state.retailers.map((retailer) => (
					<View
						key={retailer.id}
						style={{
							backgroundColor: "#FFF",
							borderRadius: 10,
							padding: 20,
							marginBottom: 20,
							shadowColor: "#000",
							shadowOffset: { width: 0, height: 2 },
							shadowOpacity: 0.1,
							shadowRadius: 4,
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								marginBottom: 15,
							}}>
							<Text
								style={{
									fontSize: 20,
									color: APP_COLOURS.BG2,
									fontWeight: "700",
								}}>
								{retailer.name}
							</Text>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								{this.state.editMode[`${retailer.id}_spend`] ? (
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<TextInput
											style={{
												borderWidth: 1,
												borderColor: APP_COLOURS.BG5,
												borderRadius: 5,
												padding: 8,
												width: 60,
												marginRight: 5,
												textAlign: "right",
											}}
											value={retailer.spendContribution.toString()}
											keyboardType='numeric'
											onChangeText={(text) => {
												const num =
													parseFloat(text) || 0;
												if (
													this.validateSpendContribution(
														retailer.id,
														num,
													)
												) {
													this.updateField(
														retailer.id,
														"spendContribution",
														null,
														num,
													);
												}
											}}
										/>
										<Text style={{ marginRight: 10 }}>
											%
										</Text>
										<TouchableOpacity
											onPress={() =>
												this.toggleEdit(
													retailer.id,
													"spend",
												)
											}>
											<Text
												style={{
													color: APP_COLOURS.BLUE,
												}}>
												Save
											</Text>
										</TouchableOpacity>
									</View>
								) : (
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<View
											style={{
												backgroundColor:
													APP_COLOURS.BLUE,
												borderRadius: 20,
												paddingVertical: 8,
												paddingHorizontal: 15,
												marginRight: 10,
											}}>
											<Text
												style={{
													color: "#FFF",
													fontWeight: "700",
													fontSize: 16,
												}}>
												{retailer.spendContribution}%
											</Text>
										</View>
										<TouchableOpacity
											onPress={() =>
												this.toggleEdit(
													retailer.id,
													"spend",
												)
											}>
											<Text
												style={{
													color: APP_COLOURS.BLUE,
												}}>
												Edit
											</Text>
										</TouchableOpacity>
									</View>
								)}
							</View>
						</View>

						<View style={{ marginBottom: 15 }}>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									marginBottom: 5,
								}}>
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "600",
									}}>
									Brand Key Account Manager
								</Text>
								<TouchableOpacity
									onPress={() =>
										this.toggleEdit(
											retailer.id,
											"brandManager",
										)
									}>
									<Text style={{ color: APP_COLOURS.BLUE }}>
										{this.state.editMode[
											`${retailer.id}_brandManager`
										]
											? "Save"
											: "Edit"}
									</Text>
								</TouchableOpacity>
							</View>
							{this.state.editMode[
								`${retailer.id}_brandManager`
							] ? (
								<View>
									<TextInput
										style={styles.input}
										value={retailer.brandManager.name}
										onChangeText={(text) =>
											this.updateField(
												retailer.id,
												"brandManager",
												"name",
												text,
											)
										}
										placeholder='Name'
									/>
									<TextInput
										style={styles.input}
										value={retailer.brandManager.email}
										onChangeText={(text) =>
											this.updateField(
												retailer.id,
												"brandManager",
												"email",
												text,
											)
										}
										placeholder='Email'
										keyboardType='email-address'
									/>
								</View>
							) : (
								<View>
									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.BG3,
										}}>
										{retailer.brandManager.name}
									</Text>
									<TouchableOpacity
										onPress={() =>
											(window.location.href = `mailto:${retailer.brandManager.email}`)
										}>
										<Text
											style={{
												fontSize: 15,
												color: APP_COLOURS.BLUE,
											}}>
											{retailer.brandManager.email}
										</Text>
									</TouchableOpacity>
								</View>
							)}
						</View>

						<View style={{ marginBottom: 15 }}>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									marginBottom: 5,
								}}>
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "600",
									}}>
									Retailer Key Account Manager
								</Text>
								<TouchableOpacity
									onPress={() =>
										this.toggleEdit(
											retailer.id,
											"retailerManager",
										)
									}>
									<Text style={{ color: APP_COLOURS.BLUE }}>
										{this.state.editMode[
											`${retailer.id}_retailerManager`
										]
											? "Save"
											: "Edit"}
									</Text>
								</TouchableOpacity>
							</View>
							{this.state.editMode[
								`${retailer.id}_retailerManager`
							] ? (
								<View>
									<TextInput
										style={styles.input}
										value={retailer.retailerManager.name}
										onChangeText={(text) =>
											this.updateField(
												retailer.id,
												"retailerManager",
												"name",
												text,
											)
										}
										placeholder='Name'
									/>
									<TextInput
										style={styles.input}
										value={retailer.retailerManager.email}
										onChangeText={(text) =>
											this.updateField(
												retailer.id,
												"retailerManager",
												"email",
												text,
											)
										}
										placeholder='Email'
										keyboardType='email-address'
									/>
								</View>
							) : (
								<View>
									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.BG3,
										}}>
										{retailer.retailerManager.name}
									</Text>
									<TouchableOpacity
										onPress={() =>
											(window.location.href = `mailto:${retailer.retailerManager.email}`)
										}>
										<Text
											style={{
												fontSize: 15,
												color: APP_COLOURS.BLUE,
											}}>
											{retailer.retailerManager.email}
										</Text>
									</TouchableOpacity>
								</View>
							)}
						</View>

						<View>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									marginBottom: 5,
								}}>
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "600",
									}}>
									Notes
								</Text>
								<TouchableOpacity
									onPress={() =>
										this.toggleEdit(retailer.id, "notes")
									}>
									<Text style={{ color: APP_COLOURS.BLUE }}>
										{this.state.editMode[
											`${retailer.id}_notes`
										]
											? "Save"
											: "Edit"}
									</Text>
								</TouchableOpacity>
							</View>
							{this.state.editMode[`${retailer.id}_notes`] ? (
								<TextInput
									multiline
									value={retailer.notes}
									onChangeText={(text) =>
										this.updateField(
											retailer.id,
											"notes",
											null,
											text,
										)
									}
									style={styles.notesInput}
								/>
							) : (
								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.BG3,
									}}>
									{retailer.notes}
								</Text>
							)}
						</View>
					</View>
				))}

				<View
					style={{
						backgroundColor: APP_COLOURS.BG2,
						padding: 10,
						borderRadius: 8,
						marginBottom: 20,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<Text style={{ color: "#FFF", fontWeight: "600" }}>
						Total Spend Contribution
					</Text>
					<Text
						style={{
							color: "#FFF",
							fontWeight: "700",
							fontSize: 18,
						}}>
						{this.state.retailers.reduce(
							(sum, retailer) =>
								sum + (retailer.spendContribution || 0),
							0,
						)}
						%
					</Text>
				</View>
			</ScrollView>
		);
	}
}

const styles = {
	input: {
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
		borderRadius: 5,
		padding: 8,
		marginBottom: 8,
		fontSize: 14,
		color: APP_COLOURS.BG3,
	},
	notesInput: {
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
		borderRadius: 5,
		padding: 10,
		minHeight: 80,
		textAlignVertical: "top",
		fontSize: 14,
		color: APP_COLOURS.BG3,
	},
};

export default RetailerContacts;
