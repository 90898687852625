import React, { Component } from "react";
import { View, Text, TouchableOpacity, Modal, TextInput } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class AddBudgetEntry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			entry: {
				brand: props.selectedBrand?.title || "",
				franchise: "",
				allocation: "",
				pl_allocation: "",
				spend: {
					jan: 0,
					feb: 0,
					mar: 0,
					apr: 0,
					may: 0,
					jun: 0,
					jul: 0,
					aug: 0,
					sep: 0,
					oct: 0,
					nov: 0,
					dec: 0,
				},
			},
			focusedMonth: null,
		};
	}

	handleSpendChange = (month, value) => {
		const numValue = parseFloat(value) || 0;
		this.setState((prevState) => ({
			entry: {
				...prevState.entry,
				spend: {
					...prevState.entry.spend,
					[month]: numValue,
				},
			},
		}));
	};

	handleFocus = (month) => {
		this.setState({ focusedMonth: month });
	};

	handleBlur = () => {
		this.setState({ focusedMonth: null });
	};

	handleSubmit = () => {
		this.props.onSubmit(this.state.entry);
	};

	render() {
		const months = [
			"jan",
			"feb",
			"mar",
			"apr",
			"may",
			"jun",
			"jul",
			"aug",
			"sep",
			"oct",
			"nov",
			"dec",
		];
		const { entry, focusedMonth } = this.state;

		return (
			<Modal visible={true} transparent={true} animationType='slide'>
				<View style={styles.modalOverlay}>
					<View style={styles.modalContent}>
						<Text style={styles.modalTitle}>Add Budget Entry</Text>

						<View style={styles.formGroup}>
							<Text style={styles.label}>Brand</Text>
							<Text style={styles.value}>{entry.brand}</Text>
						</View>

						<View style={styles.formGroup}>
							<Text style={styles.label}>Franchise</Text>
							<TextInput
								style={styles.input}
								value={entry.franchise}
								onChangeText={(value) =>
									this.setState((prevState) => ({
										entry: {
											...prevState.entry,
											franchise: value,
										},
									}))
								}
								placeholder='Enter franchise name'
							/>
						</View>

						<View style={styles.formGroup}>
							<Text style={styles.label}>Allocation</Text>
							<TextInput
								style={styles.input}
								value={entry.allocation}
								onChangeText={(value) =>
									this.setState((prevState) => ({
										entry: {
											...prevState.entry,
											allocation: value,
										},
									}))
								}
								placeholder='Enter allocation'
							/>
						</View>

						<View style={styles.formGroup}>
							<Text style={styles.label}>P&L Allocation</Text>
							<TextInput
								style={styles.input}
								value={entry.pl_allocation}
								onChangeText={(value) =>
									this.setState((prevState) => ({
										entry: {
											...prevState.entry,
											pl_allocation: value,
										},
									}))
								}
								placeholder='Enter P&L allocation'
							/>
						</View>

						<Text style={styles.sectionTitle}>Monthly Spend</Text>
						<View style={styles.monthsContainer}>
							{months.map((month) => (
								<View key={month} style={styles.monthInput}>
									<Text style={styles.monthLabel}>
										{month.toUpperCase()}
									</Text>
									<TextInput
										style={[
											styles.monthInputField,
											focusedMonth === month &&
												styles.monthInputFieldFocused,
										]}
										value={
											focusedMonth === month
												? ""
												: entry.spend[
														month
												  ]?.toString() || "0"
										}
										onChangeText={(value) =>
											this.handleSpendChange(month, value)
										}
										onFocus={() => this.handleFocus(month)}
										onBlur={this.handleBlur}
										keyboardType='numeric'
										placeholder='0.00'
										textAlign='right'
									/>
								</View>
							))}
						</View>

						<View style={styles.buttonContainer}>
							<TouchableOpacity
								style={styles.saveButton}
								onPress={this.handleSubmit}>
								<Text style={styles.saveButtonText}>
									Add Entry
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={styles.cancelButton}
								onPress={this.props.close}>
								<Text style={styles.cancelButtonText}>
									Cancel
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}

const styles = {
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 25,
		width: "90%",
		maxWidth: 800,
		maxHeight: "90%",
		overflow: "auto",
	},
	modalTitle: {
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 25,
		color: APP_COLOURS.BG2,
		textAlign: "center",
	},
	formGroup: {
		marginBottom: 20,
	},
	label: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 8,
		fontWeight: "600",
	},
	value: {
		fontSize: 14,
		color: "#666",
		padding: 12,
		backgroundColor: "#f5f5f5",
		borderRadius: 5,
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 5,
		padding: 12,
		fontSize: 14,
		color: APP_COLOURS.BG2,
		backgroundColor: "#fff",
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginTop: 25,
		marginBottom: 20,
	},
	monthsContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-between",
		gap: 15,
		backgroundColor: "#f8f9fa",
		padding: 15,
		borderRadius: 8,
	},
	monthInput: {
		width: "calc(25% - 12px)",
		marginBottom: 15,
	},
	monthLabel: {
		fontSize: 12,
		color: APP_COLOURS.BG2,
		marginBottom: 6,
		fontWeight: "500",
	},
	monthInputField: {
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 5,
		padding: 10,
		fontSize: 14,
		color: APP_COLOURS.BG2,
		backgroundColor: "#fff",
		textAlign: "right",
	},
	monthInputFieldFocused: {
		borderColor: APP_COLOURS.PURPLE,
		backgroundColor: "#fff",
		shadowColor: APP_COLOURS.PURPLE,
		shadowOffset: { width: 0, height: 0 },
		shadowOpacity: 0.2,
		shadowRadius: 3,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "center",
		gap: 15,
		marginTop: 25,
	},
	saveButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 15,
		borderRadius: 5,
		alignItems: "center",
		flex: 1,
	},
	saveButtonText: {
		color: "white",
		fontSize: 16,
		fontWeight: "600",
	},
	cancelButton: {
		padding: 15,
		borderRadius: 5,
		alignItems: "center",
		borderWidth: 1,
		borderColor: APP_COLOURS.RED,
		flex: 1,
	},
	cancelButtonText: {
		color: APP_COLOURS.RED,
		fontSize: 16,
		fontWeight: "600",
	},
};
