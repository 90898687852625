export const CREATIVE_ASSET_TYPES = {
	INSTAGRAM: {
		category: "Instagram",
		assets: [
			{
				type: "Instagram Feed Post",
				size: "1:1 (1080x1080px)",
				description: "Square format for Instagram feed",
				specs: "JPG/PNG, max 30MB",
			},
			{
				type: "Instagram Portrait Post",
				size: "4:5 (1080x1350px)",
				description: "Vertical format for Instagram feed",
				specs: "JPG/PNG, max 30MB",
			},
			{
				type: "Instagram Story",
				size: "9:16 (1080x1920px)",
				description: "Full screen vertical format",
				specs: "JPG/PNG, max 30MB",
			},
			{
				type: "Instagram Carousel",
				size: "1:1 (1080x1080px)",
				description: "Multiple square images (up to 10)",
				specs: "JPG/PNG, max 30MB per image",
			},
			{
				type: "Instagram Reels",
				size: "9:16 (1080x1920px)",
				description: "Vertical video format",
				specs: "MP4, max 15 minutes",
			},
		],
	},

	FACEBOOK: {
		category: "Facebook",
		assets: [
			{
				type: "Facebook Feed Post",
				size: "1.91:1 (1200x630px)",
				description: "Landscape format for feed",
				specs: "JPG/PNG, max 30MB",
			},
			{
				type: "Facebook Square Post",
				size: "1:1 (1200x1200px)",
				description: "Square format for feed",
				specs: "JPG/PNG, max 30MB",
			},
			{
				type: "Facebook Story",
				size: "9:16 (1080x1920px)",
				description: "Full screen vertical format",
				specs: "JPG/PNG, max 30MB",
			},
			{
				type: "Facebook Link Image",
				size: "1.91:1 (1200x630px)",
				description: "Image for link previews",
				specs: "JPG/PNG, min 200x200px",
			},
			{
				type: "Facebook Cover Photo",
				size: "16:9 (1640x924px)",
				description: "Page cover image",
				specs: "JPG/PNG, min 400x150px",
			},
		],
	},

	LINKEDIN: {
		category: "LinkedIn",
		assets: [
			{
				type: "LinkedIn Feed Post",
				size: "1.91:1 (1200x630px)",
				description: "Standard feed post",
				specs: "JPG/PNG, max 5MB",
			},
			{
				type: "LinkedIn Company Banner",
				size: "6:1 (1536x256px)",
				description: "Company page banner",
				specs: "JPG/PNG, max 4MB",
			},
			{
				type: "LinkedIn Square Post",
				size: "1:1 (1200x1200px)",
				description: "Square format for feed",
				specs: "JPG/PNG, max 5MB",
			},
		],
	},

	TWITTER: {
		category: "Twitter",
		assets: [
			{
				type: "Twitter Post",
				size: "16:9 (1200x675px)",
				description: "Standard tweet image",
				specs: "JPG/PNG/GIF, max 5MB",
			},
			{
				type: "Twitter Header",
				size: "3:1 (1500x500px)",
				description: "Profile header image",
				specs: "JPG/PNG, max 2MB",
			},
		],
	},

	TIKTOK: {
		category: "TikTok",
		assets: [
			{
				type: "TikTok Video",
				size: "9:16 (1080x1920px)",
				description: "Standard TikTok video",
				specs: "MP4/MOV, 3 seconds to 3 minutes",
			},
			{
				type: "TikTok Profile Photo",
				size: "1:1 (200x200px)",
				description: "Profile picture",
				specs: "JPG/PNG, max 1MB",
			},
		],
	},

	YOUTUBE: {
		category: "YouTube",
		assets: [
			{
				type: "YouTube Thumbnail",
				size: "16:9 (1280x720px)",
				description: "Video thumbnail",
				specs: "JPG/PNG/GIF, max 2MB",
			},
			{
				type: "YouTube Banner",
				size: "16:9 (2560x1440px)",
				description: "Channel banner",
				specs: "JPG/PNG, max 6MB",
			},
			{
				type: "YouTube Video",
				size: "16:9 (1920x1080px)",
				description: "Standard video format",
				specs: "MP4, recommended 1080p",
			},
		],
	},

	PINTEREST: {
		category: "Pinterest",
		assets: [
			{
				type: "Pinterest Pin",
				size: "2:3 (1000x1500px)",
				description: "Standard pin size",
				specs: "JPG/PNG, max 32MB",
			},
			{
				type: "Pinterest Square",
				size: "1:1 (1000x1000px)",
				description: "Square pin format",
				specs: "JPG/PNG, max 32MB",
			},
		],
	},

	EMAIL: {
		category: "Email Marketing",
		assets: [
			{
				type: "Email Header",
				size: "600x200px",
				description: "Email header image",
				specs: "JPG/PNG, max 1MB",
			},
			{
				type: "Email Banner",
				size: "600x300px",
				description: "Email promotional banner",
				specs: "JPG/PNG, max 1MB",
			},
		],
	},

	WEBSITE: {
		category: "Website",
		assets: [
			{
				type: "Website Banner",
				size: "1920x600px",
				description: "Homepage hero banner",
				specs: "JPG/PNG, optimized for web",
			},
			{
				type: "Product Image",
				size: "1000x1000px",
				description: "Product display image",
				specs: "JPG/PNG, white background",
			},
		],
	},

	DISPLAY_ADS: {
		category: "Display Ads",
		assets: [
			{
				type: "Leaderboard",
				size: "728x90px",
				description: "Horizontal banner ad",
				specs: "JPG/PNG/GIF, max 150KB",
			},
			{
				type: "Medium Rectangle",
				size: "300x250px",
				description: "Rectangle banner ad",
				specs: "JPG/PNG/GIF, max 150KB",
			},
			{
				type: "Skyscraper",
				size: "160x600px",
				description: "Vertical banner ad",
				specs: "JPG/PNG/GIF, max 150KB",
			},
		],
	},
};

export const getAssetTypesByCategory = (category) => {
	return CREATIVE_ASSET_TYPES[category]?.assets || [];
};

export const getAllAssetTypes = () => {
	return Object.values(CREATIVE_ASSET_TYPES).reduce((acc, category) => {
		return [...acc, ...category.assets];
	}, []);
};
