import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	Modal,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
import SignaturePad from "./SignaturePade";
import AutoHeightImage from "../../AutoHeightImageComp";
import moment from "moment";
import Font from "react-font";
import AddCostModal from "./AddCostModal";

export default class CostEstimate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSignaturePad: false,
			base64Signature: "",
			includeCostEstimate: false,
			costEstimateModal: false,

			// additionalItems: [
			// 	{
			// 		title: "Standard Support SLA",
			// 		desc: "Responding to direct and on-page queries at a dedicated timeframe (to be discussed) for 16 hours of the day, with an average response time of 1 hour.",
			// 	},

			// 	{
			// 		title: "Website analytics report",
			// 		desc: "Conduting of a quarterly website analytical report.",
			// 	},

			// 	{
			// 		title: "Performance Analysis & Optimization",
			// 		desc: "Regularly analyzing websiteb performance data to identify trends and opportunities for improvement, optimizing content and engagement strategies for better results.",
			// 	},
			// ],
			additionalItems: [],
			annuallyRecurring: [],
			monthlyRecurring: [],
			onceOffCosts: [],
			usageBased: [],

			// _id: "5BX3pIdtkVMMirj53w5D",
			// additionalItems: [],
			// annuallyRecurring: [
			// 	{
			// 		title: "Security & encryption certificates",
			// 		desc: "",
			// 		totalExcl: "2499",
			// 	},
			// 	{
			// 		title: "Domain renewal",
			// 		desc: "Cost to be determined",
			// 		totalExcl: "",
			// 	},
			// ],
			// monthlyRecurring: [
			// 	{
			// 		title: "Cloud hosting",
			// 		desc: "domain & website hosting",
			// 		totalExcl: "899",
			// 	},
			// 	{
			// 		title: "Email hosting & Security",
			// 		desc: "",
			// 		totalExcl: "480",
			// 	},
			// 	{
			// 		title: "Business email accounts",
			// 		desc: "5 Users",
			// 		totalExcl: "740",
			// 	},
			// 	{
			// 		title: "Domain and website protection plan",
			// 		desc: "",
			// 		totalExcl: "399",
			// 	},
			// 	{
			// 		title: "Cloudflare",
			// 		desc: "DDoS & firewall",
			// 		totalExcl: "399",
			// 	},
			// ],
			// onceOffCosts: [],
			// usageBased: [
			// 	{
			// 		title: "Business email account",
			// 		desc: "Per user",
			// 		totalExcl: "149",
			// 	},
			// ],
		};
	}

	componentDidMount() {
		this.setState({
			...this.props,
		});

		console.log("console.log(this.props);", this.props);
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({
				...this.props,
			});
		}
	}

	render() {
		let w = 900;
		let { title, title2, end_date } = this.props;
		let {
			additionalItems,
			onceOffCosts,
			usageBased,
			monthlyRecurring,
			annuallyRecurring,
		} = this.state;
		return (
			<View>
				<View
					style={{
						width: "100%",
						justifyContent: "flex-end",
						alignItems: "flex-end",
					}}>
					<TouchableOpacity
						onPress={async () => {
							// this.setState({
							// 	includeCostEstimate:
							// 		!this.state.includeCostEstimate,
							// });

							this.props.updateCostEstimate({
								includeCostEstimate:
									!this.state.includeCostEstimate,
							});
						}}
						style={{
							backgroundColor: APP_COLOURS.PURPLE,
							justifyContent: "center",
							alignItems: "center",
							borderRadius: 20,
							padding: 10,
							marginBottom: 20,
							marginHorizontal: "auto",
						}}>
						{this.state.includeCostEstimate ? (
							<Text
								style={{
									width: "100%",
									textAlign: "center",
									color: "white",
									fontSize: 16,
									fontWeight: "600",
									padding: 5,
								}}>
								Disable Cost estimate
							</Text>
						) : (
							<Text
								style={{
									width: "100%",
									textAlign: "center",
									color: "white",
									fontSize: 16,
									fontWeight: "600",
									padding: 5,
								}}>
								Enable Cost estimate
							</Text>
						)}
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						paddingBottom: "5%",
						display: this.state.includeCostEstimate
							? "flex"
							: "none",
					}}>
					<View
						style={{
							width: w,

							// height: h,
							height: "auto",
							maxWidth: 1200,
							padding: 20,

							flex: 1,
							zIndex: 1,
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
							}}>
							{/* <Text
								style={{
									fontSize: 35,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={700}>
									Quotation
								</Font>
							</Text> */}
							<TouchableOpacity
								onPress={async () => {
									this.setState({
										costEstimateModal: true,
									});
								}}
								style={{
									backgroundColor: APP_COLOURS.PURPLE,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 20,
									padding: 10,
									marginBottom: 20,
									marginHorizontal: "auto",
								}}>
								{this.state.loading ? (
									<ActivityIndicator color='#FFF' />
								) : (
									<Text
										style={{
											width: "100%",
											textAlign: "center",
											color: "white",
											fontSize: 16,
											fontWeight: "600",
											padding: 5,
										}}>
										Edit CE
									</Text>
								)}
							</TouchableOpacity>
						</View>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h * 0.2,
								width: w,
								// marginBottom: "40%",
							}}>
							<View
								style={{
									flex: 1,
									// padding: 20,
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={{
											fontSize: 35,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={700}>
											{title}
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 35 * 0.6,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={500}>
											{title2}
										</Font>
									</Text>
								</View>
							</View>
						</View>

						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h / 2,
								width: w,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										flexDirection: "row",
									}}></View>
								{/* <Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 25,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										This quotation is valid until until{" "}
										{moment(end_date).format("DD/MM/YYYY")}.
										Prices quoted are exclusive of taxes and
										may be subject to adjustment based on
										changes in project scope or unforeseen
										requirements. Payment terms are upfront
										unless otherwise stipulated. Any
										additional services requested outside
										the original scope will be quoted
										separately. Delivery timelines are
										estimates and may vary based on client
										feedback and approval processes. Project
										terms and conditions apply
									</Font>
								</Text> */}

								{/* <Text
									style={{
										fontSize: 19,
										color: "#FFF",
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Additional
									</Font>
								</Text> */}
								{additionalItems !== null
									? additionalItems.map((d, i) => (
											<View
												style={{
													fontSize: 15,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													// flexDirection: "row",
													marginBottom: 10,
												}}>
												<Text
													style={{
														fontSize: 16,
														color: APP_COLOURS.TEXTCOLOR,
														paddingLeft: 12,
													}}>
													<Font
														family='Montserrat'
														weight={400}>
														{d.title}
													</Font>
												</Text>

												<Text
													style={{
														fontSize: 14,
														color: APP_COLOURS.TEXTCOLOR,
														paddingLeft: 12,
													}}>
													<Font
														family='Montserrat'
														weight={300}>
														{d.desc}
													</Font>
												</Text>
											</View>
									  ))
									: null}

								<View style={{ height: 40 }} />

								{onceOffCosts !== null ? (
									onceOffCosts.length > 0 ? (
										<>
											<View
												style={{
													flexDirection: "row",
													borderTopWidth: 1,
													borderStyle: "dotted",
													borderColor:
														APP_COLOURS.TEXTCOLOR,
													paddingVertical: 10,
												}}>
												<View style={{ flex: 1 }}>
													<Text
														style={{
															fontSize: 20,
															color: "#FFF",
															flexDirection:
																"row",
														}}>
														<Font
															family='Montserrat'
															weight={700}>
															Once off costs
														</Font>
													</Text>
												</View>
											</View>
											{onceOffCosts.map((d, i) =>
												this.costItem(d, `2${i}`),
											)}
											<View
												style={{
													width: "100%",
													alignItems: "flex-end",
												}}>
												<Text
													style={{
														fontSize: 18,
														color: APP_COLOURS.TEXTCOLOR,
														flexDirection: "row",
													}}>
													<Font
														family='Montserrat'
														weight={700}>
														R{" "}
														{onceOffCosts.reduce(
															(
																accumulator,
																currentValue,
															) => {
																return (
																	accumulator +
																	parseFloat(
																		currentValue.totalExcl,
																	)
																);
															},
															0,
														)}
													</Font>
												</Text>
											</View>
										</>
									) : null
								) : null}

								{annuallyRecurring !== null ? (
									annuallyRecurring.length > 0 ? (
										<>
											<View
												style={{
													flexDirection: "row",
													borderTopWidth: 1,
													borderStyle: "dotted",
													borderColor:
														APP_COLOURS.DESELECTEDCOLOR,
													paddingVertical: 10,
													marginTop: 30,
												}}>
												<View style={{ flex: 1 }}>
													<Text
														style={{
															fontSize: 20,
															color: "#FFF",
															flexDirection:
																"row",
														}}>
														<Font
															family='Montserrat'
															weight={700}>
															Annually recurring
															costs
														</Font>
													</Text>
												</View>
											</View>
											{annuallyRecurring.map((d, i) =>
												this.costItem(d, `2${i}`),
											)}
											<View
												style={{
													width: "100%",
													alignItems: "flex-end",
												}}>
												<Text
													style={{
														fontSize: 18,
														color: APP_COLOURS.TEXTCOLOR,
														flexDirection: "row",
													}}>
													<Font
														family='Montserrat'
														weight={700}>
														R{" "}
														{annuallyRecurring.reduce(
															(
																accumulator,
																currentValue,
															) => {
																return (
																	accumulator +
																	parseFloat(
																		currentValue.totalExcl,
																	)
																);
															},
															0,
														)}
													</Font>
												</Text>
											</View>
										</>
									) : null
								) : null}

								{monthlyRecurring !== null ? (
									monthlyRecurring.length > 0 ? (
										<>
											<View
												style={{
													flexDirection: "row",
													borderTopWidth: 1,
													borderStyle: "dotted",
													borderColor:
														APP_COLOURS.DESELECTEDCOLOR,
													paddingVertical: 10,
													marginTop: 30,
												}}>
												<View style={{ flex: 1 }}>
													<Text
														style={{
															fontSize: 20,
															color: "#FFF",
															flexDirection:
																"row",
														}}>
														<Font
															family='Montserrat'
															weight={700}>
															Monthly recurring
															costs
														</Font>
													</Text>
												</View>
											</View>
											{monthlyRecurring.map((d, i) =>
												this.costItem(d, `2${i}`),
											)}
											<View
												style={{
													width: "100%",
													alignItems: "flex-end",
												}}>
												<Text
													style={{
														fontSize: 18,
														color: APP_COLOURS.TEXTCOLOR,
														flexDirection: "row",
													}}>
													<Font
														family='Montserrat'
														weight={700}>
														R{" "}
														{monthlyRecurring.reduce(
															(
																accumulator,
																currentValue,
															) => {
																return (
																	accumulator +
																	parseFloat(
																		currentValue.totalExcl,
																	)
																);
															},
															0,
														)}
													</Font>
												</Text>
											</View>
										</>
									) : null
								) : null}

								{usageBased !== null ? (
									usageBased.length > 0 ? (
										<>
											<View
												style={{
													flexDirection: "row",
													borderTopWidth: 1,
													borderStyle: "dotted",
													borderColor:
														APP_COLOURS.DESELECTEDCOLOR,
													paddingVertical: 10,
													marginTop: 30,
												}}>
												<View style={{ flex: 1 }}>
													<Text
														style={{
															fontSize: 20,
															color: "#FFF",
															flexDirection:
																"row",
														}}>
														<Font
															family='Montserrat'
															weight={700}>
															Useage based costs
														</Font>
													</Text>
												</View>
											</View>
											{usageBased.map((d, i) =>
												this.costItem(d, `2${i}`),
											)}
											<View
												style={{
													width: "100%",
													alignItems: "flex-end",
												}}>
												<Text
													style={{
														fontSize: 19,
														color: APP_COLOURS.TEXTCOLOR,
														flexDirection: "row",
													}}>
													<Font
														family='Montserrat'
														weight={700}>
														R{" "}
														{usageBased.reduce(
															(
																accumulator,
																currentValue,
															) => {
																return (
																	accumulator +
																	parseFloat(
																		currentValue.totalExcl,
																	)
																);
															},
															0,
														)}
													</Font>
												</Text>
											</View>
										</>
									) : null
								) : null}
							</View>
						</View>

						{/* <Text
							style={{
								fontSize: 13,
								color: APP_COLOURS.TEXTCOLOR,
								marginBottom: 2,
								flexDirection: "row",
								marginTop: 70,
							}}>
							<Font family='Montserrat' weight={300}>
								* All quoted amounts are monthly recurring and
								exclusive of VAT unless stated otherwise.
							</Font>
						</Text> */}
					</View>
					<View
						style={{
							width: w,

							// height: h,
							height: "auto",
							maxWidth: 1200,
							borderRadius: 15,

							flex: 1,
							zIndex: 1,
							backgroundColor: "#FFF",
							flexDirection: "row",
							overflow: "hidden",
						}}>
						<View style={{ flex: 1, paddingLeft: 20 }}>
							<View
								style={{
									flex: 1,
									padding: 20,
									justifyContent: "flex-end",
									alignItems: "flex-end",
									height: 350,
									borderRadius: 10,

									marginBottom: 40,
									width: "100%",
								}}>
								<View
									style={{
										borderTopColor: "#000",
										// width: "90%",
										borderTopWidth: 1,
										borderStyle: "dotted",
										height: 1,
										width: "100%",
									}}>
									<Text
										style={{
											width: "100%",
											textAlign: "center",
											color: "#000",
											fontSize: 16,
											fontWeight: "600",
											padding: 5,
										}}>
										Accept this cost estimate by signing
										below
									</Text>
								</View>
							</View>

							{/* <TouchableOpacity
								onPress={async () => {
									if (this.state.base64Signature !== "") {
										try {
											this.setState({
												loading: true,
											});
											const response = await fetch(
												"https://workflow.tetrice.co.za/webhook/1f3fc0e1-b076-4a78-ab9a-47178aff72cb",
												{
													method: "POST",
													body: this.state.formData,
												},
											);
											const result =
												await response.json();
											console.log("Success:", result);
											this.setState({
												successful: true,
											});
											// Handle success (e.g., show a success message)
										} catch (error) {
											console.error("Error:", error);
											// Handle error (e.g., show an error message)
										}
									} else {
										this.setState({
											showSignaturePad: true,
										});
									}
								}}
								style={{
									width: "100%",
									backgroundColor: APP_COLOURS.MAIN,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 20,
									padding: 10,
									marginBottom: 20,
									marginHorizontal: "auto",
								}}>
								{this.state.loading ? (
									<ActivityIndicator color='#FFF' />
								) : (
									<Text
										style={{
											width: "100%",
											textAlign: "center",
											color: "white",
											fontSize: 16,
											fontWeight: "600",
											padding: 5,
										}}>
										Sign & Accept
									</Text>
								)}
							</TouchableOpacity> */}
						</View>
						<View
							style={{
								alignItems: "flex-end",
								justifyContent: "flex-end",
							}}>
							<Image
								source={require("./agreeMent.png")}
								style={{
									width: 200 * (368.94 / 255.42),
									height: 200,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View>

					<Modal
						animationType='fade'
						transparent={true}
						visible={this.state.costEstimateModal}>
						<View
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								backgroundColor: "rgba(0, 0, 0, 0.6)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									// width: "100%",
									// maxWidth: 600,
									width: 600,
									bottom: 0,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
									backgroundColor: "#FFF",
									borderRadius: 20,
									padding: 10,
								}}>
								<AddCostModal
									defaultCosts={this.state}
									saveParent={(d) => {
										console.log(d);

										let obj = {
											...d,
											includeCostEstimate:
												this.state.includeCostEstimate,
										};

										// console.log(obj);
										// return;

										this.setState({
											...d,
											costEstimateModal: false,
										});

										// const removeEmptyArrays = (obj) => {
										// 	return Object.fromEntries(
										// 		Object.entries(obj).filter(
										// 			([key, value]) =>
										// 				!(
										// 					Array.isArray(
										// 						value,
										// 					) &&
										// 					value.length === 0
										// 				),
										// 		),
										// 	);
										// };

										// obj = removeEmptyArrays(obj);

										this.props.updateCostEstimate(obj);
									}}
								/>
							</View>
						</View>
					</Modal>

					{/* <Modal
						animationType='fade'
						transparent={true}
						visible={this.state.showSignaturePad}>
						<View
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								backgroundColor: "rgba(0, 0, 0, 0.6)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: window.innerWidth,
									height: window.innerHeight,
									backgroundColor: "rgba(0, 0, 0, 0.6)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										accept: true,
										showSignaturePad: false,
									});
								}}
							/>
							<View
								style={{
									width: "100%",
									maxWidth: 700,
									bottom: 0,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
									backgroundColor: "#FFF",
									borderRadius: 20,
									padding: 10,
								}}>
								<SignaturePad
									onEnd={(base64Signature) => {
										// console.log(base64Signature);
										clearTimeout(this._timeout);
										this._timeout = setTimeout(() => {
											this.setState({
												base64Signature,
											});
										}, 1000);
									}}
								/>
								<View
									style={{
										// position: "absolute",
										bottom: 150,
										borderTopColor: "#000",
										// width: "90%",
										borderTopWidth: 1,
										borderStyle: "dotted",
										height: 1,
										width: "100%",
									}}>
									<Text
										style={{
											width: "100%",
											textAlign: "center",
											color: "#000",
											fontSize: 16,
											fontWeight: "600",
											padding: 5,
										}}>
										Signature
									</Text>
								</View>
								<TouchableOpacity
									onPress={() => {
										this.setState({
											accept: true,
											showSignaturePad: false,
										});
									}}
									style={{
										flex: 1,
										backgroundColor: APP_COLOURS.MAIN,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 20,
										padding: 10,
										width: "100%",
										marginHorizontal: "auto",
									}}>
									<Text
										style={{
											width: "100%",
											textAlign: "center",
											color: "white",
											fontSize: 16,
											fontWeight: "600",
											padding: 5,
										}}>
										Done
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					</Modal> */}
				</View>
			</View>
		);
	}

	costItem = (d, i) => {
		return (
			<View
				key={i}
				onMouseEnter={() => this.setState({ hover: i })}
				onMouseLeave={() => this.setState({ hover: "" })}
				style={{
					marginBottom: 10,
					// marginT: 6,
					alignItems: "flex-start",
					justifyContent: "space-between",
					flexDirection: "row",
					width: "100%",
					// backgroundColor:
					// 	this.state.hover === i
					// 		? APP_COLOURS.WHITE_TRANSPARENT3
					// 		: APP_COLOURS.WHITE_TRANSPARENT4,
					// padding: 5,

					borderRadius: 5,
				}}>
				<View
					style={{
						flex: 1,
						borderLeftWidth: 0.5,
						borderLeftColor: APP_COLOURS.BACK_TRANSPARENT2,
						paddingLeft: 20,
					}}>
					<Text
						style={{
							fontSize: 16,
							color: APP_COLOURS.TEXTCOLOR,
							flexDirection: "row",
						}}>
						<Font family='Montserrat' weight={500}>
							{d.title}
						</Font>
					</Text>
					<Text
						style={{
							fontSize: 13,
							color: APP_COLOURS.TEXTCOLOR,
							flexDirection: "row",
							opacity: 0.7,
						}}>
						<Font family='Montserrat' weight={300}>
							{d.desc}
						</Font>
					</Text>
				</View>
				<Text
					style={{
						fontSize: 17,
						color: APP_COLOURS.TEXTCOLOR,
						flexDirection: "row",
					}}>
					<Font family='Montserrat' weight={300}>
						R {d.totalExcl}
					</Font>
				</Text>
			</View>
		);
	};
}
