import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Switch,
	TextInput,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import { crudUpdate } from "../../CRUDHELPER";

export default class PreflightChecklist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			checklist: {
				creativeApproved:
					this.props.campaign?.checklist?.creativeApproved || false,
				creativeNotes:
					this.props.campaign?.checklist?.creativeNotes || "",
				budgetConfirmed:
					this.props.campaign?.checklist?.budgetConfirmed || false,
				budgetNotes: this.props.campaign?.checklist?.budgetNotes || "",
				targetingReviewed:
					this.props.campaign?.checklist?.targetingReviewed || false,
				targetingNotes:
					this.props.campaign?.checklist?.targetingNotes || "",
				trackingSetup:
					this.props.campaign?.checklist?.trackingSetup || false,
				trackingNotes:
					this.props.campaign?.checklist?.trackingNotes || "",
				landingPagesReady:
					this.props.campaign?.checklist?.landingPagesReady || false,
				landingPagesNotes:
					this.props.campaign?.checklist?.landingPagesNotes || "",
				pixelsInstalled:
					this.props.campaign?.checklist?.pixelsInstalled || false,
				pixelsNotes: this.props.campaign?.checklist?.pixelsNotes || "",
				retailerBriefed:
					this.props.campaign?.checklist?.retailerBriefed || false,
				retailerNotes:
					this.props.campaign?.checklist?.retailerNotes || "",
			},
		};
	}

	handleChecklistUpdate = async () => {
		this.setState({ loading: true });

		try {
			const updatedCampaign = {
				_id: this.props.campaign._id,
				checklist: this.state.checklist,
			};

			await crudUpdate("campaigns", updatedCampaign, (status) => {
				if (status === 200) {
					this.setState({ loading: false });
					if (this.props.onUpdate) {
						this.props.onUpdate(this.state.checklist);
					}
				}
			});
		} catch (error) {
			console.error("Error updating checklist:", error);
			this.setState({ loading: false });
		}
	};

	isChecklistComplete = () => {
		const requiredFields = [
			"creativeApproved",
			"budgetConfirmed",
			"targetingReviewed",
			"trackingSetup",
			"landingPagesReady",
			"pixelsInstalled",
			"retailerBriefed",
		];

		return requiredFields.every((field) => this.state.checklist[field]);
	};

	renderChecklistItem = (label, field, notesField) => (
		<View style={styles.checklistItem}>
			<View style={styles.checklistHeader}>
				<Text style={styles.checklistLabel}>{label}</Text>
				<Switch
					value={this.state.checklist[field]}
					onValueChange={(value) => {
						this.setState((prevState) => ({
							checklist: {
								...prevState.checklist,
								[field]: value,
							},
						}));
					}}
					trackColor={{ false: "#767577", true: APP_COLOURS.GREEN }}
				/>
			</View>
			<TextInput
				style={styles.notesInput}
				placeholder='Add notes...'
				value={this.state.checklist[notesField]}
				onChangeText={(text) => {
					this.setState((prevState) => ({
						checklist: {
							...prevState.checklist,
							[notesField]: text,
						},
					}));
				}}
				multiline
			/>
		</View>
	);

	render() {
		return (
			<View style={styles.container}>
				<ScrollView style={styles.scrollView}>
					<Text style={styles.title}>
						Campaign Preflight Checklist
					</Text>

					{this.renderChecklistItem(
						"Creative Assets Approved",
						"creativeApproved",
						"creativeNotes",
					)}

					{this.renderChecklistItem(
						"Budget Confirmed",
						"budgetConfirmed",
						"budgetNotes",
					)}

					{this.renderChecklistItem(
						"Targeting Strategy Reviewed",
						"targetingReviewed",
						"targetingNotes",
					)}

					{this.renderChecklistItem(
						"Tracking Setup Complete",
						"trackingSetup",
						"trackingNotes",
					)}

					{this.renderChecklistItem(
						"Landing Pages Ready",
						"landingPagesReady",
						"landingPagesNotes",
					)}

					{this.renderChecklistItem(
						"Tracking Pixels Installed",
						"pixelsInstalled",
						"pixelsNotes",
					)}

					{this.renderChecklistItem(
						"Retailer Briefed",
						"retailerBriefed",
						"retailerNotes",
					)}

					<TouchableOpacity
						style={[
							styles.submitButton,
							!this.isChecklistComplete() &&
								styles.submitButtonDisabled,
						]}
						onPress={this.handleChecklistUpdate}
						disabled={
							!this.isChecklistComplete() || this.state.loading
						}>
						<View style={styles.submitButtonContent}>
							{this.state.loading ? (
								<ActivityIndicator
									color='#FFF'
									style={{ marginRight: 10 }}
								/>
							) : null}
							<Text style={styles.submitButtonText}>
								{this.state.loading
									? "Updating..."
									: "Update Checklist"}
							</Text>
						</View>
					</TouchableOpacity>
				</ScrollView>
			</View>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		backgroundColor: "#EFF5FC",
		borderRadius: 10,
		padding: 15,
	},
	scrollView: {
		flex: 1,
	},
	title: {
		fontSize: 20,
		fontWeight: "700",
		color: APP_COLOURS.BG2,
		marginBottom: 20,
		fontFamily: "Montserrat",
	},
	checklistItem: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		padding: 15,
		marginBottom: 15,
	},
	checklistHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 10,
	},
	checklistLabel: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		fontFamily: "Montserrat",
	},
	notesInput: {
		backgroundColor: "#F8F9FA",
		borderRadius: 6,
		padding: 10,
		minHeight: 60,
		fontFamily: "Montserrat",
	},
	submitButton: {
		backgroundColor: APP_COLOURS.GREEN,
		borderRadius: 25,
		padding: 13,
		marginTop: 20,
		marginBottom: 30,
	},
	submitButtonDisabled: {
		backgroundColor: "#ccc",
	},
	submitButtonContent: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	submitButtonText: {
		color: "#FFF",
		fontSize: 15,
		fontWeight: "700",
		fontFamily: "Montserrat",
		textAlign: "center",
	},
};
