import React, { Component } from "react";
import { View, Text, ScrollView, TextInput } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function calculateGrowth(current, previous) {
	return (((current - previous) / previous) * 100).toFixed(1);
}

class YearlyComparison extends Component {
	constructor(props) {
		super(props);
		this.state = {
			yearlyData: {
				2023: {
					growth: {
						sellOutGrowth: 8.5,
						ecommerceContribution: 10,
						marketShare: 15,
					},
					financial: {
						totalSpend: 2500000,
						roas: 2.8,
						revenue: 7000000,
					},
					performance: {
						impressions: 15000000,
						clicks: 250000,
						conversions: 12500,
					},
					engagement: {
						socialMedia: 8.5,
						influencerROI: 4.2,
						brandAwareness: 65,
					},
					influencerMetrics: {
						conversionRate: 3.2,
						awarenessContribution: 22,
						utmEffectiveness: 75,
						prAlignment: 90,
					},
					omniChannelMetrics: {
						experienceConsistency: 85,
						channelIntegration: 78,
						campaignAlignment: 92,
						inStoreDigitalSync: 80,
					},
					brandMetrics: {
						awareness: 75,
						consideration: 65,
						preference: 45,
						loyalty: 80,
					},
					searchMetrics: {
						seoScore: 82,
						ppcEfficiency: 88,
						keywordRanking: 4.2,
						searchVisibility: 75,
					},
				},
				2022: {
					growth: {
						sellOutGrowth: 7.2,
						ecommerceContribution: 8,
						marketShare: 12,
					},
					financial: {
						totalSpend: 2000000,
						roas: 2.5,
						revenue: 5000000,
					},
					performance: {
						impressions: 12000000,
						clicks: 200000,
						conversions: 10000,
					},
					engagement: {
						socialMedia: 7.0,
						influencerROI: 3.8,
						brandAwareness: 55,
					},
					influencerMetrics: {
						conversionRate: 2.8,
						awarenessContribution: 18,
						utmEffectiveness: 65,
						prAlignment: 85,
					},
					omniChannelMetrics: {
						experienceConsistency: 75,
						channelIntegration: 70,
						campaignAlignment: 85,
						inStoreDigitalSync: 72,
					},
					brandMetrics: {
						awareness: 65,
						consideration: 55,
						preference: 40,
						loyalty: 75,
					},
					searchMetrics: {
						seoScore: 75,
						ppcEfficiency: 82,
						keywordRanking: 5.1,
						searchVisibility: 68,
					},
				},
			},
			strategyNotes: {
				influencerStrategy: {
					description:
						"Leverage influencers more effectively to drive full-funnel results.",
					focus: "Measure UTM links to identify which influencers drive conversions versus awareness for better segmentation.",
				},
				omniChannelIntegration: {
					description:
						"Ensure consistent customer experiences across all digital and in-store touchpoints.",
					focus: "Coordinate digital campaigns with in-store promotions and activations.",
				},
				brandDirection: {
					description:
						"Focus on increasing profitability while reducing business costs.",
					focus: "Move towards a multi-movement (maturity) phase.",
				},
				searchStrategy: {
					description:
						"Optimize SEO efforts and invest in PPC advertising.",
					focus: "Improve keyword rankings and conversion rates.",
				},
			},
		};
	}

	renderMetricRow = (
		label,
		metric2023,
		metric2022,
		format = "number",
		prefix = "",
	) => {
		const growth = calculateGrowth(metric2023, metric2022);
		const formattedValue = (value) => {
			if (format === "currency") return `R ${currencyFormat(value)}`;
			if (format === "percentage") return `${value}%`;
			if (format === "number") return value.toLocaleString();
			return value;
		};

		return (
			<View style={styles.row}>
				<Text style={styles.label}>{label}</Text>
				<Text style={styles.value}>{formattedValue(metric2022)}</Text>
				<Text style={styles.value}>{formattedValue(metric2023)}</Text>
				<Text
					style={[
						styles.growth,
						{
							color:
								parseFloat(growth) >= 0
									? APP_COLOURS.GREEN
									: APP_COLOURS.RED,
						},
					]}>
					{growth}%
				</Text>
			</View>
		);
	};

	renderSection = (title, dataKey, format = "number", isStrategy = false) => {
		const strategyNoteKey = {
			influencerMetrics: "influencerStrategy",
			omniChannelMetrics: "omniChannelIntegration",
			brandMetrics: "brandDirection",
			searchMetrics: "searchStrategy",
		};

		return (
			<View style={styles.section}>
				<Text style={styles.sectionHeader}>{title}</Text>
				<View style={styles.headerRow}>
					<Text style={styles.headerLabel}>Metric</Text>
					<Text style={styles.headerValue}>2022</Text>
					<Text style={styles.headerValue}>2023</Text>
					<Text style={styles.headerValue}>Growth</Text>
				</View>
				{Object.entries(this.state.yearlyData[2023][dataKey]).map(
					([key, value]) => {
						const currentValue = value;
						const previousValue =
							this.state.yearlyData[2022][dataKey][key];
						const label = key
							.replace(/([A-Z])/g, " $1")
							.replace(/^./, (str) => str.toUpperCase());
						return this.renderMetricRow(
							label,
							currentValue,
							previousValue,
							format,
						);
					},
				)}
				{isStrategy &&
					this.renderStrategyNotes(strategyNoteKey[dataKey])}
				<View style={styles.sectionDivider} />
			</View>
		);
	};

	renderStrategyNotes = (sectionKey) => {
		const notes = this.state.strategyNotes[sectionKey];
		if (!notes) {
			console.log("No strategy notes found for:", sectionKey);
			return null;
		}

		return (
			<View style={styles.strategyContainer}>
				<Text style={styles.strategyLabel}>Strategy Notes</Text>
				<TextInput
					style={styles.strategyInput}
					multiline
					placeholder='Enter strategy description...'
					value={notes.description}
					onChangeText={(text) => {
						this.setState((prevState) => ({
							strategyNotes: {
								...prevState.strategyNotes,
								[sectionKey]: {
									...prevState.strategyNotes[sectionKey],
									description: text,
								},
							},
						}));
					}}
				/>

				<Text style={styles.strategyLabel}>Focus Areas</Text>
				<TextInput
					style={styles.strategyInput}
					multiline
					placeholder='Enter focus areas...'
					value={notes.focus}
					onChangeText={(text) => {
						this.setState((prevState) => ({
							strategyNotes: {
								...prevState.strategyNotes,
								[sectionKey]: {
									...prevState.strategyNotes[sectionKey],
									focus: text,
								},
							},
						}));
					}}
				/>
			</View>
		);
	};

	render() {
		const { yearlyData } = this.state;

		return (
			<ScrollView style={styles.container}>
				<Text style={styles.title}>Year-over-Year Performance</Text>

				{this.renderSection("Growth Metrics", "growth", "percentage")}
				{this.renderSection(
					"Financial Performance",
					"financial",
					"currency",
				)}
				{this.renderSection(
					"Performance Metrics",
					"performance",
					"number",
				)}
				{this.renderSection(
					"Engagement Metrics",
					"engagement",
					"number",
				)}
				{this.renderSection(
					"Influencer Strategy",
					"influencerMetrics",
					"number",
					true,
				)}
				{this.renderSection(
					"Omni-Channel Integration",
					"omniChannelMetrics",
					"number",
					true,
				)}
				{this.renderSection(
					"Brand Direction",
					"brandMetrics",
					"number",
					true,
				)}
				{this.renderSection(
					"Search Strategy",
					"searchMetrics",
					"number",
					true,
				)}
			</ScrollView>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		backgroundColor: "#FFF",
		padding: 20,
	},
	title: {
		fontSize: 24,
		fontWeight: "700",
		color: APP_COLOURS.BG2,
		marginBottom: 30,
	},
	section: {
		marginBottom: 30,
	},
	sectionHeader: {
		fontSize: 18,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 15,
	},
	headerRow: {
		flexDirection: "row",
		paddingVertical: 10,
		borderBottomWidth: 2,
		borderBottomColor: APP_COLOURS.BG2,
	},
	headerLabel: {
		flex: 2,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	headerValue: {
		flex: 1,
		textAlign: "right",
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	row: {
		flexDirection: "row",
		paddingVertical: 12,
		borderBottomWidth: 1,
		borderBottomColor: APP_COLOURS.BG5,
	},
	label: {
		flex: 2,
		color: APP_COLOURS.BG3,
	},
	value: {
		flex: 1,
		textAlign: "right",
		color: APP_COLOURS.BG3,
	},
	growth: {
		flex: 1,
		textAlign: "right",
		fontWeight: "600",
	},
	sectionDivider: {
		height: 1,
		backgroundColor: APP_COLOURS.BG2,
		marginVertical: 15,
	},
	strategyContainer: {
		marginTop: 20,
		padding: 15,
		backgroundColor: "#F8F9FA",
		borderRadius: 8,
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
	},
	strategyLabel: {
		fontSize: 14,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 8,
	},
	strategyInput: {
		backgroundColor: "#FFF",
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
		borderRadius: 6,
		padding: 12,
		marginBottom: 16,
		minHeight: 80,
		textAlignVertical: "top",
		color: APP_COLOURS.BG3,
		fontSize: 14,
	},
};

export default YearlyComparison;
