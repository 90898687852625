import React, { useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import ImageUploaderComp from "./ImageUploaderComp";
import { crudDelete, crudCreateWithBase64 } from "../../CRUDHELPER";

const ASSET_STATUS = {
	PENDING: {
		label: "Pending",
		color: APP_COLOURS.ORANGE,
		icon: "⏳",
		nextAction: "Begin Work",
	},
	IN_PROGRESS: {
		label: "In Progress",
		color: APP_COLOURS.BLUE,
		icon: "🔄",
		nextAction: "Mark as Done",
	},
	UNDER_REVIEW: {
		label: "Under Review",
		color: APP_COLOURS.PURPLE,
		icon: "👀",
		nextAction: null,
	},
	ATTENTION_REQUIRED: {
		label: "Attention Required",
		color: APP_COLOURS.RED,
		icon: "⚠️",
		nextAction: "Resume Work",
	},
	COMPLETED: {
		label: "Completed",
		color: APP_COLOURS.GREEN,
		icon: "✓",
		nextAction: null,
	},
};

const getSizePreviewDimensions = (sizeString) => {
	// Extract dimensions from size string (e.g., "16:9 (1920x1080px)" -> "16:9")
	const ratio = sizeString.split(" ")[0];
	const [width, height] = ratio.split(":").map((num) => parseInt(num));

	// Base width for preview
	const baseWidth = 60;
	const scale = baseWidth / width;

	return {
		width: width * scale,
		height: height * scale,
	};
};

const getStatusIndicator = (status) => {
	switch (status) {
		case ASSET_STATUS.IN_PROGRESS:
			return {
				color: APP_COLOURS.BLUE,
				icon: "🔄",
				label: "In Progress",
			};
		case ASSET_STATUS.UNDER_REVIEW:
			return {
				color: APP_COLOURS.PURPLE,
				icon: "👀",
				label: "Under Review",
			};
		case ASSET_STATUS.COMPLETED:
			return {
				color: APP_COLOURS.GREEN,
				icon: "✓",
				label: "Completed",
			};
		case ASSET_STATUS.ATTENTION_REQUIRED:
			return {
				color: APP_COLOURS.RED,
				icon: "⚠️",
				label: "Needs Changes",
			};
		default:
			return {
				color: APP_COLOURS.ORANGE,
				icon: "⏳",
				label: "Pending",
			};
	}
};

const AssetItem = ({ asset, onPress, onStatusChange }) => {
	const getBackgroundColor = (status) => {
		switch (status) {
			case "In Progress":
				return "#F0F7FF"; // Light blue
			case "Under Review":
				return "#F7F0FF"; // Light purple
			case "Attention Required":
				return "#FFF0F0"; // Light red
			case "Completed":
				return "#F0FFF4"; // Light green
			default:
				return "#FFF8F0"; // Light orange for pending
		}
	};

	const handleStatusChange = (newStatus) => {
		if (onStatusChange) {
			onStatusChange(asset, newStatus);
		}
	};

	return (
		<TouchableOpacity
			style={[
				styles.assetItemContainer,
				{
					backgroundColor: getBackgroundColor(asset.status),
					borderColor:
						ASSET_STATUS[asset.status]?.color ||
						ASSET_STATUS.PENDING.color,
					borderWidth: asset.status === "In Progress" ? 2 : 1,
				},
				asset.status === "In Progress" && styles.activeAssetContainer,
			]}
			onPress={onPress}>
			<View style={styles.assetRow}>
				<View style={styles.assetInfo}>
					<View style={styles.assetHeader}>
						<Text style={styles.assetItem}>• {asset.type}</Text>
						<View
							style={[
								styles.statusIndicator,
								{
									backgroundColor: "#FFF",
									borderColor:
										ASSET_STATUS[asset.status]?.color,
									borderWidth: 1,
								},
							]}>
							<Text style={styles.statusIcon}>
								{ASSET_STATUS[asset.status]?.icon}
							</Text>
							<Text
								style={[
									styles.statusLabel,
									{
										color: ASSET_STATUS[asset.status]
											?.color,
									},
								]}>
								{asset.status}
							</Text>
						</View>
					</View>
					<View style={styles.sizePreviewContainer}>
						<View
							style={[
								styles.sizePreview,
								{
									width: getSizePreviewDimensions(asset.size)
										.width,
									height: getSizePreviewDimensions(asset.size)
										.height,
									backgroundColor:
										ASSET_STATUS[asset.status]?.color,
									opacity: 0.2,
									borderColor:
										ASSET_STATUS[asset.status]?.color,
								},
							]}
						/>
						<Text style={styles.sizeText}>{asset.size}</Text>
					</View>
				</View>
			</View>

			{asset.brief && (
				<Text style={styles.briefPreview} numberOfLines={2}>
					{asset.brief}
				</Text>
			)}

			{asset.status === "Pending" && (
				<TouchableOpacity
					style={[
						styles.actionButton,
						{ backgroundColor: APP_COLOURS.BLUE },
					]}
					onPress={() => handleStatusChange("In Progress")}>
					<Text style={styles.actionButtonText}>Begin Work</Text>
				</TouchableOpacity>
			)}

			{asset.status === "In Progress" && (
				<TouchableOpacity
					style={[
						styles.actionButton,
						{ backgroundColor: APP_COLOURS.GREEN },
					]}
					onPress={() => handleStatusChange("Under Review")}>
					<Text style={styles.actionButtonText}>Mark as Done</Text>
				</TouchableOpacity>
			)}

			{asset.files?.length > 0 && (
				<Text
					style={[
						styles.filesCount,
						{ color: ASSET_STATUS[asset.status]?.color },
					]}>
					{asset.files.length} file(s) uploaded
				</Text>
			)}

			{/* {StatusTransitionButtons({ asset, onStatusChange })} */}
		</TouchableOpacity>
	);
};

const StatusTransitionButtons = ({ asset, onStatusChange }) => {
	const getNextAction = () => {
		switch (asset.status) {
			case ASSET_STATUS.PENDING.label:
				return {
					action: "Begin Work",
					icon: "🎯",
					color: APP_COLOURS.BLUE,
					nextStatus: ASSET_STATUS.IN_PROGRESS.label,
				};
			case ASSET_STATUS.IN_PROGRESS.label:
				return {
					action: "Submit for Review",
					icon: "✓",
					color: APP_COLOURS.GREEN,
					nextStatus: ASSET_STATUS.UNDER_REVIEW.label,
				};
			case ASSET_STATUS.ATTENTION_REQUIRED.label:
				return {
					action: "Submit for Review",
					icon: "✓",
					color: APP_COLOURS.GREEN,
					nextStatus: ASSET_STATUS.UNDER_REVIEW.label,
				};
			case ASSET_STATUS.UNDER_REVIEW.label:
				return null; // Will show approve/reject buttons instead
			default:
				return null;
		}
	};

	const nextAction = getNextAction();

	return (
		<View style={styles.statusSection}>
			<View style={styles.statusHeader}>
				<View style={styles.currentStatusIndicator}>
					<Text style={styles.currentStatusLabel}>
						Current Status:
					</Text>
					<Text
						style={[
							styles.currentStatusText,
							{ color: getStatusIndicator(asset.status).color },
						]}>
						{getStatusIndicator(asset.status).icon} {asset.status}
					</Text>
				</View>
			</View>

			{nextAction ? (
				<TouchableOpacity
					style={[
						styles.actionButton,
						{ backgroundColor: nextAction.color },
					]}
					onPress={() =>
						onStatusChange(asset, nextAction.nextStatus)
					}>
					<Text style={styles.actionIcon}>{nextAction.icon}</Text>
					<Text style={styles.actionText}>{nextAction.action}</Text>
				</TouchableOpacity>
			) : asset.status === ASSET_STATUS.UNDER_REVIEW.label ? (
				<View style={styles.reviewActions}>
					<TouchableOpacity
						style={[
							styles.actionButton,
							{ backgroundColor: APP_COLOURS.ORANGE },
						]}
						onPress={() =>
							onStatusChange(
								asset,
								ASSET_STATUS.ATTENTION_REQUIRED.label,
							)
						}>
						<Text style={styles.actionIcon}>⚠️</Text>
						<Text style={styles.actionText}>Request Changes</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={[
							styles.actionButton,
							{ backgroundColor: APP_COLOURS.GREEN },
						]}
						onPress={() =>
							onStatusChange(asset, ASSET_STATUS.COMPLETED.label)
						}>
						<Text style={styles.actionIcon}>✓</Text>
						<Text style={styles.actionText}>Approve</Text>
					</TouchableOpacity>
				</View>
			) : null}
		</View>
	);
};

const getAssetStatusCounts = (assets) => {
	return {
		total: assets.length,
		pending: assets.filter((a) => a.status === ASSET_STATUS.PENDING.label)
			.length,
		inProgress: assets.filter(
			(a) => a.status === ASSET_STATUS.IN_PROGRESS.label,
		).length,
		underReview: assets.filter(
			(a) => a.status === ASSET_STATUS.UNDER_REVIEW.label,
		).length,
		completed: assets.filter(
			(a) => a.status === ASSET_STATUS.COMPLETED.label,
		).length,
		needsAttention: assets.filter(
			(a) => a.status === ASSET_STATUS.ATTENTION_REQUIRED.label,
		).length,
	};
};

const getDetailedStatusMessage = (counts) => {
	const messages = [];
	if (counts.inProgress > 0)
		messages.push(`${counts.inProgress} in progress`);
	if (counts.underReview > 0)
		messages.push(`${counts.underReview} under review`);
	if (counts.completed > 0) messages.push(`${counts.completed} completed`);
	if (counts.needsAttention > 0)
		messages.push(`${counts.needsAttention} needs attention`);
	if (counts.pending > 0) messages.push(`${counts.pending} pending`);
	return messages.join(", ");
};

const getStatusChangeReason = (assets, newStatus) => {
	const counts = getAssetStatusCounts(assets);

	// Check for specific status transitions
	if (counts.underReview === counts.total) {
		return "All assets are now under review";
	}
	if (counts.completed === counts.total) {
		return "All assets have been completed";
	}
	if (counts.inProgress > 0) {
		return `${counts.inProgress} asset(s) in progress`;
	}
	if (counts.needsAttention > 0) {
		return `${counts.needsAttention} asset(s) need attention`;
	}
	if (newStatus === ASSET_STATUS.IN_PROGRESS.label) {
		return "Work started on asset";
	}
	if (newStatus === ASSET_STATUS.UNDER_REVIEW.label) {
		return "Asset submitted for review";
	}
	if (newStatus === ASSET_STATUS.ATTENTION_REQUIRED.label) {
		return "Changes requested for asset";
	}
	if (newStatus === ASSET_STATUS.COMPLETED.label) {
		return "Asset approved and completed";
	}

	return "Status updated based on asset changes";
};

const getProgressColor = (assets) => {
	const counts = getAssetStatusCounts(assets);

	if (counts.completed === counts.total) return APP_COLOURS.GREEN;
	if (counts.underReview > 0) return APP_COLOURS.PURPLE;
	if (counts.inProgress > 0) return APP_COLOURS.BLUE;
	if (counts.needsAttention > 0) return APP_COLOURS.RED;
	return APP_COLOURS.ORANGE; // Default for pending
};

const StatusSummary = ({ assets }) => {
	const counts = getAssetStatusCounts(assets);

	return (
		<View style={styles.statusSummary}>
			<Text style={styles.statusSummaryTitle}>Status Summary:</Text>
			<View style={styles.statusCounts}>
				{counts.inProgress > 0 && (
					<Text
						style={[
							styles.statusCount,
							{ color: APP_COLOURS.BLUE },
						]}>
						{counts.inProgress} In Progress
					</Text>
				)}
				{counts.underReview > 0 && (
					<Text
						style={[
							styles.statusCount,
							{ color: APP_COLOURS.PURPLE },
						]}>
						{counts.underReview} Under Review
					</Text>
				)}
				{counts.completed > 0 && (
					<Text
						style={[
							styles.statusCount,
							{ color: APP_COLOURS.GREEN },
						]}>
						{counts.completed} Completed
					</Text>
				)}
				{counts.needsAttention > 0 && (
					<Text
						style={[
							styles.statusCount,
							{ color: APP_COLOURS.RED },
						]}>
						{counts.needsAttention} Needs Attention
					</Text>
				)}
				{counts.pending > 0 && (
					<Text
						style={[
							styles.statusCount,
							{ color: APP_COLOURS.ORANGE },
						]}>
						{counts.pending} Pending
					</Text>
				)}
			</View>
			<Text style={styles.completionText}>
				{counts.completed} of {counts.total} assets completed
			</Text>
		</View>
	);
};

const StatusCommentInput = ({ comment, setComment, onSubmit, onCancel }) => (
	<View style={styles.commentInputContainer}>
		<TextInput
			style={styles.commentInput}
			placeholder='Add a comment about this status change...'
			value={comment}
			onChangeText={setComment}
			multiline
		/>
		<View style={styles.commentButtons}>
			<TouchableOpacity style={styles.commentButton} onPress={onCancel}>
				<Text style={styles.commentButtonText}>Cancel</Text>
			</TouchableOpacity>
			<TouchableOpacity
				style={[styles.commentButton, styles.submitButton]}
				onPress={() => {
					if (comment.trim()) {
						onSubmit(comment);
					}
				}}>
				<Text style={styles.submitButtonText}>Submit</Text>
			</TouchableOpacity>
		</View>
	</View>
);

const AssetFileUpload = ({ asset, onFileUploaded }) => {
	const [uploading, setUploading] = useState(false);
	const [preview, setPreview] = useState(null);

	const handleFileDrop = async (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		handleFile(file);
	};

	const handleFileSelect = async (e) => {
		const file = e.target.files[0];
		handleFile(file);
	};

	const handleFile = async (file) => {
		if (!file) return;

		// Check file type and size
		const isImage = file.type.startsWith("image/");
		const isVideo = file.type.startsWith("video/");

		if (!isImage && !isVideo) {
			alert("Please upload an image or video file");
			return;
		}

		// Create preview
		const reader = new FileReader();
		reader.onload = (e) => {
			setPreview(e.target.result);
		};
		reader.readAsDataURL(file);

		setUploading(true);
		try {
			// Convert to base64 and upload
			const base64 = await toBase64(file);
			const uploadData = {
				document: {
					name: file.name,
					type: file.type,
					size: file.size,
					base64: base64,
				},
				assetId: asset._id,
			};

			await crudCreateWithBase64(
				"creative_assets",
				uploadData,
				(status, data) => {
					if (status === 200) {
						onFileUploaded({
							...data,
							preview: URL.createObjectURL(file),
						});
					}
				},
			);
		} catch (error) {
			console.error("Error uploading file:", error);
			alert("Failed to upload file");
		} finally {
			setUploading(false);
		}
	};

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	return (
		<View style={styles.uploadContainer}>
			{!preview ? (
				<>
					<View
						style={styles.dropZone}
						onDragOver={(e) => e.preventDefault()}
						onDrop={handleFileDrop}>
						<Text style={styles.dropZoneText}>
							Drag and drop files here or
						</Text>
						<TouchableOpacity
							style={styles.browseButton}
							onPress={() =>
								document
									.getElementById(`fileInput-${asset._id}`)
									.click()
							}>
							<Text style={styles.browseButtonText}>
								Browse Files
							</Text>
						</TouchableOpacity>
						<input
							type='file'
							id={`fileInput-${asset._id}`}
							style={{ display: "none" }}
							onChange={handleFileSelect}
							accept='image/*,video/*'
						/>
						{uploading && (
							<ActivityIndicator
								color={APP_COLOURS.BLUE}
								style={styles.uploadingIndicator}
							/>
						)}
					</View>
				</>
			) : (
				<View style={styles.previewContainer}>
					<Image
						source={{ uri: preview }}
						style={styles.previewImage}
						resizeMode='contain'
					/>
					<TouchableOpacity
						style={styles.removePreviewButton}
						onPress={() => setPreview(null)}>
						<Text style={styles.removePreviewText}>×</Text>
					</TouchableOpacity>
				</View>
			)}
		</View>
	);
};

const RequestCard = ({
	request,
	onStatusChange,
	onEdit,
	onAssetUpdate,
	onRequestDeleted,
	user_data,
}) => {
	const [showAssetModal, setShowAssetModal] = useState(false);
	const [selectedAsset, setSelectedAsset] = useState(null);
	const [newComment, setNewComment] = useState("");
	const [statusComment, setStatusComment] = useState("");
	const [showCommentInput, setShowCommentInput] = useState(false);
	const [statusToChangeTo, setStatusToChangeTo] = useState(null);

	const closeModal = () => {
		setShowAssetModal(false);
		setSelectedAsset(null);
		setStatusComment("");
		setShowCommentInput(false);
		setStatusToChangeTo(null);
	};

	const handlePreStatusChange = (asset, newStatus) => {
		setStatusToChangeTo(newStatus);
		setShowCommentInput(true);
	};

	const handleCommentSubmit = (comment) => {
		if (selectedAsset && statusToChangeTo) {
			handleAssetStatusChange(selectedAsset, statusToChangeTo, comment);
			setStatusComment("");
			setShowCommentInput(false);
			setStatusToChangeTo(null);
		}
	};

	const handleAssetStatusChange = (asset, newStatus, comment) => {
		const updatedAsset = {
			...asset,
			status: newStatus,
			lastUpdated: new Date().toISOString(),
			history: [
				...(asset.history || []),
				{
					action: "Status Changed",
					timestamp: new Date().toISOString(),
					details: `Status changed from ${asset.status} to ${newStatus}`,
					comment: comment,
					oldStatus: asset.status,
					newStatus: newStatus,
					user: {
						id: user_data.id,
						name: `${user_data.first_name} ${user_data.last_name}`,
					},
				},
			],
		};

		if (newStatus === "In Progress" && !asset.startedAt) {
			updatedAsset.startedAt = new Date().toISOString();
		} else if (newStatus === "Under Review" && !asset.reviewAt) {
			updatedAsset.reviewAt = new Date().toISOString();
		}

		const updatedAssets = request.assets.map((a) =>
			a._id === asset._id ? updatedAsset : a,
		);

		let newRequestStatus;

		if (updatedAssets.some((a) => a.status === "In Progress")) {
			newRequestStatus = "In Progress";
		} else if (
			updatedAssets.some((a) => a.status === "Under Review") &&
			updatedAssets.some((a) => a.status === "Completed")
		) {
			newRequestStatus = "Under Review";
		} else if (updatedAssets.every((a) => a.status === "Under Review")) {
			newRequestStatus = "Under Review";
		} else if (updatedAssets.every((a) => a.status === "Completed")) {
			newRequestStatus = "Completed";
		} else if (
			updatedAssets.some((a) => a.status === "Attention Required")
		) {
			newRequestStatus = "Attention Required";
		} else {
			newRequestStatus = "Pending";
		}

		const updatedRequest = {
			...request,
			status: newRequestStatus,
			lastUpdated: new Date().toISOString(),
			assets: updatedAssets,
			history: [
				...(request.history || []),
				{
					action: "Status Changed",
					timestamp: new Date().toISOString(),
					details: `Request moved to ${newRequestStatus} - ${getStatusChangeReason(
						updatedAssets,
						newStatus,
					)}`,
					oldStatus: request.status,
					newStatus: newRequestStatus,
					triggeredBy: asset.type,
				},
			],
		};

		onStatusChange(request._id, updatedRequest);

		if (selectedAsset && selectedAsset._id === asset._id) {
			setSelectedAsset(updatedAsset);
		}

		if (showAssetModal) {
			setShowAssetModal(false);
		}
	};

	const handleDelete = async (request, user_data) => {
		// Check if current user is the creator
		if (request.created_by !== user_data.id) {
			alert("Only the creator can delete this request");
			return;
		}

		if (window.confirm("Are you sure you want to delete this request?")) {
			try {
				await crudDelete("creative_requests", request, (status) => {
					if (status === 200) {
						// Call the passed prop instead of accessing undefined variable
						onRequestDeleted(request._id);
					} else {
						alert("Failed to delete request");
					}
				});
			} catch (error) {
				console.error("Error deleting request:", error);
				alert("Error deleting request");
			}
		}
	};

	return (
		<TouchableOpacity style={styles.container}>
			<View style={styles.header}>
				<View style={styles.titleContainer}>
					<Text style={styles.title}>{request.title}</Text>
					{request.created_by === user_data?.id && (
						<TouchableOpacity
							style={styles.deleteButton}
							onPress={() => handleDelete(request, user_data)}>
							<Text style={styles.deleteButtonText}>×</Text>
						</TouchableOpacity>
					)}
				</View>
				<View
					style={[
						styles.progressBar,
						{ backgroundColor: getProgressColor(request.assets) },
					]}
				/>
			</View>

			<View style={styles.dates}>
				<Text style={styles.dateText}>
					Start: {moment(request.startDate).format("DD MMM")}
				</Text>
				<Text style={styles.dateText}>
					Due: {moment(request.dueDate).format("DD MMM")}
				</Text>
			</View>

			<StatusSummary assets={request.assets} />

			<View style={styles.assetsContainer}>
				<Text style={styles.assetsTitle}>Required Assets:</Text>
				{request.assets?.map((asset, index) => (
					<AssetItem
						key={index}
						asset={asset}
						onStatusChange={handleAssetStatusChange}
						onPress={() => {
							setSelectedAsset(asset);
							setShowAssetModal(true);
						}}
					/>
				))}
			</View>

			{showCommentInput && (
				<StatusCommentInput
					comment={statusComment}
					setComment={setStatusComment}
					onSubmit={handleCommentSubmit}
					onCancel={() => {
						setShowCommentInput(false);
						setStatusComment("");
						setStatusToChangeTo(null);
					}}
				/>
			)}

			{showAssetModal && selectedAsset && (
				<Modal
					visible={showAssetModal}
					transparent={true}
					animationType='slide'
					onRequestClose={closeModal}>
					<View style={styles.modalContainer}>
						<View style={styles.modalContent}>
							<View style={styles.modalHeader}>
								<Text style={styles.modalTitle}>
									{selectedAsset.type}
								</Text>
								<TouchableOpacity onPress={closeModal}>
									<Text style={styles.closeButton}>×</Text>
								</TouchableOpacity>
							</View>

							<ScrollView style={styles.modalScroll}>
								{/* Status Section */}
								<View
									style={[
										styles.section,
										{
											backgroundColor: `${
												ASSET_STATUS[
													selectedAsset.status
												]?.color
											}15`,
										},
									]}>
									<View style={styles.currentStatusHeader}>
										<View style={styles.statusBadge}>
											<Text style={styles.statusIcon}>
												{
													ASSET_STATUS[
														selectedAsset.status
													]?.icon
												}
											</Text>
											<Text
												style={[
													styles.currentStatusText,
													{
														color: ASSET_STATUS[
															selectedAsset.status
														]?.color,
													},
												]}>
												{selectedAsset.status}
											</Text>
										</View>
										{selectedAsset.lastUpdated && (
											<Text
												style={styles.lastUpdatedText}>
												Updated{" "}
												{moment(
													selectedAsset.lastUpdated,
												).fromNow()}
											</Text>
										)}
									</View>

									{/* Asset Details */}
									<View style={styles.assetDetails}>
										<Text style={styles.assetDetailLabel}>
											Size:
										</Text>
										<Text style={styles.assetDetailValue}>
											{selectedAsset.size}
										</Text>

										<Text style={styles.assetDetailLabel}>
											Specifications:
										</Text>
										<Text style={styles.assetDetailValue}>
											{selectedAsset.specs}
										</Text>

										<Text style={styles.assetDetailLabel}>
											Description:
										</Text>
										<Text style={styles.assetDetailValue}>
											{selectedAsset.description}
										</Text>

										{selectedAsset.brief && (
											<>
												<Text
													style={
														styles.assetDetailLabel
													}>
													Content Brief:
												</Text>
												<Text
													style={
														styles.assetDetailValue
													}>
													{selectedAsset.brief}
												</Text>
											</>
										)}
									</View>

									{/* Status Actions */}
									<View style={styles.statusActions}>
										{selectedAsset.status === "Pending" && (
											<TouchableOpacity
												style={[
													styles.actionButton,
													{
														backgroundColor:
															APP_COLOURS.BLUE,
													},
												]}
												onPress={() =>
													handlePreStatusChange(
														selectedAsset,
														"In Progress",
													)
												}>
												<Text
													style={
														styles.actionButtonText
													}>
													Begin Work
												</Text>
											</TouchableOpacity>
										)}

										{selectedAsset.status ===
											"In Progress" && (
											<TouchableOpacity
												style={[
													styles.actionButton,
													{
														backgroundColor:
															APP_COLOURS.GREEN,
													},
												]}
												onPress={() =>
													handlePreStatusChange(
														selectedAsset,
														"Under Review",
													)
												}>
												<Text
													style={
														styles.actionButtonText
													}>
													Submit for Review
												</Text>
											</TouchableOpacity>
										)}

										{selectedAsset.status ===
											"Under Review" && (
											<View style={styles.reviewActions}>
												<TouchableOpacity
													style={[
														styles.actionButton,
														{
															backgroundColor:
																APP_COLOURS.RED,
														},
													]}
													onPress={() =>
														handlePreStatusChange(
															selectedAsset,
															"Attention Required",
														)
													}>
													<Text
														style={
															styles.actionButtonText
														}>
														Request Changes
													</Text>
												</TouchableOpacity>
												<TouchableOpacity
													style={[
														styles.actionButton,
														{
															backgroundColor:
																APP_COLOURS.GREEN,
														},
													]}
													onPress={() =>
														handlePreStatusChange(
															selectedAsset,
															"Completed",
														)
													}>
													<Text
														style={
															styles.actionButtonText
														}>
														Approve
													</Text>
												</TouchableOpacity>
											</View>
										)}

										{selectedAsset.status ===
											"Attention Required" && (
											<TouchableOpacity
												style={[
													styles.actionButton,
													{
														backgroundColor:
															APP_COLOURS.GREEN,
													},
												]}
												onPress={() =>
													handlePreStatusChange(
														selectedAsset,
														"Under Review",
													)
												}>
												<Text
													style={
														styles.actionButtonText
													}>
													Submit for Review
												</Text>
											</TouchableOpacity>
										)}
									</View>
								</View>

								{/* Timeline Section */}
								<View style={styles.section}>
									<Text style={styles.sectionTitle}>
										Timeline
									</Text>
									<View style={styles.timeline}>
										{selectedAsset.history?.map(
											(event, index) => (
												<View
													key={index}
													style={styles.timelineItem}>
													<Text
														style={
															styles.timelineIcon
														}>
														{event.action ===
														"Created"
															? "🆕"
															: event.action ===
															  "Status Changed"
															? "🔄"
															: event.action ===
															  "Files Added"
															? "📎"
															: "✏️"}
													</Text>
													<View
														style={
															styles.timelineContent
														}>
														<Text
															style={
																styles.timelineTitle
															}>
															{event.action}
														</Text>
														<Text
															style={
																styles.timelineDetails
															}>
															{event.details}
														</Text>
														{event.comment && (
															<Text
																style={
																	styles.historyComment
																}>
																"{event.comment}
																"
															</Text>
														)}
														<Text
															style={
																styles.timelineDate
															}>
															{moment(
																event.timestamp,
															).format(
																"DD MMM YYYY, HH:mm",
															)}
														</Text>
													</View>
												</View>
											),
										)}
									</View>
								</View>

								{/* Files Section */}
								<View style={styles.section}>
									<Text style={styles.sectionTitle}>
										Files
									</Text>
									{selectedAsset.files?.length > 0 ? (
										<View style={styles.filesGrid}>
											{selectedAsset.files.map(
												(file, index) => (
													<View
														key={index}
														style={styles.fileItem}>
														<Image
															source={{
																uri:
																	file.preview ||
																	file.url,
															}}
															style={
																styles.filePreview
															}
															resizeMode='cover'
														/>
														<Text
															style={
																styles.fileName
															}
															numberOfLines={1}>
															{file.name}
														</Text>
													</View>
												),
											)}
										</View>
									) : (
										<Text style={styles.noFilesText}>
											No files uploaded yet
										</Text>
									)}
								</View>

								{/* Add file upload section when status is "In Progress" */}
								{selectedAsset.status === "In Progress" && (
									<View style={styles.section}>
										<Text style={styles.sectionTitle}>
											Upload Asset
										</Text>
										<AssetFileUpload
											asset={selectedAsset}
											onFileUploaded={(fileData) => {
												const updatedAsset = {
													...selectedAsset,
													files: [
														...(selectedAsset.files ||
															[]),
														fileData,
													],
													history: [
														...(selectedAsset.history ||
															[]),
														{
															action: "File Uploaded",
															timestamp:
																new Date().toISOString(),
															details: `File "${fileData.name}" uploaded`,
															user: {
																id: user_data.id,
																name: `${user_data.first_name} ${user_data.last_name}`,
															},
														},
													],
												};
												handleAssetStatusChange(
													updatedAsset,
													selectedAsset.status,
												);
											}}
										/>
									</View>
								)}
							</ScrollView>
						</View>
					</View>
				</Modal>
			)}
		</TouchableOpacity>
	);
};

const styles = {
	container: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		padding: 12,
		marginBottom: 10,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
	},
	header: {
		marginBottom: 8,
	},
	title: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 4,
	},
	progressBar: {
		height: 4,
		borderRadius: 2,
		width: "100%",
	},
	dates: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 8,
	},
	dateText: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
	},
	assetsContainer: {
		marginTop: 8,
	},
	assetsTitle: {
		fontSize: 14,
		fontWeight: "500",
		marginBottom: 4,
	},
	assetItem: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
		marginLeft: 8,
	},
	moreAssets: {
		fontSize: 12,
		color: APP_COLOURS.BLUE,
		marginTop: 4,
	},
	driveLink: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 8,
		padding: 8,
		backgroundColor: "#F8F9FA",
		borderRadius: 4,
	},
	driveIcon: {
		width: 16,
		height: 16,
		marginRight: 6,
	},
	driveLinkText: {
		fontSize: 12,
		color: APP_COLOURS.BLUE,
	},
	modalContainer: {
		flex: 1,
		backgroundColor: "rgba(0,0,0,0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "#FFF",
		borderRadius: 10,
		padding: 20,
		width: "90%",
		maxWidth: 500,
		maxHeight: "80%",
	},
	modalHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: "600",
	},
	closeButton: {
		fontSize: 24,
		fontWeight: "600",
	},
	assetDetails: {
		marginBottom: 20,
	},
	assetLabel: {
		fontSize: 14,
		fontWeight: "600",
		marginBottom: 4,
	},
	assetValue: {
		fontSize: 14,
		marginBottom: 12,
	},
	uploadSection: {
		marginBottom: 20,
	},
	uploadTitle: {
		fontSize: 16,
		fontWeight: "600",
		marginBottom: 10,
	},
	fileItem: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 8,
	},
	filePreview: {
		width: 40,
		height: 40,
		marginRight: 10,
		borderRadius: 4,
	},
	fileName: {
		fontSize: 14,
	},
	statusButton: {
		padding: 10,
		borderRadius: 6,
		alignItems: "center",
		marginVertical: 5,
	},
	statusButtonText: {
		color: "#FFF",
		fontWeight: "600",
		fontSize: 14,
	},
	reviewButtons: {
		flexDirection: "row",
		justifyContent: "space-between",
		gap: 10,
	},
	assetItemContainer: {
		borderRadius: 8,
		padding: 15,
		marginBottom: 10,
		borderWidth: 1,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
	},
	assetRow: {
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
	},
	assetStatus: {
		width: 8,
		height: 8,
		borderRadius: 4,
		marginLeft: 8,
	},
	filesCount: {
		fontSize: 12,
		color: APP_COLOURS.BLUE,
		marginLeft: 16,
	},
	section: {
		marginBottom: 20,
		padding: 10,
		backgroundColor: "#F8F9FA",
		borderRadius: 8,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		marginBottom: 10,
		color: APP_COLOURS.BG2,
	},
	statusButtons: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 8,
	},
	timeline: {
		marginTop: 10,
	},
	timelineItem: {
		fontSize: 14,
		marginBottom: 5,
		color: APP_COLOURS.BG3,
	},
	comments: {
		marginBottom: 10,
	},
	comment: {
		backgroundColor: "#FFF",
		padding: 10,
		borderRadius: 6,
		marginBottom: 8,
	},
	commentAuthor: {
		fontWeight: "600",
		marginBottom: 4,
	},
	commentText: {
		marginBottom: 4,
	},
	commentTime: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
	},
	addComment: {
		flexDirection: "row",
		alignItems: "flex-start",
	},
	commentInput: {
		flex: 1,
		borderWidth: 1,
		borderColor: "#E5E5E5",
		borderRadius: 4,
		padding: 8,
		marginRight: 8,
		minHeight: 40,
	},
	addCommentButton: {
		backgroundColor: APP_COLOURS.BLUE,
		padding: 8,
		borderRadius: 4,
		justifyContent: "center",
	},
	addCommentButtonText: {
		color: "#FFF",
		fontWeight: "600",
	},
	historyItem: {
		backgroundColor: "#FFF",
		padding: 8,
		borderRadius: 4,
		marginBottom: 6,
	},
	historyAction: {
		fontWeight: "600",
		marginBottom: 2,
	},
	historyDetails: {
		fontSize: 14,
		marginBottom: 2,
	},
	historyTime: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
	},
	modalScroll: {
		maxHeight: "80%",
	},
	sizePreviewContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 4,
		marginLeft: 8,
	},
	sizePreview: {
		borderRadius: 4,
		borderWidth: 1,
		borderColor: APP_COLOURS.BLUE,
		marginRight: 8,
	},
	sizeText: {
		fontSize: 11,
		color: APP_COLOURS.BG3,
	},
	assetInfo: {
		flex: 1,
	},
	briefPreview: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
		marginLeft: 8,
		marginTop: 4,
		fontStyle: "italic",
	},
	assetItemContainer: {
		backgroundColor: "#F8F9FA",
		padding: 8,
		borderRadius: 6,
		marginBottom: 8,
	},
	assetRow: {
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
	},
	statusButtonContainer: {
		marginTop: 10,
		padding: 10,
		backgroundColor: "#F8F9FA",
		borderRadius: 8,
	},
	currentStatus: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		marginBottom: 8,
	},
	statusButton: {
		padding: 12,
		borderRadius: 6,
		alignItems: "center",
		marginVertical: 5,
	},
	statusButtonText: {
		color: "#FFF",
		fontWeight: "600",
		fontSize: 14,
	},
	reviewButtons: {
		flexDirection: "row",
		justifyContent: "space-between",
		gap: 10,
	},
	completedStatus: {
		padding: 12,
		borderRadius: 6,
		alignItems: "center",
		backgroundColor: "#E8F5E9",
		marginVertical: 5,
	},
	completedText: {
		color: APP_COLOURS.GREEN,
		fontWeight: "600",
		fontSize: 14,
	},
	statusSection: {
		backgroundColor: "#F8F9FA",
		borderRadius: 8,
		padding: 15,
		marginTop: 15,
	},
	statusHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 12,
	},
	statusIndicator: {
		flexDirection: "row",
		alignItems: "center",
	},
	statusDot: {
		width: 8,
		height: 8,
		borderRadius: 4,
		marginRight: 8,
	},
	currentStatus: {
		fontSize: 14,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	lastUpdated: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
		fontStyle: "italic",
	},
	statusButton: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		padding: 12,
		borderRadius: 8,
		marginVertical: 4,
	},
	statusIcon: {
		fontSize: 16,
		marginRight: 8,
	},
	statusButtonText: {
		color: "#FFF",
		fontSize: 14,
		fontWeight: "600",
	},
	startButton: {
		backgroundColor: APP_COLOURS.BLUE,
	},
	completeButton: {
		backgroundColor: APP_COLOURS.GREEN,
	},
	reviewButtonsContainer: {
		flexDirection: "row",
		gap: 8,
	},
	rejectButton: {
		flex: 1,
		backgroundColor: APP_COLOURS.ORANGE,
	},
	approveButton: {
		flex: 1,
		backgroundColor: APP_COLOURS.GREEN,
	},
	resumeButton: {
		backgroundColor: APP_COLOURS.BLUE,
	},
	completedContainer: {
		backgroundColor: "#E8F5E9",
		padding: 12,
		borderRadius: 8,
		alignItems: "center",
	},
	completedIcon: {
		fontSize: 24,
		color: APP_COLOURS.GREEN,
		marginBottom: 4,
	},
	completedText: {
		color: APP_COLOURS.GREEN,
		fontSize: 16,
		fontWeight: "600",
		marginBottom: 4,
	},
	completedDate: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
	},
	assetItemContainer: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		padding: 15,
		marginBottom: 10,
		borderWidth: 1,
		borderColor: "#E5E5E5",
	},
	assetInfo: {
		flex: 1,
		marginRight: 12,
	},
	assetTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 6,
	},
	assetDescription: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		marginBottom: 8,
	},
	assetMeta: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 8,
	},
	assetMetaItem: {
		flexDirection: "row",
		alignItems: "center",
		marginRight: 16,
	},
	assetMetaIcon: {
		marginRight: 4,
		fontSize: 12,
	},
	assetMetaText: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
	},
	activeAssetContainer: {
		borderWidth: 2,
		backgroundColor: "#F0F7FF",
		shadowColor: APP_COLOURS.BLUE,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 4,
	},
	assetHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 8,
	},
	statusIndicator: {
		flexDirection: "row",
		alignItems: "center",
		paddingHorizontal: 8,
		paddingVertical: 4,
		borderRadius: 12,
		marginLeft: 8,
	},
	statusIcon: {
		marginRight: 4,
		fontSize: 12,
	},
	statusLabel: {
		fontSize: 12,
		fontWeight: "600",
	},
	quickActionButton: {
		backgroundColor: APP_COLOURS.GREEN,
		padding: 8,
		borderRadius: 6,
		alignItems: "center",
		marginTop: 8,
	},
	quickActionText: {
		color: "#FFF",
		fontSize: 12,
		fontWeight: "600",
	},
	actionButton: {
		padding: 8,
		borderRadius: 6,
		alignItems: "center",
		marginTop: 8,
	},
	actionButtonText: {
		color: "#FFF",
		fontSize: 12,
		fontWeight: "600",
	},
	statusSummary: {
		backgroundColor: "#F8F9FA",
		padding: 12,
		borderRadius: 8,
		marginTop: 12,
	},
	statusSummaryTitle: {
		fontSize: 14,
		fontWeight: "600",
		marginBottom: 8,
		color: APP_COLOURS.BG2,
	},
	statusCounts: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 8,
	},
	statusCount: {
		fontSize: 12,
		fontWeight: "500",
		backgroundColor: "#FFF",
		paddingHorizontal: 8,
		paddingVertical: 4,
		borderRadius: 12,
	},
	completionText: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
		marginTop: 8,
	},
	modalActionButton: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		padding: 12,
		borderRadius: 8,
		marginVertical: 8,
	},
	modalActionIcon: {
		fontSize: 18,
		marginRight: 8,
		color: "#FFF",
	},
	modalActionText: {
		color: "#FFF",
		fontSize: 16,
		fontWeight: "600",
	},
	reviewActionsContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		gap: 10,
	},
	currentStatusHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 12,
	},
	statusBadge: {
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "#FFF",
		paddingHorizontal: 12,
		paddingVertical: 6,
		borderRadius: 20,
	},
	currentStatusText: {
		fontSize: 16,
		fontWeight: "600",
		marginLeft: 6,
	},
	lastUpdatedText: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
		fontStyle: "italic",
	},
	timelineItem: {
		flexDirection: "row",
		alignItems: "flex-start",
		marginBottom: 16,
	},
	timelineIcon: {
		fontSize: 20,
		marginRight: 12,
	},
	timelineContent: {
		flex: 1,
	},
	timelineTitle: {
		fontSize: 14,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 2,
	},
	timelineDate: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
	},
	statusActionsContainer: {
		marginTop: 8,
	},
	statusActions: {
		marginTop: 20,
		padding: 15,
		backgroundColor: "#f8f9fa",
		borderRadius: 8,
	},
	reviewActions: {
		flexDirection: "row",
		justifyContent: "space-between",
		gap: 10,
	},
	assetDetails: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		padding: 12,
		marginTop: 12,
	},
	assetDetailLabel: {
		fontSize: 14,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 4,
		marginTop: 8,
	},
	assetDetailValue: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		marginBottom: 8,
	},
	timelineDetails: {
		fontSize: 13,
		color: APP_COLOURS.BG3,
		marginBottom: 2,
	},
	noFilesText: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		fontStyle: "italic",
		textAlign: "center",
		padding: 20,
	},
	section: {
		backgroundColor: "#F8F9FA",
		borderRadius: 8,
		padding: 15,
		marginBottom: 15,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 12,
	},
	commentInputContainer: {
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "#FFF",
		padding: 15,
		borderTopWidth: 1,
		borderTopColor: "#E5E5E5",
		shadowColor: "#000",
		shadowOffset: { width: 0, height: -2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
	},
	commentInput: {
		borderWidth: 1,
		borderColor: "#E5E5E5",
		borderRadius: 8,
		padding: 12,
		minHeight: 80,
		marginBottom: 10,
		backgroundColor: "#FFF",
	},
	commentButtons: {
		flexDirection: "row",
		justifyContent: "flex-end",
		gap: 10,
	},
	commentButton: {
		paddingVertical: 8,
		paddingHorizontal: 15,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: "#E5E5E5",
	},
	submitButton: {
		backgroundColor: APP_COLOURS.GREEN,
		borderColor: APP_COLOURS.GREEN,
	},
	commentButtonText: {
		color: APP_COLOURS.BG2,
		fontWeight: "600",
	},
	submitButtonText: {
		color: "#FFF",
		fontWeight: "600",
	},
	historyComment: {
		fontSize: 13,
		color: APP_COLOURS.BG3,
		fontStyle: "italic",
		marginTop: 4,
		backgroundColor: "#F8F9FA",
		padding: 8,
		borderRadius: 4,
	},
	titleContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 4,
	},
	deleteButton: {
		backgroundColor: APP_COLOURS.RED,
		width: 24,
		height: 24,
		borderRadius: 12,
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 8,
	},
	deleteButtonText: {
		color: "#FFF",
		fontSize: 16,
		fontWeight: "600",
		lineHeight: 24,
	},
	uploadContainer: {
		marginTop: 15,
		marginBottom: 15,
	},
	dropZone: {
		borderWidth: 2,
		borderColor: APP_COLOURS.BLUE,
		borderStyle: "dashed",
		borderRadius: 8,
		padding: 20,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#F8F9FA",
	},
	dropZoneText: {
		color: APP_COLOURS.BG3,
		marginBottom: 10,
	},
	browseButton: {
		backgroundColor: APP_COLOURS.BLUE,
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 6,
	},
	browseButtonText: {
		color: "#FFF",
		fontWeight: "600",
	},
	uploadingIndicator: {
		marginTop: 10,
	},
	previewContainer: {
		position: "relative",
		marginTop: 10,
	},
	previewImage: {
		width: "100%",
		height: 200,
		borderRadius: 8,
	},
	removePreviewButton: {
		position: "absolute",
		top: -10,
		right: -10,
		backgroundColor: APP_COLOURS.RED,
		width: 24,
		height: 24,
		borderRadius: 12,
		justifyContent: "center",
		alignItems: "center",
	},
	removePreviewText: {
		color: "#FFF",
		fontSize: 16,
		fontWeight: "600",
	},
	filesGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 10,
		marginTop: 10,
	},
	fileItem: {
		width: "48%",
		marginBottom: 15,
	},
	filePreview: {
		width: "100%",
		height: 150,
		borderRadius: 8,
		backgroundColor: "#F0F0F0",
	},
	fileName: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
		marginTop: 5,
	},
};

export default RequestCard;
