import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Font from "react-font";
// import DatePicker from 'react-native-date-picker';
import {
	APP_COLOURS,
	RETAILER,
	RETAILERPERFORMANCE,
	RETAILERS,
	screenHeight,
} from "../../APP_VARS";

import tick from "../../../assets/images/tick.png";
import starFill from "../../../assets/images/Table/starFill.png";
import star from "../../../assets/images/Table/star.png";
import FullCalendar from "./FullCalendar";
import BrandTabs from "../BrandTabs";
import SmallerTabs from "../TopTabs/SmallerTabs";
import CampaignSelector from "./SelectChannels";
import SelectRetailerCampaigns from "./SelectRetailerCampaigns";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuid() {
	return "xxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

const BLUE = "#3F6EFF";
const WHITE = FONT;
const FONT_ALMOSTWHITE = "#D4D4D4";

const RED = "#FF5F5F";
const GREEN = "#3F6EFF";
const OPACITYGREY = "#2F2F39";

const FONT_DARK = "#141D31";
const BORDERBOTTOM = "#28282F";

export default class AddCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: uuid(),
			title: "",
			end_date: moment(),
			start_date: moment(),

			status: "Strategy",
			description: "",

			googleDriveDocumentUrl: "",

			retailerList: [],

			retailerchannels: [],

			campaignBudget: 0,

			brand: this.props.selectedBrand,

			brand_id: this.props.selectedBrand.id,

			spendType: "includingAgency",
		};
	}

	componentDidMount() {
		// this.setState({
		// 	retailerchannels:	...this.props,
		// });
	}

	updateTotalBudget = (retailerchannels) => {
		const total = retailerchannels.reduce((sum, campaign) => {
			let budget = parseFloat(campaign.totalBudget);
			return sum + budget;
		}, 0);

		this.setState({
			campaignBudget: total,
		});
	};

	render() {
		let form_fields = [
			// {
			// 	id: 1,
			// 	editable: true,
			// 	state: "barcode",
			// 	title: "Barcode / A Code",
			// 	autoCapitalize: true,
			// 	validation: "name",
			// },
			{
				id: 2,
				editable: true,
				state: "title",
				title: "Campaign title",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 3,
				editable: true,
				state: "description",
				title: "Campaign description",
				autoCapitalize: true,
				validation: "name",
				numberofLines: 10,
			},

			{
				id: 4,
				editable: true,
				state: "googleDriveDocumentUrl",
				title: "Strategy Presentation - Google drive file URL",
				autoCapitalize: true,
				validation: "name",
			},
		];
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						ref={(ref) => (this.scrollViewRef = ref)}>
						<View
							style={{
								width: "100%",
								// maxWidth: 600,
								// height: this.props.screenHeight / 2,
								height: "auto",
								// maxHeight: screenHeight - this.state.keyboardOffset,
								// flex: 1,
								// backgroundColor: BG,
								backgroundColor: "#FFF",
								borderRadius: 10,
								padding: 15,
								// paddingTop: 50,
								marginTop: 50,
								minWidth: 900,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									// borderBottomWidth: 0.5,
									paddingBottom: 10,
									// borderBottomcolor: APP_COLOURS.BG2,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={async () => {
										this.props.close();
									}}>
									<Image
										style={{
											width: 24,
											height: 24,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View style={{ flex: 1 }}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 20,
											marginLeft: 10,
											fontWeight: "600",
											fontFamily: "Gotham",
										}}>
										{this.props.selectedCampaign !== ""
											? "Update"
											: "Add"}{" "}
										Campaign
									</Text>
								</View>

								{this.props.selectedCampaign !== "" ? (
									<TouchableOpacity
										onPress={async () => {
											this.props.deletCampaign(
												this.state,
											);
										}}>
										<Image
											style={{
												width: 24,
												height: 24,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/bin.png")}
										/>
									</TouchableOpacity>
								) : null}
							</View>

							{/* <SmallerTabs
								selectedSubPage={(d) => {
									this.setState({
										selectedTab: d,
									});
									console.log(d);
								}}
								tabs={["Product", "Retailer", "Dates"]}
							/> */}

							<View
								style={
									{
										// display:
										// this.state.selectedTab
									}
								}>
								<View
									style={{
										marginBottom: 10,
										padding: 10,
										borderRadius: 10,
										backgroundColor: BG2,
										// backgroundColor: "#FFF",
										// maxWidth: 350,
									}}>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											fontWeight: "400",
											fontFamily: "Gotham",
											// opacity: 0.5,
											padding: 0,
											marginBottom: 3,
										}}>
										Select brand
									</Text>

									<Text
										style={{
											fontSize: 15,
											color: "#FFF",
											fontWeight: "400",
											fontFamily: "Gotham",
											// opacity: 0.5,
											padding: 0,
											marginBottom: 3,
										}}>
										{this.props.selectedBrand.title}
									</Text>
								</View>
							</View>

							{/* <View
								style={{
									marginTop: 20,
								}}>
								<Text
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",
										fontSize: 12,
										color: BORDER,
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										zIndex: 99,
										backgroundColor: BG,
										padding: 4,
										borderRadius: 8,
									}}>
									Task description
								</Text>

								<TextInput
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										fontFamily: "Gotham",
										width: "100%",
										paddingLeft: 10,
										padding: 13,
										// backgroundColor:
										// 	this.state.docID !== "" ? BLUE : BG2,
										color: APP_COLOURS.BG2,
										borderRadius: 7,
										borderColor: BORDER,
										borderWidth: 1,
									}}
									placeholderTextColor={FONT}
									autoCapitalize='sentences'
									// autoCorrect={false}
									clearButtonMode='while-editing'
									// autoCompleteType="off"
									placeholder={`Task title`}
									onChangeText={(text) =>
										this.setState({
											title: text,
										})
									}
								/>
							</View> */}

							{form_fields.map((d, i) => {
								let validated = `${d.state}_valid`;
								return (
									<View
										key={i}
										style={{
											width: "auto",

											flexDirection: "row",
											alignItems: "center",
											margin: 10,
											marginBottom: 16,
										}}>
										<Text
											style={{
												opacity: this.state.unEditable
													? 0.4
													: 1,
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.PLACEHOLDER,
												fontWeight: "500",
												marginBottom: 4,
												position: "absolute",
												top: -12,
												left: 9,
												backgroundColor: "#FFF",
												padding: 4,
												borderRadius: 8,
											}}>
											{d.title}
										</Text>
										<TextInput
											style={{
												width: "100%",
												opacity: this.state.unEditable
													? 0.4
													: 1,
												borderWidth: 1,
												borderColor: this.state[
													validated
												]
													? APP_COLOURS.GREEN
													: typeof this.state[
															d.state
													  ] !== "undefined"
													? "#000"
													: APP_COLOURS.RED,
												padding: 12,
												paddingLeft: 30,
												fontSize: 16,
												borderRadius: 8,
												backgroundColor:
													APP_COLOURS.WHITE,
												color: "#000",
												fontWeight: "500",
												fontFamily: "Gotham",
											}}
											placeholderTextColor={"#000"}
											// autoCapitalize="sentences"
											clearButtonMode='while-editing'
											autoCorrect={false}
											// placeholder={d.title}
											numberOfLines={10}
											defaultValue={this.state[d.state]}
											onChangeText={async (text) => {
												// if (d.autoCapitalize) {
												// 	text = await capitalizeName(
												// 		text,
												// 	);
												// }

												await this.setState({
													[d.state]: text,
												});
											}}
										/>

										{this.state.unEditable &&
										!d.editable ? (
											<View
												style={{
													position: "absolute",
													width: "100%",
													height: "100%",
													backgroundColor: `rgba(52, 52, 52, 0.3)`,
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 8,
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 12,
														color: APP_COLOURS.WHITE,
														fontWeight: "700",
													}}>
													Uneditable field
												</Text>
											</View>
										) : null}

										{this.state[validated] ? (
											<Image
												source={tick}
												style={{
													width: 16,
													height: 16,
													resizeMode: "contain",
													position: "absolute",
													left: 9,
													top: 16,
												}}
											/>
										) : null}
									</View>
								);
							})}

							<View
								style={{
									padding: 10,
								}}>
								<TouchableOpacity
									onPress={async () => {
										window.open(
											"https://drive.google.com/drive/folders/1PFP9BgZSTMmjAE1UMzZ_ChzHabuGf0Eg?usp=drive_link",
										);
									}}>
									<View
										style={{
											padding: 10,
											borderRadius: 10,
											borderWidth: 0.5,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG,
												fontSize: 16,

												fontWeight: "400",
												fontFamily: "Gotham",
											}}>
											Please insert the strategy
											presentation into the google drive
											link below. Once the file is in the
											drive put the google drive sharable
											fiel in the field above.
										</Text>
										<Text
											style={{
												color: APP_COLOURS.BLUE,
												fontSize: 16,
												fontWeight: "600",
												fontFamily: "Gotham",
												marginTop: 10,
											}}>
											Google drive url
										</Text>
									</View>
								</TouchableOpacity>
							</View>

							<View
								style={{
									marginBottom: 10,
									padding: 10,
									borderRadius: 10,
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 12,
										color: APP_COLOURS.BG3,
										fontWeight: "700",
									}}>
									Select date range
								</Text>
								<View
									style={{
										width: 350,
										justifyContent: "center",
										alignItems: "center",
									}}>
									<FullCalendar
										compWidth={350}
										calendarList={[]}
										selectedDate={""}
										startDate={(date) => {
											this.setState({
												start_date: date,
											});
										}}
										endDate={(date) => {
											this.setState({
												end_date: date,
											});
										}}
									/>
								</View>
							</View>

							<Text
								style={{
									color: APP_COLOURS.BG,
									fontSize: 16,

									fontWeight: "400",
									fontFamily: "Gotham",
								}}>
								Select spend type
							</Text>
							<View
								style={{
									padding: 10,
									flexDirection: "row",
								}}>
								<TouchableOpacity
									onPress={async () => {
										this.setState({
											spendType: "includingAgency",
										});
									}}>
									<View
										style={{
											padding: 10,
											borderRadius: 10,
											borderWidth: 0.5,
											backgroundColor:
												this.state.spendType ===
												"includingAgency"
													? APP_COLOURS.BLUE
													: "transparent",
											marginRight: 10,
										}}>
										<Text
											style={{
												color:
													this.state.spendType ===
													"includingAgency"
														? "white"
														: APP_COLOURS.BLUE,
												fontSize: 16,
												fontWeight: "600",
												fontFamily: "Gotham",
												marginTop: 10,
											}}>
											Total spend
										</Text>
										<Text
											style={{
												color:
													this.state.spendType ===
													"includingAgency"
														? "white"
														: APP_COLOURS.BLUE,
												fontSize: 10,
												fontWeight: "600",
												fontFamily: "Gotham",
												marginTop: 10,
											}}>
											(Includes agency fees, budget spend)
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={async () => {
										this.setState({
											spendType: "excludesAgency",
										});
									}}>
									<View
										style={{
											padding: 10,
											borderRadius: 10,
											borderWidth: 0.5,
											backgroundColor:
												this.state.spendType ===
												"excludesAgency"
													? APP_COLOURS.BLUE
													: "transparent",
										}}>
										<Text
											style={{
												color:
													this.state.spendType ===
													"excludesAgency"
														? "white"
														: APP_COLOURS.BLUE,
												fontSize: 16,
												fontWeight: "600",
												fontFamily: "Gotham",
												marginTop: 10,
											}}>
											Rate Card spend
										</Text>
										<Text
											style={{
												color:
													this.state.spendType ===
													"excludesAgency"
														? "white"
														: APP_COLOURS.BLUE,
												fontSize: 10,
												fontWeight: "600",
												fontFamily: "Gotham",
												marginTop: 10,
											}}>
											(Excludes agency fees and is based
											on rate card)
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={async () => {
										this.setState({
											spendType: "agencyFeeOnly",
										});
									}}>
									<View
										style={{
											padding: 10,
											borderRadius: 10,
											borderWidth: 0.5,
											marginLeft: 10,
											backgroundColor:
												this.state.spendType ===
												"agencyFeeOnly"
													? APP_COLOURS.BLUE
													: "transparent",
										}}>
										<Text
											style={{
												color:
													this.state.spendType ===
													"agencyFeeOnly"
														? "white"
														: APP_COLOURS.BLUE,
												fontSize: 16,
												fontWeight: "600",
												fontFamily: "Gotham",
												marginTop: 10,
											}}>
											Agency fee only
										</Text>
										<Text
											style={{
												color:
													this.state.spendType ===
													"agencyFeeOnly"
														? "white"
														: APP_COLOURS.BLUE,
												fontSize: 10,
												fontWeight: "600",
												fontFamily: "Gotham",
												marginTop: 10,
											}}>
											(Insert budget spend here and agency
											fee will be calculated)
										</Text>
									</View>
								</TouchableOpacity>
							</View>

							<View
								style={{
									marginBottom: 10,
									padding: 10,
									borderRadius: 10,
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 12,
										color: APP_COLOURS.BG3,
										fontWeight: "700",
									}}>
									Select retailers
								</Text>

								{RETAILER.map((d, i) => {
									let exists =
										this.state.retailerList.includes(d);

									return (
										<SelectRetailerCampaigns
											d={d}
											i={i}
											exists={exists}
											spendType={this.state.spendType}
											selectRetailer={(d) => {
												if (exists) {
													this.setState({
														retailerList:
															this.state.retailerList.filter(
																(r) => r !== d,
															),
													});
												} else {
													this.setState({
														retailerList: [
															...this.state
																.retailerList,
															d,
														],
													});
												}
											}}
											updateParent={async (up) => {
												let retailerchannels =
													await this.state.retailerchannels.filter(
														(dd) => dd.id !== up.id,
													);

												retailerchannels = [
													...retailerchannels,
													up,
												];

												await this.setState({
													retailerchannels:
														retailerchannels,
												});

												this.updateTotalBudget(
													retailerchannels,
												);
											}}
											removeChannel={(id) => {
												console.log(
													"remove retailerchannel",
													id,
												);
												let retailerchannels =
													this.state.retailerchannels.filter(
														(dd) => dd.id !== id,
													);

												this.setState({
													retailerchannels:
														retailerchannels,
												});
												console.log(
													"remove retailerchannels",
													retailerchannels,
												);
												this.updateTotalBudget(
													retailerchannels,
												);
											}}
										/>
									);
								})}
							</View>

							<View
								style={{
									padding: 10,
									flexDirection: "row",
									justifyContent: "space-between",
								}}>
								<Text
									style={{
										fontSize: 20,
										color: APP_COLOURS.BG3,
										fontWeight: "600",
									}}>
									{this.state.spendType === "excludesAgency"
										? "Rate Card spend"
										: "Total Spend"}
								</Text>
								<Text
									style={{
										fontSize: 20,
										color: APP_COLOURS.BG3,
										fontWeight: "600",
									}}>
									{currencyFormat(this.state.campaignBudget)}
								</Text>
							</View>
							<View
								style={{
									padding: 10,
									flexDirection: "row",
									justifyContent: "space-between",
								}}>
								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.BG3,
										fontWeight: "400",
									}}>
									Total spend{" "}
									{this.state.spendType === "excludesAgency"
										? "exclude"
										: "includes"}{" "}
									agency fees and creative
								</Text>
							</View>

							{this.state.spendType === "excludesAgency" ? (
								<View
									style={{
										padding: 10,
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 20,
											color: APP_COLOURS.BG3,
											fontWeight: "600",
										}}>
										+ Agency fee
									</Text>
									<Text
										style={{
											fontSize: 20,
											color: APP_COLOURS.BG3,
											fontWeight: "600",
										}}>
										.
									</Text>
								</View>
							) : null}

							<TouchableOpacity
								style={{
									width: this.state.addingBrand
										? "auto"
										: "100%",
									padding: 10,
								}}
								onPress={async () => {
									this.setState({
										addingBrand: true,
									});
									let obj = { ...this.state };

									this.props.addEntry(obj);
									// console.log(obj);
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BG2,
										width: this.state.addingBrand
											? "auto"
											: "100%",
										padding: 13,
										height: 45,
										borderRadius: 25,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{!this.state.addingBrand ? (
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 15,
											}}>
											<Font
												weight={700}
												family='Montserrat'>
												{this.props.selectedCampaign !==
												""
													? "Update"
													: "Add"}{" "}
												campaign
											</Font>
										</Text>
									) : (
										<ActivityIndicator color={"#FFF"} />
									)}
								</View>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}
}
